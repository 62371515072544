import { Component, OnInit, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-respuesta',
  templateUrl: './respuesta.component.html',
  styleUrls: ['./respuesta.component.css']
})
export class RespuestaComponent implements OnInit {
  @Input() status;

  idStatus: string;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    switch (this.status) {
      case 'APROBADO':
          this.idStatus = 'APROBADO'
        break;
      case 'IMAGEN':
          this.idStatus = 'IMAGEN';
        break;
      default:
          this.idStatus = 'ERROR';
        break;
    }

  }
  closeModal() {
    if( this.idStatus == 'IMAGEN'){
      this.activeModal.close('Modal Closed');
    }else{
      this.activeModal.close('Modal Closed');
      location.reload();
    }
  }

}
