import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { BanorteService } from 'src/app/services/banorte.service';
import { LottieService } from 'src/app/services/lottie.service';
import { RestService } from 'src/app/services/rest.service';
import { environment } from 'src/environments/environment';

declare global {
  interface Window {
    Yolopago?: any;
  }
}

@Component({
  selector: 'app-banorte',
  templateUrl: './banorte.component.html',
  styleUrls: ['../payment-components.css'],
})
export class BanorteComponent implements OnInit {
  @Input() paymentData = null;

  paymentForm: FormGroup;

  cardData = {
    card_holder_name: null,
    email_holder: null,
    paymentTime: 1,
    phone: null,
  };

  cardNumber: any;
  cardCvv: any;
  cardExp: any;
  textss;
  textss2;
  montoinicial;
  track;
  usuario;
  password;
  generalSelect = 1;
  tipoSelect = 1;
  tipoBanc = 1;
  metodopSelect = 1;
  vermetodo = true;
  urlLogo1 = 'assets/img/general/credit-card-color.svg';
  marcat = 'VISA';
  tipot = 'CREDITO';
  tipoba = 'NACIONAL';
  metodopver = false;
  metodopvalue = 0;
  vercontado = false;
  ver3meses = false;
  ver6meses = false;
  ver9meses = false;
  ver12meses = false;
  ver18meses = false;

  showMessage = 1;
  count = 0;
  key_private: any;
  msi = [];
  errorShow = false;
  error: any;
  currency: any;

  constructor(
    private lottieService: LottieService,
    private fb: FormBuilder,

    private srvBanorte: BanorteService
  ) {}

  ngOnInit(): void {
    this.usuario = this.paymentData.datos['BNTE']['usuario'];
    this.password = this.paymentData.datos['BNTE']['tprivada'];
    this.montoinicial = this.paymentData.monto['BNTE']['cargo'];
    this.currency = this.paymentData.datos['BNTE']['currency'];


    switch (true) {
      case this.montoinicial < 300:
        //solo mostramos contado
        this.vercontado = true;
        break;
      case this.montoinicial < 600:
        //mostramos contado y 3 meses
        this.vercontado = true;
        this.ver3meses = true;
        break;
      case this.montoinicial < 900:
        //mostramos contado, 3 y 6 meses
        this.vercontado = true;
        this.ver3meses = true;
        this.ver6meses = true;
        break;
      case this.montoinicial < 1200:
        //mostramos contado, 3, 6 y 9 meses
        this.vercontado = true;
        this.ver3meses = true;
        this.ver6meses = true;
        this.ver9meses = true;
        break;
      case this.montoinicial < 1800:
        //mostramos contado, 3, 6, 9 y 12 meses.
        this.vercontado = true;
        this.ver3meses = true;
        this.ver6meses = true;
        this.ver9meses = true;
        this.ver12meses = true;
        break;
      case this.montoinicial >= 1800:
        //mostramos contado, 3, 6, 9 y 12 meses.
        this.vercontado = true;
        this.ver3meses = true;
        this.ver6meses = true;
        this.ver9meses = true;
        this.ver12meses = true;
        this.ver18meses = true;

        break;
    }

    this.track = this.paymentData.clave;
    this.paymentForm = this.fb.group({
      card_holder_name: new FormControl(this.cardData.card_holder_name, [
        Validators.required,
      ]),
      email_holder: new FormControl(this.cardData.email_holder, [
        Validators.required,
        Validators.email,
      ]),
      phone: new FormControl(this.cardData.phone, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(11),
        Validators.pattern('[- +()0-9]+'),
      ]),
      cardNumber: [false, [Validators.required, Validators.requiredTrue]], //TODO true | false
      cardCvv: [false, [Validators.required, Validators.requiredTrue]], //TODO true | false
      cardExp: [false, [Validators.required, Validators.requiredTrue]], //TODO true | false
    });
    this.selectmontof();
  }

  sendCardData() {
    this.showMessage = 2;
    //crear funcion para actualizar

    let randomNumber = Math.floor(Math.random() * 90 + 10);
    var body = {
      totalamount: this.montoinicial,
      reference: this.paymentData.clave,
      reference2: this.paymentData.descripcion,
      email: 'soporte@payoff.mx',
      transactiontype: '999',
      sendmail: '0',
      msi: this.msi,
      apiKey: this.password,
    };

    this.srvBanorte.generateleague(body).subscribe((response) => {
      if (response.success) {
        const url = response.data.UrlPayment;
        const newWindow = window.open(url, '_blank');
        newWindow.focus();
      } else {
        this.errorShow = true;
        this.error = response.errordescription;
        this.showMessage = 1;
      }
    });
  }

  cargarMarca(item: number) {
    this.generalSelect = item;
    switch (item) {
      case 1:
        this.marcat = 'VISA';
        break;
      case 2:
        this.marcat = 'MASTERCARD';
        break;
    }
    this.selectmontof();
  }

  cargarTipo(item: number) {
    this.tipoSelect = item;
    switch (item) {
      case 1:
        this.tipot = 'CREDITO';
        this.vermetodo = true;
        break;
      case 2:
        this.tipot = 'DEBITO';
        this.vermetodo = false;
        this.metodopSelect = 1;
        break;
      case 3:
        this.tipot = 'INTERNACIONAL';
        this.vermetodo = true;
        break;
    }
    this.selectmontof();
  }

  cargarBanc(item: number) {
    this.tipoBanc = item;
    switch (item) {
      case 1:
        this.tipoba = 'NACIONAL';
        break;
      case 2:
        this.tipoba = 'INTERNACIONAL';
        break;
    }
    this.selectmontof();
  }

  cargarMetodop(item: number) {
    this.metodopSelect = item;
    switch (item) {
      case 1:
        //armar el campo de envio formulario
        this.metodopver = true;
        this.metodopvalue = 0;
        break;
      case 2:
        //armar el campo de envio formulario
        this.metodopver = true;
        this.metodopvalue = 3;
        break;
      case 3:
        //armar el campo de envio formulario
        this.metodopver = true;
        this.metodopvalue = 6;
        break;
      case 4:
        //armar el campo de envio formulario
        this.metodopver = true;
        this.metodopvalue = 9;
        break;
      case 5:
        //armar el campo de envio formulario
        this.metodopver = true;
        this.metodopvalue = 12;
        break;
      case 6:
        //armar el campo de envio formulario
        this.metodopver = true;
        this.metodopvalue = 18;
        break;
    }
    this.selectmontof();
  }

  selectmontof() {
    //visa-mastercard
    switch (this.tipot) {
      case 'CREDITO':
        switch (this.metodopSelect) {
          case 1:
            this.msi = [];
            //contado
            this.montoinicial =
              this.paymentData.monto['BNTE']['Credito']['1_cargo'];
            break;
          case 2:
            this.msi = [3];
            //3 meses
            this.montoinicial =
              this.paymentData.monto['BNTE']['Credito']['3_cargo'];
            break;
          case 3:
            this.msi = [6];
            //6 meses
            this.montoinicial =
              this.paymentData.monto['BNTE']['Credito']['6_cargo'];
            break;
          case 4:
            this.msi = [9];
            //9 meses
            this.montoinicial =
              this.paymentData.monto['BNTE']['Credito']['9_cargo'];
            break;
          case 5:
            this.msi = [12];
            //12 meses
            this.montoinicial =
              this.paymentData.monto['BNTE']['Credito']['12_cargo'];
            break;
          case 6:
            this.msi = [18];
            //18 meses
            this.montoinicial =
              this.paymentData.monto['BNTE']['Extranjera']['18_cargo'];
            break;
          default:
            this.montoinicial =
              this.paymentData.monto['BNTE']['Credito']['1_cargo'];
            break;
        }
        break;
      case 'DEBITO':
        this.msi = [];
        switch (this.metodopSelect) {
          case 1:
            //contado
            this.montoinicial =
              this.paymentData.monto['BNTE']['Debito']['1_cargo'];
            break;
          case 2:
            //3 meses
            this.montoinicial =
              this.paymentData.monto['BNTE']['Debito']['3_cargo'];
            break;
          case 3:
            //6 meses
            this.montoinicial =
              this.paymentData.monto['BNTE']['Debito']['6_cargo'];
            break;
          case 4:
            //9 meses
            this.montoinicial =
              this.paymentData.monto['BNTE']['Debito']['9_cargo'];
            break;
          case 5:
            //12 meses
            this.montoinicial =
              this.paymentData.monto['BNTE']['Debito']['12_cargo'];
            break;
          case 6:
            //18 meses
            this.montoinicial =
              this.paymentData.monto['BNTE']['Extranjera']['18_cargo'];
            break;
          default:
            this.montoinicial =
              this.paymentData.monto['BNTE']['Debito']['1_cargo'];
            break;
        }
        break;
      case 'INTERNACIONAL':
        switch (this.metodopSelect) {
          case 1:
            this.msi = [];
            //contado
            this.montoinicial =
              this.paymentData.monto['BNTE']['Extranjera']['1_cargo'];
            break;
          case 2:
            this.msi = [3];
            //3 meses
            this.montoinicial =
              this.paymentData.monto['BNTE']['Extranjera']['3_cargo'];
            break;
          case 3:
            this.msi = [6];
            //6 meses
            this.montoinicial =
              this.paymentData.monto['BNTE']['Extranjera']['6_cargo'];
            break;
          case 4:
            this.msi = [9];
            //9 meses
            this.montoinicial =
              this.paymentData.monto['BNTE']['Extranjera']['9_cargo'];
            break;
          case 5:
            this.msi = [12];
            //12 meses
            this.montoinicial =
              this.paymentData.monto['BNTE']['Extranjera']['12_cargo'];
            break;
          default:
            this.montoinicial =
              this.paymentData.monto['BNTE']['Extranjera']['1_cargo'];
            break;
        }
        break;
    }
  }
}
