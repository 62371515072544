import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class documentosService {
    constructor(private http: HttpClient) { }
    getpdf(dato:any, url:any){
        return this.http.post<any>(url, dato).pipe(map(pdf => {
            //console.info('pdf',pdf);
            return pdf;
        }));
    }

    getpdf2(url){
        return this.http.get<any>(url).pipe(map(pdf => {
            //console.info('pdf',pdf);
            return pdf;
        }));
    }

    sendPagoData(url, dato){
        return this.http.post<any>(url, dato).pipe(map(result => {
            return result;
        }));
    }
}