
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(  private http: HttpClient,
                 ) { }

  



    sendPayment( data ) : Observable<any>{
        const URL = environment.url + 'cargo';

        const headers =  new HttpHeaders()
                            .set('Content-Type', 'application/x-www-form-urlencoded')
                            .set('Accept','application/json');
    
                            
        
        let body = {
          params: {
            idPasarela : data['idPasarela'],
            idEmpresa : data['idEmpresa'],
            monto : data['monto'],
            tipoPago : data['tipoPago'],
            referencia : data['referencia'],
            descripcion : data['descripcion'],
            clave : data['clave'],
            email : data['email'],
            track : data['track'],
            paymentMethodId: data['paymentMethodId'],
            key_private: data['key_private']
          }
        };

    return this.http.post(URL,body, {headers}).pipe(map(res => res));
  }


  stripeConfirmPayment(id: string, track: string, key_private: string){
    let data = {
      'paymentMethodId': id,
      'track': track,
      'key_private': key_private
    }
    return this.http.post(`${environment.api}/confirmPayment`, data)
  }


  getOrderDetail( id ) : Observable<any>{
    const URL = environment.api + '/obetenerDetalle';
    const headers =  new HttpHeaders()
                        .set('Content-Type', 'application/x-www-form-urlencoded')
                        .set('Accept','application/json');

                        
    let body = {
        id : id
    };

return this.http.post(URL,body, {headers}).pipe(map(res => res));
}

 
}
