import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { WindowRef } from '../../../app/WindowRef';
import { environment } from '../../../environments/environment';
import { RestService } from '../../../app/services/rest.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
//import { ModalStripeComponent } from './modal-stripe/modal-stripe.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { StripeService } from '../../../app/services/stripe.service';
import { LottieService } from '../../services/lottie.service';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';

declare global {
  interface Window {
    Yolopago?: any;
  }
}
@Component({
  selector: 'app-yolopago',
  templateUrl: './yolopago.component.html',
  styleUrls: ['../payment-components.css'],
})
export class YolopagoComponent implements OnInit {
  @Input() paymentData = null;
  @Output() yolopagoEmiter = new EventEmitter();
  private YOLOPAGO!: any; //TODO: window.Stripe
  private elementYolopago!: any;
  paymentForm: FormGroup;
  amount: number;

  cardData = {
    card_holder_name: null,
    email_holder: null,
    paymentTime: 1,
    phone: null,
  };

  cardNumber: any;
  cardCvv: any;
  cardExp: any;
  textss;
  textss2;
  //textss2='<html><head><title>MySitioWeb</title><script>type="text/javascript"src="yolopagoapi.js"</script><link rel="stylesheet"href="yolopagoapi.css"></head><body><script>type="text/javascript">Document.addEventListener("DOMContentloaded",function(event){yBtn= new YoloAPI({URL: "https://portal.yolopago.com.mx",user:"francis@softrek.mx",password:"YOLOPAGOCE-0872c9b1-ad10-43bf-a307-5e95184f8770",amountInputId: "lPaymentAmount",percentageCommisionId: "lPercentageCommision",paramsInputId: ["trxNbr", "name", "lastname", "email","nocontrol", "motivo", "tip", "phoneNumber", "street","externalNumber", "internalNumber", "zipCode"],);yBtn.render();});</script><div id= "ct1" style= "height:100px" border=1></div><div id= "ct2" height="100px" border=1><iframe src=""frameborder=0id="ourAppFrm"></iframe></div><script>console.log(document.enviroment)</script><label for="idLocalTr">idLocalTr</label><br/><input type= "text" id= "idLocalTr" value= "Mi parametro"></input><br/><label for="lPaymentAmount">Monto a cobrar</label><br/><input type="number" id="lPaymentAmount" value= "110.20"></input><br/><div id= "divtw"></div></body></html>';
  urlmodal;
  montoinicial;
  track;
  usuario;
  password;
  generalSelect = 1;
  tipoSelect = 1;
  tipoBanc = 1;
  metodopSelect = 1;
  vermetodo = true;
  urlLogo1 = 'assets/img/general/credit-card-color.svg';
  marcat = 'VISA';
  tipot = 'CREDITO';
  tipoba = 'NACIONAL';
  metodopver = false;
  metodopvalue = 0;
  vercontado = false;
  ver3meses = false;
  ver6meses = false;
  ver9meses = false;
  ver12meses = false;
  urlliga;
  urlfinal;
  vermensaje = 1;

  count = 0;
  key_private: any;
  currency: string;
  private yoloManual = environment.url + 'yolo/manual';

  constructor(
    private restService: RestService,
    //private stripeSrv: StripeService,
    private lottieService: LottieService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    //sanitize
    this.urlliga = this.sanitizer.bypassSecurityTrustUrl(
      'https://portal.yolopago.com.mx/ce/j_spring_security_check'
    );
    //this.urlliga = this.sanitizer.bypassSecurityTrustUrl('https://preprod.yolopago.com.mx/ce/j_spring_security_check');
    this.urlfinal = this.urlliga.changingThisBreaksApplicationSecurity;
    //

    // this.loadData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.paymentData) {
      this.loadData();
      console.log('paymentData en el hijo ha cambiado:');
    }
  }
  sendCardData() {
    this.vermensaje = 2;
    //crear funcion para actualizar

    let form: HTMLFormElement = <HTMLFormElement>(
      document.getElementById('yoloLoginForm')
    );
    form.submit();

    var data = {
      montof: this.montoinicial,
      clave: this.paymentData.clave,
      usuario: this.usuario,
      cardType: this.tipot,
      cardBrand: this.marcat,
      bankType: this.tipoba,
      paymentNumber: this.metodopvalue,
      percentage_commision: '',
      operation: 'COMPRA',
    };
    this.http
      .post(this.yoloManual, {
        params: data,
      })
      .subscribe((data) => {});
  }

  loadData() {
    console.log(this.paymentData);

    this.usuario = this.paymentData.datos['YOLO']['usuario'];
    //this.usuario ="francis@softrek.mx";
    this.password = this.paymentData.datos['YOLO']['tprivada'];
    //this.password ="YOLOPAGOCE-9548028e-0cc8-407c-aca8-e70659585b17";
    this.montoinicial = this.paymentData.monto['YOLO']['cargo'];
    this.currency = this.paymentData.datos['YOLO']['currency'];

    switch (true) {
      case this.montoinicial < 300:
        //solo mostramos contado
        this.vercontado = true;
        break;
      case this.montoinicial < 600:
        //mostramos contado y 3 meses
        this.vercontado = true;
        this.ver3meses = true;
        break;
      case this.montoinicial < 900:
        //mostramos contado, 3 y 6 meses
        this.vercontado = true;
        this.ver3meses = true;
        this.ver6meses = true;
        break;
      case this.montoinicial < 1200:
        //mostramos contado, 3, 6 y 9 meses
        this.vercontado = true;
        this.ver3meses = true;
        this.ver6meses = true;
        this.ver9meses = true;
        break;
      case this.montoinicial >= 1200:
        //mostramos contado, 3, 6, 9 y 12 meses.
        this.vercontado = true;
        this.ver3meses = true;
        this.ver6meses = true;
        this.ver9meses = true;
        this.ver12meses = true;
        break;
    }

    this.track = this.paymentData.clave;
    this.paymentForm = this.fb.group({
      card_holder_name: new FormControl(this.cardData.card_holder_name, [
        Validators.required,
      ]),
      email_holder: new FormControl(this.cardData.email_holder, [
        Validators.required,
        Validators.email,
      ]),
      phone: new FormControl(this.cardData.phone, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(11),
        Validators.pattern('[- +()0-9]+'),
      ]),
      cardNumber: [false, [Validators.required, Validators.requiredTrue]], //TODO true | false
      cardCvv: [false, [Validators.required, Validators.requiredTrue]], //TODO true | false
      cardExp: [false, [Validators.required, Validators.requiredTrue]], //TODO true | false
    });
    this.selectmontof();
  }
  cargarMarca(item: number) {
    this.generalSelect = item;
    switch (item) {
      case 1:
        this.marcat = 'VISA';
        break;
      case 2:
        this.marcat = 'MASTERCARD';
        break;
      case 3:
        this.marcat = 'AMEX';
        break;
    }
    this.selectmontof();
  }

  cargarTipo(item: number) {
    this.tipoSelect = item;
    switch (item) {
      case 1:
        this.tipot = 'CREDITO';
        this.vermetodo = true;
        break;
      case 2:
        this.tipot = 'DEBITO';
        this.vermetodo = false;
        this.metodopSelect = 1;
        break;
      case 3:
        this.tipot = 'INTERNACIONAL';
        this.vermetodo = true;
        break;
    }
    this.selectmontof();
  }

  cargarBanc(item: number) {
    this.tipoBanc = item;
    switch (item) {
      case 1:
        this.tipoba = 'NACIONAL';
        break;
      case 2:
        this.tipoba = 'INTERNACIONAL';
        break;
    }
    this.selectmontof();
  }

  cargarMetodop(item: number) {
    this.metodopSelect = item;
    switch (item) {
      case 1:
        //armar el campo de envio formulario
        //this.metodopver=false;
        this.metodopver = true;
        this.metodopvalue = 0;
        break;
      case 2:
        //armar el campo de envio formulario
        this.metodopver = true;
        this.metodopvalue = 3;
        break;
      case 3:
        //armar el campo de envio formulario
        this.metodopver = true;
        this.metodopvalue = 6;
        break;
      case 4:
        //armar el campo de envio formulario
        this.metodopver = true;
        this.metodopvalue = 9;
        break;
      case 5:
        //armar el campo de envio formulario
        this.metodopver = true;
        this.metodopvalue = 12;
        break;
    }
    this.selectmontof();
  }

  selectmontof() {
    if (this.marcat == 'AMEX') {
      //amex
      switch (this.metodopSelect) {
        case 1:
          //contado
          this.montoinicial = this.paymentData.monto['YOLO']['Amex']['1_cargo'];
          break;
        case 2:
          //3 meses
          this.montoinicial = this.paymentData.monto['YOLO']['Amex']['3_cargo'];
          break;
        case 3:
          //6 meses
          this.montoinicial = this.paymentData.monto['YOLO']['Amex']['6_cargo'];
          break;
        case 4:
          //9 meses
          this.montoinicial = this.paymentData.monto['YOLO']['Amex']['9_cargo'];
          break;
        case 5:
          //12 meses
          this.montoinicial =
            this.paymentData.monto['YOLO']['Amex']['12_cargo'];
          break;
        default:
          this.montoinicial = this.paymentData.monto['YOLO']['Amex']['1_cargo'];
          break;
      }
    } else {
      //visa-mastercard
      switch (this.tipot) {
        case 'CREDITO':
          switch (this.metodopSelect) {
            case 1:
              //contado
              this.montoinicial =
                this.paymentData.monto['YOLO']['Credito']['1_cargo'];
              break;
            case 2:
              //3 meses
              this.montoinicial =
                this.paymentData.monto['YOLO']['Credito']['3_cargo'];
              break;
            case 3:
              //6 meses
              this.montoinicial =
                this.paymentData.monto['YOLO']['Credito']['6_cargo'];
              break;
            case 4:
              //9 meses
              this.montoinicial =
                this.paymentData.monto['YOLO']['Credito']['9_cargo'];
              break;
            case 5:
              //12 meses
              this.montoinicial =
                this.paymentData.monto['YOLO']['Credito']['12_cargo'];
              break;
            default:
              this.montoinicial =
                this.paymentData.monto['YOLO']['Credito']['1_cargo'];
              break;
          }
          break;
        case 'DEBITO':
          switch (this.metodopSelect) {
            case 1:
              //contado
              this.montoinicial =
                this.paymentData.monto['YOLO']['Debito']['1_cargo'];
              break;
            case 2:
              //3 meses
              this.montoinicial =
                this.paymentData.monto['YOLO']['Debito']['3_cargo'];
              break;
            case 3:
              //6 meses
              this.montoinicial =
                this.paymentData.monto['YOLO']['Debito']['6_cargo'];
              break;
            case 4:
              //9 meses
              this.montoinicial =
                this.paymentData.monto['YOLO']['Debito']['9_cargo'];
              break;
            case 5:
              //12 meses
              this.montoinicial =
                this.paymentData.monto['YOLO']['Debito']['12_cargo'];
              break;
            default:
              this.montoinicial =
                this.paymentData.monto['YOLO']['Debito']['1_cargo'];
              break;
          }
          break;
        case 'INTERNACIONAL':
          switch (this.metodopSelect) {
            case 1:
              //contado
              this.montoinicial =
                this.paymentData.monto['YOLO']['Extranjera']['1_cargo'];
              break;
            case 2:
              //3 meses
              this.montoinicial =
                this.paymentData.monto['YOLO']['Extranjera']['3_cargo'];
              break;
            case 3:
              //6 meses
              this.montoinicial =
                this.paymentData.monto['YOLO']['Extranjera']['6_cargo'];
              break;
            case 4:
              //9 meses
              this.montoinicial =
                this.paymentData.monto['YOLO']['Extranjera']['9_cargo'];
              break;
            case 5:
              //12 meses
              this.montoinicial =
                this.paymentData.monto['YOLO']['Extranjera']['12_cargo'];
              break;
            default:
              this.montoinicial =
                this.paymentData.monto['YOLO']['Extranjera']['1_cargo'];
              break;
          }
          break;
      }
    }
  }

  /* load_home() {
    document.getElementById("content").innerHTML='<object type="text/html" data="home.html" ></object>';
  } */
}
