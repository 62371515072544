import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { WindowRef } from '../../../app/WindowRef';
import { environment } from '../../../environments/environment';
import { RestService } from '../../../app/services/rest.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalStripeComponent } from './modal-stripe/modal-stripe.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StripeService } from '../../../app/services/stripe.service';
import { LottieService } from '../../services/lottie.service';
import { InputUtilsService } from 'src/app/services/input-utils.service';

declare global {
  interface Window {
    Stripe?: any;
  }
}

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['../payment-components.css'],
})
export class StripeComponent implements OnInit {
  @Input() paymentData = null;
  @Output() stripeEmiter = new EventEmitter();
  private STRIPE!: any; //TODO: window.Stripe
  private elementStripe!: any;
  paymentForm: FormGroup;
  amount: number;

  cardData = {
    card_holder_name: null,
    email_holder: null,
    paymentTime: 1,
    phone: null,
  };

  cardNumber: any;
  cardCvv: any;
  cardExp: any;

  count = 0;
  key_private: any;
  currency: any;

  constructor(
    private restService: RestService,
    private stripeSrv: StripeService,
    private lottieService: LottieService,
    private fb: FormBuilder,
    private inputUtilsService: InputUtilsService
  ) {
    // this.STRIPE = window.Stripe(environment.stripe_pk);
  }

  ngOnInit(): void {
    this.STRIPE = window.Stripe(this.paymentData.datos['STRE']['tpublica']);
    this.currency = this.paymentData.datos['STRE']['currency'];
    this.amount = this.paymentData.monto['STRE']['Tarjeta']['1_cargo'];
    this.key_private = this.paymentData.datos['STRE']['tprivada'];
    this.paymentForm = this.fb.group({
      card_holder_name: new FormControl(this.cardData.card_holder_name, [
        Validators.required,
      ]),
      email_holder: new FormControl(this.cardData.email_holder, [
        Validators.required,
        Validators.email,
      ]),
      phone: new FormControl(this.cardData.phone, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(11),
        Validators.pattern('[- +()0-9]+'),
      ]),
      cardNumber: [false, [Validators.required, Validators.requiredTrue]], //TODO true | false
      cardCvv: [false, [Validators.required, Validators.requiredTrue]], //TODO true | false
      cardExp: [false, [Validators.required, Validators.requiredTrue]], //TODO true | false
    });

    this.createStripeElement();
  }

  onInputChange(event: any) {
    const numericValue = this.inputUtilsService.onInputChange(event);

    this.phone.setValue(numericValue);
  }

  private createStripeElement = () => {
    const style = {
      base: {
        lineHeight: '1.9',
      },
      invalid: {
        color: '#dc3545',
      },
    };
    //TODO: SDK de Stripe inicia la generacion de elementos
    this.elementStripe = this.STRIPE.elements({
      fonts: [
        {
          cssSrc:
            'https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap',
        },
      ],
    });

    //TODO: SDK Construimos los inputs de tarjeta, cvc, fecha con estilos
    const cardNumber = this.elementStripe.create('cardNumber', {
      placeholder: '4242 4242 4242 4242',
      style,
      classes: {
        base: 'input-stripe-custom',
      },
    });
    const cardExp = this.elementStripe.create('cardExpiry', {
      placeholder: 'MM/AA',
      style,
      classes: {
        base: 'input-stripe-custom',
      },
    });
    const cardCvc = this.elementStripe.create('cardCvc', {
      placeholder: '000',
      style,
      classes: {
        base: 'input-stripe-custom',
      },
    });

    //TODO: SDK Montamos los elementos en nuestros DIV identificados on el #id
    cardNumber.mount('#card');
    cardExp.mount('#exp');
    cardCvc.mount('#cvc');

    this.cardNumber = cardNumber;
    this.cardExp = cardExp;
    this.cardCvv = cardCvc;

    //TODO: Escuchamos los eventos del SDK
    this.cardNumber.addEventListener('change', this.onChangeCard.bind(this));
    this.cardExp.addEventListener('change', this.onChangeExp.bind(this));
    this.cardCvv.addEventListener('change', this.onChangeCvv.bind(this));
  };

  async initPay(): Promise<any> {}

  async sendCardData(): Promise<any> {
    this.lottieService.setLoader(true, '');
    // TODO: PASO 1 GENERAR EL METODO DE PAGO
    this.STRIPE.createPaymentMethod('card', this.cardNumber).then(
      async (data) => {
        let datos = {
          idPasarela: 'STRE',
          idEmpresa: this.paymentData.idempresa,
          monto: this.amount,
          tipoPago: 1,
          referencia: this.paymentData.clave,
          descripcion: this.paymentData.refencia,
          clave: this.paymentData.clave,
          track: this.paymentData.clave,
          paymentMethodId: data.paymentMethod.id,
          key_private: this.key_private,
        };
        this.stripeSrv.sendPayment(datos).subscribe((res) => {
          if (res.requiresAction) {
            this.STRIPE.handleCardAction(res['clientSecret'])
              .then(async (data) => {
                if (data.error) {
                  const now = new Date();
                  let array = {
                    status: 'ERROR',
                    fecha: now.toLocaleDateString(),
                    autorizacion: '',
                    ccNumero: '',
                    monto: '',
                    track: this.paymentData.clave,
                    orderId: '',
                    msg: data.error.message,
                  };
                  this.stripeEmiter.emit(array);
                  this.lottieService.setLoader(false, '');
                } else {
                  this.confirmPayment(data.paymentIntent.id);
                }
              })
              .catch((error) => {
                this.lottieService.setLoader(false, '');
              });
          } else {
            const now = new Date();
            let array;
            if (res.status == 'ERROR') {
              array = {
                status: 'ERROR',
                fecha: now.toLocaleDateString(),
                autorizacion: '',
                ccNumero: '',
                monto: '',
                track: this.paymentData.clave,
                orderId: '',
                msg: 'Error desconocido intente de nuevo',
              };
            } else {
              array = {
                status: 'Aprobado',
                respuesta: {
                  status: 'Aprobado',
                  fecha: now.toLocaleDateString(),
                  autorizacion: res['data'].id,
                  ccNumero:
                    res['data']['charges']['data'][0]['payment_method_details'][
                      'card'
                    ].last4,
                  monto: res['data'].amount / 100,
                  track: this.paymentData.clave,
                  orderId: res['data'].id,
                },
              };
            }

            this.stripeEmiter.emit(array);
            this.lottieService.setLoader(false, '');
          }
        });

        // this.generarOrden(data.paymentMethod.id);
      }
    );
  }

  confirmPayment(id: string) {
    this.stripeSrv
      .stripeConfirmPayment(id, this.paymentData.clave, this.key_private)
      .subscribe((res) => {
        if (res['data'].status == 'succeeded') {
          const now = new Date();
          let array = {
            status: 'Aprobado',
            respuesta: {
              status: 'Aprobado',
              fecha: now.toLocaleDateString(),
              autorizacion: res['data'].id,
              ccNumero:
                res['data']['charges']['data'][0]['payment_method_details'][
                  'card'
                ].last4,
              monto: res['data'].amount / 100,
              track: this.paymentData.clave,
              orderId: res['data'].id,
            },
          };
          this.stripeEmiter.emit(array);
          this.lottieService.setLoader(false, '');
        } else {
        }
      });
  }

  sanitize(event: KeyboardEvent) {
    let value = null;
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^A-Za-z\s]+/g, '');
  }

  //TODO: Manejadores de validacion de input de stripe

  onChangeCard(error: any) {
    var element = document.getElementById('card');
    if (error.complete) {
      element.classList.remove('input-error');
      element.classList.add('input-valid');
    } else {
      element.classList.remove('input-valid');
      element.classList.add('input-error');
    }

    this.paymentForm.patchValue({ cardNumber: !error.error });
  }

  onChangeCvv({ error }: any) {
    var element = document.getElementById('cvc');
    if (error === undefined) {
      element.classList.remove('input-error');
      element.classList.add('input-valid');
    } else {
      element.classList.remove('input-valid');
      element.classList.add('input-error');
    }

    this.paymentForm.patchValue({ cardCvv: !error });
  }

  onChangeExp({ error }: any) {
    var element = document.getElementById('exp');
    if (error === undefined) {
      element.classList.remove('input-error');
      element.classList.add('input-valid');
    } else {
      element.classList.remove('input-valid');
      element.classList.add('input-error');
    }

    this.paymentForm.patchValue({ cardExp: !error });
  }

  get card_holder_name() {
    return this.paymentForm.get('card_holder_name');
  }
  get email_holder() {
    return this.paymentForm.get('email_holder');
  }
  get phone() {
    return this.paymentForm.get('phone');
  }
}
