import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {
  constructor() { }
  d2h(d) {
    return d.toString(16);
  }
  bin2Hex(string) {
    var str = '', i = 0, tmp_len = string.length, c;

    for (; i < tmp_len; i += 1) {
      c = string.charCodeAt(i);
      str += this.d2h(c);
    }
    return str;
  }
  getBase64(myString) {
    const encryptedWord = CryptoJS.enc.Utf8.parse(myString);
    const encrypted = CryptoJS.enc.Base64.stringify(encryptedWord);
    return encrypted;
  }
  //The set method is use for encrypt the value.
  set(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(this.bin2Hex(keys));
    var iv = CryptoJS.enc.Utf8.parse(this.bin2Hex(keys));
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    let keyHex = encrypted.key.toString(); // actual ciphertext
    let ctHex = encrypted.ciphertext.toString(); // actual ciphertext
    let ivHex = encrypted.iv.toString();         // generated IV 
    let codes = {
      key: keys,
      keyHex: keyHex,
      iv: key,
      ivHex: ivHex,
      ct: ctHex
    }
    //console.log(codes);
    return encrypted.toString();
  }
  //The get method is use for decrypt the value.
  get(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(this.bin2Hex(keys));
    var iv = CryptoJS.enc.Utf8.parse(this.bin2Hex(keys));
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}