import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InputUtilsService {
  constructor() {}

  onInputChange(event: any) {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    return numericValue;
  }
}
