import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: [

  ]
})
export class ModalComponent {
  urlfmodal;
  urlmodal;
  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.urlmodal = this.sanitizer.bypassSecurityTrustUrl('https://3ds.happi.business');
    this.urlfmodal = this.urlmodal.changingThisBreaksApplicationSecurity;
  }
  closeModal() {
    
    this.activeModal.close('Modal Closed');
  }


}
