import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaypalService {

  constructor( private http: HttpClient ) { }

  sendPayment( data ) : Observable<any>{
    const URL = environment.url + 'cargo';
    const headers =  new HttpHeaders()
                        .set('Content-Type', 'application/x-www-form-urlencoded')
                        .set('Accept','application/json');

    let body = {
      params: {
        idPasarela : data['idPasarela'],
        idEmpresa : data['idEmpresa'],
        monto : data['monto'],
        tipoPago : data['tipoPago'],
        referencia : data['referencia'],
        descripcion : data['descripcion'],
        clave : data['clave'],
        email : data['email'],
        track : data['track'],
        paymentMethodId: data['paymentMethodId'],
      }
    };

    return this.http.post(URL,body, {headers}).pipe(map(res => res));
  }

  confirmPago( data ) : Observable<any>{
    const URL = environment.url + 'webhook/paypal';
    const headers =  new HttpHeaders()
                        .set('Content-Type', 'application/x-www-form-urlencoded')
                        .set('Accept','application/json');

    return this.http.post(URL,data, {headers}).pipe(map(res => res));
  }
}
