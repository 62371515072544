<div class="payment-form-container">
    <div *ngIf="showMessage==1" class="form-container w-100 green-border">
        <p class="title" [class.selected]="paymentForm.invalid"><span class="number">1</span>
            Complete la información y genere el pago.</p>
        <br>
        <p class="payment-amount">
            Marca de tarjeta</p>
        <ul class="nav nav-tabs nav-fill mb-3 justify-content-center" id="pills-tab" role="tablist">
            <li class="nav-item " role="presentation" [ngClass]="generalSelect == 1 ? 'nav-item-select' :'nav-item'">
                <button class="nav-link" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                    type="button" role="tab" aria-controls="pills-home" aria-selected="true" (click)="cargarMarca(1)"
                    [ngClass]="generalSelect == 1 ? 'active' :''">
                    <!-- <div class="icon-g">
                <img  class="img-fluid img-general" [src]="urlLogo1" />
              </div> -->
                    VISA
                </button>
            </li>
            <li class="nav-item" role="presentation" [ngClass]="generalSelect == 2 ? 'nav-item-select' :'nav-item'">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                    type="button" role="tab" aria-controls="pills-profile" aria-selected="false"
                    (click)="cargarMarca(2)" [ngClass]="generalSelect == 2 ? 'active' :''">
                    <!-- <img class="img-fluid img-general" [src]="urlLogo1" /> -->
                    MASTERCARD
                </button>
            </li>
            <!-- <li class="nav-item" role="presentation" [ngClass]="generalSelect == 3 ? 'nav-item-select' :'nav-item'">
                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
                    type="button" role="tab" aria-controls="pills-contact" aria-selected="false"
                    (click)="cargarMarca(3)" [ngClass]="generalSelect == 3 ? 'active' :''">
                    AMEX
                </button>
            </li> -->
        </ul>

        <p class="payment-amount">
            Tipo de tarjeta</p>
        <ul class="nav nav-tabs nav-fill mb-3 justify-content-center" id="pills-tab" role="tablist">
            <li class="nav-item " role="presentation" [ngClass]="tipoSelect == 1 ? 'nav-item-select' :'nav-item'">
                <button class="nav-link" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                    type="button" role="tab" aria-controls="pills-home" aria-selected="true" (click)="cargarTipo(1)"
                    [ngClass]="tipoSelect == 1 ? 'active' :''">
                    <!-- <div class="icon-g">
                  <img  class="img-fluid img-general" [src]="urlLogo1" />
                </div> -->
                    CREDITO
                </button>
            </li>
            <li class="nav-item" role="presentation" [ngClass]="tipoSelect == 2 ? 'nav-item-select' :'nav-item'">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                    type="button" role="tab" aria-controls="pills-profile" aria-selected="false" (click)="cargarTipo(2)"
                    [ngClass]="tipoSelect == 2 ? 'active' :''">
                    <!-- <img class="img-fluid img-general" [src]="urlLogo1" /> -->
                    DEBITO
                </button>
            </li>
            <li class="nav-item" role="presentation" [ngClass]="tipoSelect == 3 ? 'nav-item-select' :'nav-item'">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                    type="button" role="tab" aria-controls="pills-profile" aria-selected="false" (click)="cargarTipo(3)"
                    [ngClass]="tipoSelect == 3 ? 'active' :''">
                    <!-- <img class="img-fluid img-general" [src]="urlLogo1" /> -->
                    INTERNACIONAL
                </button>
            </li>

        </ul>
        <div *ngIf="vermetodo">
            <p class="payment-amount">
                Método de pago</p>
            <ul class="nav nav-tabs nav-fill mb-3 justify-content-center" id="pills-tab" role="tablist">
                <li *ngIf="vercontado" class="nav-item " role="presentation"
                    [ngClass]="metodopSelect == 1 ? 'nav-item-select' :'nav-item'">
                    <button class="nav-link" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                        type="button" role="tab" aria-controls="pills-home" aria-selected="true"
                        (click)="cargarMetodop(1)" [ngClass]="metodopSelect == 1 ? 'active' :''">
                        <!-- <div class="icon-g">
                    <img  class="img-fluid img-general" [src]="urlLogo1" />
                  </div> -->
                        Contado
                    </button>
                </li>
                <li *ngIf="ver3meses" class="nav-item" role="presentation"
                    [ngClass]="metodopSelect == 2 ? 'nav-item-select' :'nav-item'">
                    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                        aria-selected="false" (click)="cargarMetodop(2)" [ngClass]="metodopSelect == 2 ? 'active' :''">
                        <!-- <img class="img-fluid img-general" [src]="urlLogo1" /> -->
                        3 Meses
                    </button>
                </li>
                <li *ngIf="ver6meses" class="nav-item" role="presentation"
                    [ngClass]="metodopSelect == 3 ? 'nav-item-select' :'nav-item'">
                    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                        aria-selected="false" (click)="cargarMetodop(3)" [ngClass]="metodopSelect == 3 ? 'active' :''">
                        <!-- <img class="img-fluid img-general" [src]="urlLogo1" /> -->
                        6 Meses
                    </button>
                </li>
                <li *ngIf="ver9meses" class="nav-item" role="presentation"
                    [ngClass]="metodopSelect == 4 ? 'nav-item-select' :'nav-item'">
                    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                        aria-selected="false" (click)="cargarMetodop(4)" [ngClass]="metodopSelect == 4 ? 'active' :''">
                        <!-- <img class="img-fluid img-general" [src]="urlLogo1" /> -->
                        9 Meses
                    </button>
                </li>
                <li *ngIf="ver12meses" class="nav-item" role="presentation"
                    [ngClass]="metodopSelect == 5 ? 'nav-item-select' :'nav-item'">
                    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                        aria-selected="false" (click)="cargarMetodop(5)" [ngClass]="metodopSelect == 5 ? 'active' :''">
                        <!-- <img class="img-fluid img-general" [src]="urlLogo1" /> -->
                        12 Meses
                    </button>
                </li>
                <li *ngIf="ver18meses" class="nav-item" role="presentation"
                    [ngClass]="metodopSelect == 5 ? 'nav-item-select' :'nav-item'">
                    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                        aria-selected="false" (click)="cargarMetodop(6)" [ngClass]="metodopSelect == 5 ? 'active' :''">
                        <!-- <img class="img-fluid img-general" [src]="urlLogo1" /> -->
                        18 Meses
                    </button>
                </li>
            </ul>
        </div>

        <br>
        <p class="payment-amount">
            MONTO A PAGAR</p>
        <p class="oxxo-amount">{{montoinicial | currency:'USD':'symbol'}} {{ currency }}</p>



    </div>
    <div *ngIf="showMessage==2" class="form-container w-100 green-border">
        <p class="title" [class.selected]="paymentForm.invalid"><span class="number">1</span>
            Para continuar con el cargo, complete la información de pago en la pestaña nueva.</p>
        <br>
    </div>

    <div *ngIf="errorShow" class="form-container w-100 green-border">
        <p style="text-align: center; margin-top: 15px; color: red">
            <strong>Error: {{ error }} </strong>
        </p>

        <br>
    </div>
    <div *ngIf="showMessage==1">
        <button (click)="sendCardData()" class="w-100 pay-button">Generar pago</button>
    </div>
</div>
<ngx-spinner type="">
    <img src='assets/img/loader.gif' width="120" alt="" />"
</ngx-spinner>