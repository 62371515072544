// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlModal: 'http://localhost:4200',
  url: 'https://sandboxapi.payoff.mx/',
  // url: 'https://mpuapi.payoff.mx/',
  // url: 'http://localhost/PAY-OFF/payoff-api/',
  privateKey: 'wNbocfETIdxj1KKsHb1P0qd8C2FShQkBDMMaRL04x57',
  publicKey: 'u4QVYKhdE2PsOxFi5kLQHI6yy8geiXSQ',
  stripe_pk:
    'pk_test_51L5zoZCbdgvxZtiwYT5ZZfDTj1wmebpnCnwApryzVhAOaH5RlMq5oQ46xocBmptBc0bs0sf2bwgHgYC4ns8uHljX00iQxxSw9s',
  api: ' http://stripe.payoff.mx',
  mifelKey: 'OGE4Mjk0MTg1MmNhZDA1MzAxNTJjYmIwYmM1ZjAzN2R8UnFRbTlOYWpSaw==',
  descriptor: 9387690,
  entityId: '8ac7a4c8900cc79d01900e58b97e0162',
  firebase: {
    apiKey: 'AIzaSyD1DsGeZFHdUWBEw-IzS3oW-zyjpwreGKU',
    authDomain: 'tms-sandboxdev.firebaseapp.com',
    databaseURL: 'https://tms-sandboxdev-default-rtdb.firebaseio.com',
    projectId: 'tms-sandboxdev',
    storageBucket: 'tms-sandboxdev.appspot.com',
    messagingSenderId: '568881189451',
    appId: '1:568881189451:web:09112acaec26e0f8fa33ce',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
