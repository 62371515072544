<div class="payment-form-container">
    <div class="form-container w-100 green-border">
        <!-- <p class="title selected" ><span class="number">1</span>Ingresar los datos de la tarjeta de crédito o débito</p>
      <br>
        <p class="payment-amount">
            MONTO A PAGAR
        </p>
        <p class="oxxo-amount">{{amount | currency:'USD':'symbol'}}</p> -->
        <!-- <p class="subtitle color707070">
          Al presionar el botón "Generar pago" se abrirá una modal de pago de Stripe, favor de llenar los datos de la modal y presione "Pagar" para procesar el cargo           
      </p> -->

        <!-- TODO: FORMULARIO -->
        <p class="title" [class.selected]="paymentForm.invalid"><span class="number">1</span> Ingresar los datos de la
            tarjeta de crédito o débito</p>
        <form class="form row" [formGroup]="paymentForm">
            <div class="row d-flex align-items-center m-t-20">
                <div class="group col-6">
                    <div class="col-12">
                        <p>Número de tarjeta:</p>
                        <div id="card"></div>
                    </div>
                </div>
                <div class="group col-6">
                    <div class="col-12">
                        <p>Válida hasta:</p>
                        <div id="exp"></div>
                    </div>
                </div>
            </div>
            <div class="row d-flex align-items-center m-t-20">
                <div class="group col-9">
                    <div class="col-12">
                        <p>Nombre del titular:</p>
                        <input (keyup)="sanitize($event)" class="w-100" placeholder="Como aparece en la tarjeta"
                            [ngClass]="card_holder_name.dirty || card_holder_name.touched ? (card_holder_name.invalid ? 'error' : 'valid') : ''"
                            formControlName="card_holder_name" type="text" name="name">
                    </div>
                </div>
                <div class="group col-3">
                    <div class="col-12">
                        <p>CVV:</p>
                        <div id="cvc" name="cvc"></div>
                    </div>
                </div>
            </div>
            <div class="row d-flex align-items-center m-t-20">
                <div class="group col-6">
                    <div class="col-12">
                        <p>Email:</p>
                        <input class="w-100" placeholder="nombre@email.com"
                            [ngClass]="email_holder.dirty || email_holder.touched ? (email_holder.invalid ? 'error' : 'valid') : ''"
                            formControlName="email_holder" type="email" name="number-card">
                    </div>
                </div>
                <div class="group col-6">
                    <div class="col-12">
                        <p>Teléfono:</p>
                        <input class="w-100" maxlength="11"
                            [ngClass]="phone.dirty || phone.touched ? (phone.invalid ? 'error' : 'valid') : ''"
                            placeholder="Campo obligatorio" formControlName="phone" name="number-card"
                            (input)="onInputChange($event)">
                    </div>
                </div>
            </div>
        </form>
        <!-- TODO: FIN FORMULARIO -->
        <!-- <div class="row">
          <div class="col-12">
              <div class="col-xs-12 d-flex align-items-center secure2">
                  <div class="col-2">
                      <img class="img-fluid" src="assets/img/billete.svg">
                  </div>
                  <div class="col-10" style="padding: 0px 10px">
                      <p>Conoce nuestros métodos de pago disponible</p>
                      <a data-bs-toggle="modal" data-bs-target="#exampleModal"
                          style="text-decoration: underline; color: #0d6efd; cursor: pointer;">Tarjetas
                          participantes</a>
                  </div>
              </div>
          </div>

      </div> -->
    </div>

    <div class="form-container green-border" style="margin-top: 20px;">
        <p class="title selected">
            <span class="number">2</span>
            Tarjetas participantes
        </p>
        <p class="subtitle color707070">
            Los planes de meses sin intereses sólo aplican para tarjeta de crédito
        </p>
        <div class="table-payment">

            <div class="table-body">

                <div class="d-flex col-12"
                    style="border-bottom: 2px solid #DDDDDD;margin-bottom: 5px;align-items: center;">
                    <div class="d-flex justify-content-center banks-imgs f-wrap">
                        <div class="w-100 d-flex justify-content-center">
                            <img style="width: 150;height:40px" src="assets/img/visa.png">
                            <img style="width: 150;height: 35px;margin: 4px;" src="assets/img/mastercard.png">
                            <img style="width: 150;height: 55px;margin: 4px;" src="assets/img/americanexpress.png">
                            <img style="width: 150;height: 25px; margin-top: 10px;"
                                src="assets/img/americanexpress2.svg">
                        </div>
                        <div class="logosBancospequenios text-center">
                            <img style="width: 70px;height:25px" src="assets/img/HSBC.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Santander.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Scotiabank.svg">
                            <img style="width: 70px;height:25px" src="assets/img/banregio.svg">
                            <img style="width: 70px;height:25px" src="assets/img/liverpool.svg">
                            <img style="width: 30px;height:25px" src="assets/img/Itaucard.svg">
                            <img style="width: 70px;height:25px" src="assets/img/banco-del-bajio.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Banorte.svg">
                            <img style="width: 70px;height:25px" src="assets/img/afirme.svg">
                            <img style="width: 70px;height:25px" src="assets/img/banca-mifel.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Inbursa.svg">
                            <img style="width: 70px;height:25px" src="assets/img/famsa.svg">
                            <img style="width: 50px;height:25px" src="assets/img/banjercito.svg">
                            <img style="width: 50px;height:25px" src="assets/img/invex.svg">
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

    <button class="w-100 pay-button" [disabled]="paymentForm.invalid" (click)="sendCardData()">Pagar {{amount |
        currency:'USD':'symbol'}} {{ currency }} </button>

    <!-- <button  class="w-100 pay-button" 
      (click)="sendCardData()">Generar Pago</button> -->
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row modal_header">
                    <img style="height: 50px;" src="assets/img/happi-color.svg" alt="">
                </div>
                <div class="row mt-3 d-flex justify-content-centeral align-items-center">
                    <div class="col-12 text-center"> <strong>Tarjetas participantes</strong> <img class="img-fluid mx-3"
                            src="assets/img/billete.svg"> </div>

                </div>
                <div class="row my-3">
                    <div>
                        <p>Un cargo</p>
                        <div class="d-flex justify-content-around">
                            <img class="" style="width: 150;height:25px" src="assets/img/BBVA.svg">
                            <img style="width: 150;height:25px" src="assets/img/citibanamex.svg">
                        </div>

                    </div>
                </div>
                <div class="row my-3">
                    <div>
                        <p>Un cargo, 3, 6, 9 y 12 meses sin intereses</p>
                        <div class="logosBancos text-center">
                            <img style="width: 70px;height:25px" src="assets/img/HSBC.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Santander.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Scotiabank.svg">
                            <img style="width: 70px;height:25px" src="assets/img/banregio.svg">
                            <img style="width: 70px;height:25px" src="assets/img/liverpool.svg">
                            <img style="width: 30px;height:25px" src="assets/img/Itaucard.svg">
                            <img style="width: 70px;height:25px" src="assets/img/banco-del-bajio.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Banorte.svg">
                            <img style="width: 70px;height:25px" src="assets/img/afirme.svg">
                            <img style="width: 70px;height:25px" src="assets/img/banca-mifel.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Inbursa.svg">
                            <img style="width: 50px;height:25px" src="assets/img/famsa.svg">
                            <img style="width: 50px;height:25px" src="assets/img/banjercito.svg">
                            <img style="width: 50px;height:25px" src="assets/img/invex.svg">
                        </div>

                    </div>
                </div>
                <div class="row my-3">
                    <div>
                        <p>Un cargo, 3, 6, 9, 12 y 18 meses sin intereses</p>
                        <div class="logosBancos text-center">
                            <img style="width: 70px;height:35px" src="assets/img/americanexpress2.svg">

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>