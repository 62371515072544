import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CobroComponent } from './payments-components/happi/cobro/cobro.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SafePipe } from './pipes/safe.pipe';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ModalComponent } from './payments-components/happi/modal/modal.component';
import { ExpirationErrorComponent } from './expiration-error/expiration-error.component';
import { PrivacyModalComponent } from './privacy-modal/privacy-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RespuestaComponent } from './payments-components/pago/respuesta/respuesta.component';
import { ModalFiservComponent } from './payments-components/fiserv/modal-fiserv/modal-fiserv.component';
import { SafeResourceUrlPipe } from './payments-components/fiserv/modal-fiserv/safe-resource-url.pipe';
import { ModalStripeComponent } from './payments-components/stripe/modal-stripe/modal-stripe.component';
import { ValidacionStripeComponent } from './validacion-stripe/validacion-stripe.component';
import { StatusYoloComponent } from './status-yolo/status-yolo.component';
import { RespuestaMifelComponent } from './respuesta-mifel/respuesta-mifel.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    CobroComponent,
    SafePipe,
    ModalComponent,
    ExpirationErrorComponent,
    PrivacyModalComponent,
    RespuestaComponent,
    ModalFiservComponent,
    SafeResourceUrlPipe,
    ModalStripeComponent,
    ValidacionStripeComponent,
    StatusYoloComponent,
    RespuestaMifelComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    ImageCropperModule,
    LottieModule.forRoot({ player: playerFactory }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
