import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['../modal-payment/modal-payment.component.css']
})
export class PaymentStatusComponent implements OnInit {
  @Input() type = null;
  @Input() message = null;
  @Input() errorMessage = null;
  @Input() conektaResponse = null;
  constructor() { }

  ngOnInit(): void {
  }

  refresh(){
    window.location.reload();
  }

}
