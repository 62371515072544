import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-validacion-stripe',
  templateUrl: './validacion-stripe.component.html',
  styleUrls: ['./validacion-stripe.component.css']
})
export class ValidacionStripeComponent implements OnInit {

  constructor( private route:ActivatedRoute ) { 
    this.route.url.subscribe(params => {
		
		});
  }

  ngOnInit(): void {
  }

}
