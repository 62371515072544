import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import html2canvas from 'html2canvas';
import { NgxSpinnerService } from 'ngx-spinner';
import { LottieService } from '../../services/lottie.service';
import { environment } from '../../../environments/environment';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { documentosService } from 'src/app/services/documentos.service';
import { Moment } from 'moment';
import { RespuestaComponent } from '../pago/respuesta/respuesta.component';
import { InputUtilsService } from 'src/app/services/input-utils.service';

@Component({
  selector: 'app-pago-usd',
  templateUrl: './pago-usd.component.html',
  styleUrls: ['../payment-components.css'],
})
export class PagoUsdComponent implements OnInit {
  @ViewChild(ImageCropperComponent, { static: true })
  imageCropper: ImageCropperComponent;

  @Input() paymentData = null;
  paymentForm: FormGroup;
  private serviceURL = environment.url + 'cargo';
  amount = null;
  payment_id = null;
  paymentTypeAmounts = 0;
  oxxoResponse = null;
  clave;
  referencia;
  status;
  verformulario;
  cardData = {
    card_holder_name: null,
    email_holder: null,
    phone: null,
  };
  prueba: boolean = false;
  valueSelect: number = 5;
  name = 'Angular';

  imageChangedEvent: any = '';
  croppedImage: any = '';
  bancoReceptorDis: boolean = false;
  showCropper = false;
  myimage;
  myimglog;
  base64ContentArray;
  mimeType;
  reader = new FileReader();
  today = new Date();
  dd = this.today.getDate();
  mm = this.today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
  mmString = this.mm.toString();
  ddString = this.dd.toString();
  yyyy = this.today.getFullYear();
  prueba2;
  arreglopago = [];
  file!: File;
  currency: any;
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private spinnerService: NgxSpinnerService,
    private lottieService: LottieService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public documentosService: documentosService,
    private inputUtilsService: InputUtilsService
  ) {
    this.paymentForm = this.formBuilder.group({
      card_holder_name: ['', Validators.required],
      email_holder: ['', [Validators.required, Validators.email]],
      phone: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(11),
          Validators.pattern('[- +()0-9]+'),
        ],
      ],
      TipoPago: ['', Validators.required],
      fecha: ['', [Validators.required]],
      Desc: [''],
      img: [''],
    });
    this.route.queryParams.subscribe((params) => {
      this.payment_id = params['id'];
    });
  }

  ngOnInit(): void {
    console.log(this.paymentData.datos);

    if (this.mm < 10) {
      this.mmString = '0' + this.mm;
    }
    if (this.dd < 10) {
      this.ddString = '0' + this.dd;
    }
    this.prueba2 =
      this.yyyy.toString() +
      '-' +
      this.mmString.toString() +
      '-' +
      this.ddString.toString();
    this.showPaymentsTimes();
    var statusTest = 'Pendiente revision';
    // if(this.paymentData.monto.PUSD.status == 'Pendiente revision'){
    if (statusTest == 'Pendiente revision') {
      this.getData();
    }
  }

  addValidatorPhone(group) {
    group.controls.phone.setValidators([Validators.required]);
    group.controls.email_holder.setValidators([Validators.required]);
  }

  onInputChange(event: any) {
    const numericValue = this.inputUtilsService.onInputChange(event);

    this.phone.setValue(numericValue);
  }

  sanitize(event: KeyboardEvent) {
    let value = null;
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^A-Za-z\s]+/g, '');
  }

  showPaymentsTimes() {
    let arreglobase = this.paymentData.datos['PUSD']['configuracion'];
    let arrvalue;
    let arrnombre;
    let arreglouno = [];
    arreglobase.forEach(function (value) {
      if (value.Status == 1) {
        switch (value.Nombre) {
          case 'Deposito':
            arrvalue = 1;
            arrnombre = 'Depósito';
            break;
          case 'Transferencia':
          case 'Transaccion':
            arrvalue = 2;
            arrnombre = 'Transferencia';
            break;
          case 'Efectivo':
            arrvalue = 3;
            arrnombre = 'Efectivo';
            break;
          case 'Tarjeta de credito o debito':
          case 'Tarjeta presente':
            arrvalue = 4;
            arrnombre = 'Tarjeta de crédito o débito';
            break;
          case 'Cheque':
            arrvalue = 5;
            arrnombre = 'Cheque';
            break;
        }
        arreglouno.push({
          nombre: arrnombre,
          value: arrvalue,
        });
      }
    });
    this.arreglopago = arreglouno;
    this.paymentTypeAmounts = this.paymentData.monto['PUSD']['cargo'];
    this.currency = this.paymentData.datos['PUSD']['currency'];

    this.clave = this.paymentData.clave;
    this.referencia = this.paymentData.monto['PUSD']['referencia'];
    this.status = this.paymentData.monto['PUSD']['status'];
    if (this.status == 'Pendiente') {
      this.verformulario = true;
    } else {
      this.verformulario = true;
    }
  }

  onChangeOption(value) {
    this.valueSelect = value.target.value;
    if (this.valueSelect == 1) {
      // this.paymentForm.addControl('noAutorizacion', new FormControl('', Validators.required));
      this.paymentForm.addControl(
        'bancoDep',
        new FormControl('', Validators.required)
      );
      this.paymentForm.removeControl('folio');
      this.paymentForm.removeControl('noAutorizacion');
      this.paymentForm.removeControl('crastreo');
    }
    if (this.valueSelect == 2) {
      // this.paymentForm.addControl('noAutorizacion', new FormControl('', Validators.required));
      this.paymentForm.addControl(
        'bancoDep',
        new FormControl('', Validators.required)
      );
      this.paymentForm.addControl(
        'crastreo',
        new FormControl('', Validators.required)
      );
      this.paymentForm.removeControl('folio');
      this.paymentForm.removeControl('noAutorizacion');
    }
    if (this.valueSelect == 3) {
      //this.paymentForm.addControl('folio', new FormControl('', Validators.required));
      this.paymentForm.addControl('folio', new FormControl(''));
      this.paymentForm.removeControl('crastreo');
      this.paymentForm.removeControl('noAutorizacion');
      this.paymentForm.removeControl('bancoDep');
    }
    if (this.valueSelect == 4) {
      this.paymentForm.addControl(
        'noAutorizacion',
        new FormControl('', Validators.required)
      );
      this.paymentForm.removeControl('crastreo');
      this.paymentForm.removeControl('folio');
      this.paymentForm.removeControl('bancoDep');
    }
  }

  getData() {
    this.prueba = true;
    this.paymentForm.addControl('bancoDep', new FormControl(''));
    this.paymentForm.addControl('crastreo', new FormControl(''));
    this.paymentForm.addControl('folio', new FormControl(''));
    this.paymentForm.addControl('noAutorizacion', new FormControl(''));
    this.paymentForm.patchValue({
      card_holder_name: this.paymentData.monto.PUSD.nombre,
      email_holder: this.paymentData.monto.PUSD.email,
      phone: this.paymentData.monto.PUSD.telefono,
      TipoPago: this.paymentData.monto.PUSD.tipopago,
      fecha: this.paymentData.monto.PUSD.fecha,
      Desc: this.paymentData.monto.PUSD.descripcion,
      bancoDep: this.paymentData.monto.PUSD.banco,
      img: this.paymentData.monto.PUSD.img,
      crastreo: this.paymentData.monto.PUSD.crastreo,
      folio: '',
      noAutorizacion: '',
    });
    setTimeout(() => {
      this.paymentForm.patchValue({
        bancoDep: this.paymentData.monto.PUSD.banco,
        // img: this.paymentData.monto.PUSD.img,
        crastreo: this.paymentData.monto.PUSD.crastreo,
        folio: this.paymentData.monto.PUSD.folio,
        noAutorizacion: this.paymentData.monto.PUSD.folio,
      });
    }, 100);
    setTimeout(() => {
      this.paymentForm.patchValue({
        bancoDep: this.paymentData.monto.PUSD.banco,
        // img: this.paymentData.monto.PUSD.img,
        crastreo: this.paymentData.monto.PUSD.crastreo,
        folio: this.paymentData.monto.PUSD.folio,
        noAutorizacion: this.paymentData.monto.PUSD.folio,
      });
    }, 100);
    switch (this.paymentData.monto.PUSD.tipopago) {
      case 1:
        this.valueSelect = 1;
        break;
      case 2:
        this.valueSelect = 2;
        break;
      case 3:
        this.valueSelect = 3;
        break;
      case 4:
        this.valueSelect = 4;
        break;
    }
  }

  sendPagoData() {
    this.lottieService.setLoader(true, '');
    if (this.valueSelect != 3 && this.file == undefined) {
      this.open('IMAGEN');
    } else {
      var folioFinal;
      if (this.valueSelect == 3) {
        folioFinal = this.paymentForm.value.folio;
      } else if (this.valueSelect == 4) {
        folioFinal = this.paymentForm.value.noAutorizacion;
      }
      var pagoData = {
        params: {
          nombre: this.paymentForm.value.card_holder_name,
          monto: this.paymentData.monto.PUSD.cargo,
          img: this.myimage,
          idPasarela: 'PUSD',
          idEmpresa: this.paymentData.idempresa,
          fechaExp: this.paymentForm.value.fecha,
          clave: this.paymentData.monto.PUSD.clave,
          crastreo: this.paymentForm.value.crastreo,
          referencia: this.paymentData.monto.PUSD.referencia,
          descripcion: this.paymentForm.value.Desc,
          tipoPago: this.valueSelect,
          email: this.paymentForm.value.email_holder,
          telefono: this.paymentForm.value.phone,
          banco: this.paymentForm.value.bancoDep,
          folio: folioFinal,
        },
      };
      this.documentosService
        .sendPagoData(this.serviceURL, pagoData)
        .subscribe((res) => {
          //this.lottieService.setLoader(false, '');
          this.open(res.status);
        });
    }
  }

  open(data) {
    //this.spinnerService.hide();
    this.lottieService.setLoader(false, '');
    const modalRef = this.modalService.open(RespuestaComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.status = data;
    modalRef.result.then((result) => {}).catch((error) => {});
    /* modalRef.componentInstance.valueChange.subscribe(($e) => {
      console.info('hola soy la e',$e);
    }); */
  }

  onChange(event) {
    if (event.target.files.length == 0) {
      return;
    }
    this.file = event.target.files[0];

    //const reader = new FileReader();
    this.reader.onload = () => {
      this.myimage = this.reader.result as string;
      this.checkf(this.reader.result);
    };
    this.reader.readAsDataURL(this.file);
    /* console.info('imagen tres',this.myimage);
      //check imagen
      this.base64ContentArray = this.myimage.split(",");
      this.mimeType = this.base64ContentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];
      if(this.mimeType=='application/pdf'){
        this.myimage = '../../../assets/img/pdf.svg';
      }
      console.info('imagen',this.myimage); */
  }

  checkf(check) {
    this.base64ContentArray = this.myimage.split(',');
    this.mimeType = this.base64ContentArray[0].match(
      /[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/
    )[0];
    if (this.mimeType == 'application/pdf') {
      this.myimglog = '../../../assets/img/pdf.svg';
    } else {
      this.myimglog = this.myimage;
    }
  }

  get card_holder_name() {
    return this.paymentForm.get('card_holder_name');
  }
  get email_holder() {
    return this.paymentForm.get('email_holder');
  }
  get phone() {
    return this.paymentForm.get('phone');
  }
  get bancoDep() {
    return this.paymentForm.get('bancoDep');
  }
  get fecha() {
    return this.paymentForm.get('fecha');
  }
  get bancoTransf() {
    return this.paymentForm.get('bancoTransf');
  }
  get crastreo() {
    return this.paymentForm.get('crastreo');
  }
  get folio() {
    return this.paymentForm.get('folio');
  }
  get noAutorizacion() {
    return this.paymentForm.get('noAutorizacion');
  }
}
