import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModalPaymentComponent } from './modal-payment/modal-payment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ConektaComponent } from './payments-components/conekta/conekta.component';
import { HappiComponent } from './payments-components/happi/happi.component';
import { OxxoComponent } from './payments-components/oxxo/oxxo.component';
import { PagoComponent } from './payments-components/pago/pago.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ExpirationErrorComponent } from './expiration-error/expiration-error.component';
import { StatusYoloComponent } from './status-yolo/status-yolo.component';
import { FiservComponent } from './payments-components/fiserv/fiserv.component';
import { CodiComponent } from './payments-components/codi/codi.component';
import { StripeComponent } from './payments-components/stripe/stripe.component';
import { YolopagoComponent } from './payments-components/yolopago/yolopago.component';
import { ValidacionStripeComponent } from './validacion-stripe/validacion-stripe.component';
import { PaypalComponent } from './payments-components/paypal/paypal.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { BanorteComponent } from './payments-components/banorte/banorte.component';
import { PagoUsdComponent } from './payments-components/pago-usd/pago-usd.component';
import { MifelComponent } from './payments-components/mifel/mifel.component';
import { RespuestaMifelComponent } from './respuesta-mifel/respuesta-mifel.component';

const routes: Routes = [
  // { path: '', component: ModalPaymentComponent},
  { path: ':id', component: ModalPaymentComponent },
  { path: 'liga/error-vigencia', component: ExpirationErrorComponent },
  { path: 'respuesta/yolo', component: StatusYoloComponent },
  { path: 'estatus/:id', component: ValidacionStripeComponent },
  { path: 'mifel/:id', component: RespuestaMifelComponent },
];

@NgModule({
  declarations: [
    ModalPaymentComponent,
    ConektaComponent,
    HappiComponent,
    PaymentStatusComponent,
    OxxoComponent,
    PagoComponent,
    FiservComponent,
    CodiComponent,
    StripeComponent,
    PaypalComponent,
    YolopagoComponent,
    BanorteComponent,
    PagoUsdComponent,
    MifelComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxPayPalModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
