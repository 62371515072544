import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MifelService {
  private url = 'https://eu-test.oppwa.com/v1/checkouts';
  private descriptor = environment.descriptor;
  private entityId = environment.entityId;
  private headers = new HttpHeaders({
    Authorization: 'Bearer ' + environment.mifelKey,
    'Content-Type': 'application/x-www-form-urlencoded',
  });

  constructor(private http: HttpClient) {}

  postData(amount: number): Observable<any> {
    const data = `entityId=${this.entityId}&amount=${amount}&currency=MXN&paymentType=DB&descriptor=${this.descriptor}&testMode=EXTERNAL`;

    return this.http.post(this.url, data, { headers: this.headers });
  }
}
