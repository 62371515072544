import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-respuesta-mifel',
  templateUrl: './respuesta-mifel.component.html',
  styleUrls: ['./respuesta-mifel.component.css'],
})
export class RespuestaMifelComponent implements OnInit {
  checkoutId: string;
  dataBrands: string = 'MASTER';

  constructor(private route: ActivatedRoute, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.checkoutId = this.route.snapshot.paramMap.get('id');
    this.dataBrands = localStorage.getItem('card');
    this.loadPaymentWidgetScript(this.checkoutId);
  }

  loadPaymentWidgetScript(checkoutId: string) {
    console.log(this.checkoutId);

    const script = this.renderer.createElement('script');
    script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
    script.onload = () => this.loadCustomScript();
    this.renderer.appendChild(document.body, script);
  }

  loadCustomScript() {
    let msi = localStorage.getItem('msi');
    const customScript = this.renderer.createElement('script');
    customScript.type = 'text/javascript';
    customScript.text = `
      var wpwlOptions = {
        style: "card",
        onReady: function () {
          var numberOfInstallmentsHtml = '<div class="wpwl-label wpwl-label-custom" style="display:inline-block">Número de cuotas</div>' +
            '<div class="wpwl-wrapper wpwl-wrapper-custom" style="display:inline-block">' +
            '<select name="recurring.numberOfInstallments"><option value="${msi}">${msi}</option>' +
            '</div >';
          $('form.wpwl-form-card').find('.wpwl-button').before(numberOfInstallmentsHtml);
        }
      }`;
    this.renderer.appendChild(document.body, customScript);
  }
}
