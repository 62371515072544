import { Injectable } from '@angular/core';


import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(private http: HttpClient) {
  }

 /**
  * It returns an observable of type any
  * @param {string} id - The id of the order you want to get the details of.
  * @returns An observable of any type.
  */
  getOrderDetail(id:string): Promise<any> {
    return this.http.patch(`${environment.api}/orders/detalle/${id}`, {id}).toPromise()
  }
  // getOrderDetail(id: string): Observable<any> {
  //    return this.http.patch(`${environment.api}/orders/createLiga/test`).toPromise()
  // }

  /**
   * It sends a payment to the server.
   * @param {string} token - The token returned by Stripe.js
   * @param {string} id - The ID of the order you want to pay for.
   */
  sendPayment(data): Promise<any> {
    let idPasarela = data['idPasarela'];
    let idEmpresa = data['idEmpresa'];
    let monto = data['monto'];
    let tipoPago = data['tipoPago'];
    let referencia = data['referencia'];
    let descripcion = data['descripcion'];
    let clave = data['clave'];
    let email = data['email'];
    let track = data['track'];
    let urlBack = `${environment.urlModal}/${data['claveUrl']}`;

    return this.http.patch(`${environment.api}/orders/createLiga/test`,
      {
        idPasarela,
        idEmpresa,
        monto,
        tipoPago,
        referencia,
        descripcion,
        clave,
        email,
        track,
        urlBack
        // token
      }).toPromise()
  }

  /**
   * It takes an object with a name and amount property, and returns an observable of any
   * @param data - { name: string, amount: number }
   * @returns Observable<any>
   */
  generateOrder(data: { name: string, amount: number }): Observable<any> {
    return this.http.post(`${environment.api}/orders`, data)
  }

  /**
   * It takes an order id, and sends a patch request to the server to confirm the order
   * @param {string} id - The id of the order you want to confirm.
   * @returns The order object
   */
  confirmOrder(id:string): Promise<any> {
    return this.http.patch(`${environment.api}/orders/confirm/${id}`, {}).toPromise()
  }

}
