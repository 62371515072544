import {
  Component,
  OnInit,
  Input,
  HostListener,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { interval, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { PrivacyModalComponent } from '../privacy-modal/privacy-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import { RestService } from '../services/rest.service';
import { StripeService } from '../services/stripe.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { debounceTime, skip } from 'rxjs/operators';
@Component({
  selector: 'app-modal-payment',
  templateUrl: './modal-payment.component.html',
  styleUrls: ['./modal-payment.component.css'],
})
export class ModalPaymentComponent implements OnInit {
  paymentForm: FormGroup;
  vencimiento: Date;
  fechaToday: Date;
  validacionError: boolean;
  interval = null;
  // private serviceURL = 'https://mpuapi.payoff.mx/';
  private serviceURL = environment.url;
  amount = null;
  paymentsFiltered = [];
  payment_id = null;
  payment_data = null;
  paymentStatus;
  conektaResponse = null;
  happiResponse = null;
  descripcion: string;
  refencia: string;
  logoEmpresaBase64: string;
  nombreEmpresa: string;
  validation = false;
  numerosent;
  cardData = {
    number_card: null,
    valid_month: null,
    valid_year: null,
    card_holder_name: null,
    card_cvv: null,
    email_holder: null,
    paymentTime: null,
    phone: null,
  };
  keysIgnore = [
    'Backspace',
    'Tab',
    'ArrowLeft',
    'ArrowUp',
    'ArrowDown',
    'ArrowRight',
  ];
  paymentsType = [
    {
      id: 'YOLO',
      name: 'Yolo Pago',
      img: 'assets/img/Yolopago_Logo_Grey.svg',
      selected: false,
      show: false,
    },
    {
      id: 'HAPP',
      name: 'Crédito y débito',
      img: 'assets/img/happi-bw.svg',
      selected: true,
      show: false,
    },
    {
      id: 'COKT',
      name: 'Crédito y débito',
      img: 'assets/img/conekta-bw.svg',
      selected: false,
      show: false,
    },
    {
      id: 'PAYPAL',
      name: 'PayPal01',
      img: 'assets/img/PayPal_Logo_Grey.svg',
      selected: false,
      show: false,
    },
    {
      id: 'OXXO',
      name: 'Pago en Oxxo',
      img: 'assets/img/oxxo-bw.svg',
      selected: false,
      show: false,
    },
    {
      id: 'CODI',
      name: 'CoDi',
      img: 'assets/img/qrcode-bw.svg',
      selected: false,
      show: false,
    },
    {
      id: 'PAGO',
      name: 'Pago en efectivo',
      img: 'assets/img/Pago-manual-Grey.svg',
      selected: false,
      show: false,
    },
    {
      id: 'STRE',
      name: 'Stripe',
      img: 'assets/img/Stripe_Logo_Grey.svg',
      selected: false,
      show: false,
    },
    {
      id: 'FISV',
      name: 'Fiserv',
      img: 'assets/img/Fiserv_Grey_Logo_0121.svg',
      selected: false,
      show: false,
    },
    {
      id: 'BNTE',
      name: 'Banorte',
      img: 'assets/img/Banorte_Logo_Grey.svg',
      selected: false,
      show: false,
    },
    {
      id: 'PUSD',
      name: 'Pago USD',
      img: 'assets/img/Pago-manual-Colores.svg',
      selected: false,
      show: false,
    },
    {
      id: 'MFEL',
      name: 'Banco MIFEL',
      img: 'assets/img/Mifel_Color.svg',
      selected: false,
      show: false,
    },
  ];

  urlLogo1 = 'assets/img/general/credit-card-color.svg';
  urlLogo2 = 'assets/img/general/store-solid.svg';
  urlLogo3 = 'assets/img/general/money-bill-solid.svg';

  private checkidUrl = environment.url + 'checkid/';
  private checkDetalleUrl = environment.url + 'checkidDetalle/';

  folio: string;
  pasarelaId: string;
  generalSelect = 1;
  idSelect: string;

  pagoTarjeta = [];

  tarjetaCount = 0;
  efectivoCount = 0;
  otrosCount = 0;
  items$: Observable<any[]>;
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private restService: RestService,
    private stripeSrv: StripeService,
    private db: AngularFireDatabase
  ) {
    /* this.route.queryParams.subscribe(params => {
			this.payment_id = params['id'];
		}); */

    this.validacionError;
    this.route.url.subscribe((params) => {
      //
      this.payment_id = params[0].path;
    });
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  ngOnInit(): void {
    this.getDataTrack();
  }

  getDataTrack() {
    const cobrourl = this.serviceURL + this.payment_id;
    //Obtemos la fecha de ingreso al sitio y se combierte a formato UTC
    let date = new Date();
    this.fechaToday = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    this.http.get(cobrourl).subscribe((data) => {
      this.payment_data = [];
      this.payment_data = data;
      this.descripcion = this.payment_data.descripcion;
      this.refencia = this.payment_data.refencia;
      this.logoEmpresaBase64 = this.payment_data.logo;
      this.nombreEmpresa = this.payment_data.nombre;
      this.folio = this.payment_data.folio;
      this.pasarelaId = this.payment_data.pasarelaId;
      this.firebase(this.payment_data.idempresa, this.payment_data.idproducto);
      // Obtenemos la fecha de vencimiento
      if (this.payment_data.vencimiento != '0000-00-00 00:00:00') {
        this.vencimiento = new Date(this.payment_data.vencimiento);

        // Validamos las fechas si ya esta vencido lo mandamos a pagina de error si no se crear el observable que valida cada segundo
        if (this.vencimiento <= this.fechaToday) {
          this.router.navigate(['/liga/error-vigencia']);
        } else {
          this.interval = interval(1000).subscribe((x) => {
            let date = new Date();
            this.fechaToday = new Date(
              date.getUTCFullYear(),
              date.getUTCMonth(),
              date.getUTCDate(),
              date.getUTCHours(),
              date.getUTCMinutes(),
              date.getUTCSeconds()
            );

            if (this.vencimiento <= this.fechaToday) {
              this.router.navigate(['/liga/error-vigencia']);
            }
          });
        }
      }

      if (this.payment_data.status != 'ERROR') {
        if (this.pasarelaId && this.pasarelaId == 'STRE') {
          this.detalleStripe();
        } else {
          this.filterPayments();
        }
      } else {
        this.setPaymentStatus();
      }
    });
  }

  firebase(idempresa: string, idproducto: string) {
    // Especifica la referencia a la colección o nodo que quieres observar
    this.items$ = this.db
      .list(`RealtimePO/configuracion/${idempresa}/${idproducto}`)
      .valueChanges();

    // Suscríbete a los cambios en la referencia, ignorando la primera emisión y aplicando debounce
    this.items$
      .pipe(
        skip(1), // Ignora la primera emisión
        debounceTime(300) // Espera 300 ms antes de emitir el siguiente valor
      )
      .subscribe((items) => {
        console.log(items);
        this.getDataTrack();
      });
  }

  async detalleStripe(): Promise<any> {
    this.stripeSrv.getOrderDetail(this.folio).subscribe((res) => {
      if (res.data.status == 'succeeded') {
        const now = new Date();
        let array = {
          fecha: now.toLocaleDateString(),
          autorizacion: res.data.customer,
          ccNumero: '',
          monto: res.data.amount / 100,
          orderId: res.data.id,
        };

        // respuesta.status = 'Aprobado';
        // respuesta.fecha = res['ca_fecha'];
        // respuesta.autorizacion = res['ca_autorizacion'];
        // respuesta.ccNumero = res['ca_ccnumero'];
        // respuesta.monto = res['ca_montofinal'];
        // respuesta.orderId = res['ca_folio'];

        this.conektaResponse = array;

        this.setPagoStrype();
      } else {
        this.filterPayments();
      }
    });
  }
  //Call this method in the image source, it will sanitize it.
  transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.logoEmpresaBase64
    );
  }

  changePaymentOption(paymentOption) {
    // Define los arreglos de imágenes
    const resPaymentsType = [
      { id: 'YOLO', img: 'assets/img/Yolopago_Logo_Grey.svg' },
      { id: 'HAPP', img: 'assets/img/happi-bw.svg' },
      { id: 'COKT', img: 'assets/img/conekta-bw.svg' },
      { id: 'PAYPAL', img: 'assets/img/PayPal_Logo_Grey.svg' },
      { id: 'OXXO', img: 'assets/img/oxxo-bw.svg' },
      { id: 'CODI', img: 'assets/img/qrcode-bw.svg' },
      { id: 'PAGO', img: 'assets/img/Pago-manual-Grey.svg' },

      { id: 'STRE', img: 'assets/img/Stripe_Logo_Grey.svg' },
      { id: 'FISV', img: 'assets/img/Fiserv_Grey_Logo_0121.svg' },
      { id: 'BNTE', img: 'assets/img/Banorte_Logo_Grey.svg' },
    ];

    const imagenesColor = [
      { id: 'HAPP', img: 'assets/img/happi-color.svg' },
      { id: 'YOLO', img: 'assets/img/Yolopago_Logo_Color.svg' },
      { id: 'COKT', img: 'assets/img/conekta-color.svg' },
      { id: 'PAYPAL', img: 'assets/img/PayPal_Logo_Color.svg' },
      { id: 'OXXO', img: 'assets/img/oxxo.svg' },
      { id: 'CODI', img: 'assets/img/qrcode-color.svg' },
      { id: 'BNTE', img: 'assets/img/Banorte_Logo_Color.svg' },
      { id: 'PAGO', img: 'assets/img/Pago-manual-Colores.svg' },
      { id: 'STRE', img: 'assets/img/Stripe_Logo_Color.svg' },
      { id: 'FISV', img: 'assets/img/fiserv-color.svg' },
    ];

    // Crear mapas para acceso rápido
    const resPaymentsTypeMap = new Map(
      resPaymentsType.map((pt) => [pt.id, pt.img])
    );
    const imagenesColorMap = new Map(
      imagenesColor.map((ic) => [ic.id, ic.img])
    );

    // Actualizar imagenes
    this.pagoTarjeta.forEach((p) => {
      const img = resPaymentsTypeMap.get(p.id);
      const imgColor =
        p.id === paymentOption.id ? imagenesColorMap.get(p.id) : img;
      p.img = imgColor || img; // Usar imgColor si está definido, de lo contrario usar img
    });

    // Actualizar el estado de selección
    this.idSelect = paymentOption.id;
    this.paymentSelected.selected = false;
    paymentOption.selected = true;
  }

  getResponse(data) {
    if (data.status == 'ERROR') this.conektaResponse = data;
    else this.conektaResponse = data.respuesta;
    this.setPaymentStatus();
  }
  getResponseHappi(data) {
    this.happiResponse = data;
    this.setPaymentStatusHappi();
  }

  getResponseCodi(data) {
    if (data.status == 'ERROR') this.conektaResponse = data;
    else this.conektaResponse = data.respuesta;
    this.setPaymentStatusFiserv();
  }
  getResponseFiserv(data) {
    if (data.status == 'ERROR') {
      this.conektaResponse = data;
      this.setPaymentStatusFiserv();
    } else if (data.status == 'Aprobado') {
      this.conektaResponse = data.respuesta;

      this.setPaymentStatusFiserv();
    } else if (data.status == 'Fallido') {
      this.conektaResponse = data.respuesta;
      this.conektaResponse.msg = data.respuesta.mensaje;
      this.setPaymentStatusFiserv();
    } else if (data.status == 'PENDIENTE') {
      this.spinnerService.show();
      let respuesta = data.respuesta;
      const urlcheck = this.checkDetalleUrl + respuesta.track;
      let count = 0;
      let intervalo = interval(5000);

      let sub = intervalo.subscribe(() => {
        count++;
        this.http.get(urlcheck).subscribe((res) => {
          if (res['ca_status'] != 'Pendiente') {
            sub.unsubscribe();
            this.spinnerService.hide();
            if (res['ca_status'] == 'Denegado') {
              respuesta.status = 'ERROR';
              // respuesta.msg = 'Ocurrio un error al momento del procesar el cargo, favor de intentarlo nuevamente.';
              respuesta.msg = res['ca_respuesta'];
              this.conektaResponse = respuesta;
              this.setPaymentStatusFiserv();
            } else {
              const urlcheck = this.checkDetalleUrl + respuesta.track;
              this.http.get(urlcheck).subscribe((res) => {
                let monto = res['ca_montofinal'];
                let comision = res['ca_comision'];
                //let total = monto + comision;
                let total = monto;
                respuesta.status = 'Aprobado';
                respuesta.fecha = res['ca_fecha'];
                respuesta.autorizacion = res['ca_autorizacion'];
                respuesta.ccNumero = res['ca_ccnumero'];
                respuesta.monto = total;
                respuesta.orderId = res['ca_folio'];

                this.conektaResponse = respuesta;
                this.setPaymentStatusFiserv();
              });
            }
          } else if (count == 6) {
            sub.unsubscribe();
            this.spinnerService.hide();
            respuesta.status = 'ERROR';
            // respuesta.msg = 'Ocurrio un error al momento del procesar el cargo, favor de intentarlo nuevamente.';
            respuesta.msg =
              'Ocurrio un error al momento del procesar el cargo, favor de intentarlo nuevamente.';
            this.conektaResponse = respuesta;
            this.setPaymentStatusFiserv();
          }
        });
      });
    }
  }
  filterPayments() {
    let payments = [...this.paymentsType];

    const order = ['HAPP', 'YOLO', 'COKT', 'FISV'];

    payments.sort((a, b) => {
      const indexA = order.indexOf(a.id);
      const indexB = order.indexOf(b.id);

      if (indexA === -1 && indexB === -1) {
        return 0; // Both are not in the order list, keep their relative order
      }
      if (indexA === -1) {
        return 1; // a is not in the order list, put it after b
      }
      if (indexB === -1) {
        return -1; // b is not in the order list, put it after a
      }
      return indexA - indexB; // Both are in the order list, sort by their indices
    });
    // TODO: QUITAR
    this.paymentsFiltered = [];
    // this.payment_data.pasarelas.push('PUSD');
    payments.forEach((paymenType) => {
      if (this.payment_data.pasarelas.includes(paymenType.id)) {
        if (
          paymenType.id === 'FISV' ||
          paymenType.id === 'HAPP' ||
          paymenType.id === 'COKT' ||
          paymenType.id === 'STRE' ||
          paymenType.id === 'YOLO' ||
          paymenType.id === 'BNTE' ||
          paymenType.id === 'MFEL'
        ) {
          this.tarjetaCount++;
          this.pagoTarjeta.push(paymenType);
        }

        if (
          paymenType.id === 'PAGO' ||
          paymenType.id === 'PUSD' ||
          paymenType.id === 'OXXO'
        ) {
          this.pagoTarjeta.push(paymenType);
          this.efectivoCount++;
        }

        if (paymenType.id === 'CODI' || paymenType.id === 'PAYP') {
          this.otrosCount++;
        }

        this.activaOpcion(
          this.tarjetaCount,
          this.efectivoCount,
          this.otrosCount
        );
        this.paymentsFiltered.push(paymenType);
      }
    });
    if (this.paymentsFiltered.length > 0) {
      this.paymentsFiltered.forEach((check, index) => {
        if (
          check.id === 'FISV' ||
          check.id === 'HAPP' ||
          check.id === 'COKT' ||
          check.id === 'STRE' ||
          check.id === 'YOLO'
        ) {
          this.validation = true;
          this.numerosent = index;
        }
      });

      this.cargarPasarelas(1);
      // if (this.validation == true) {
      //   this.changePaymentOption(this.paymentsFiltered[0]);
      // } else {
      //   this.changePaymentOption(this.paymentsFiltered[0]);
      // }
      //this.changePaymentOption(this.paymentsFiltered[0]);
    }
  }
  setPaymentStatus() {
    if (this.payment_data && this.payment_data.status == 'ERROR') {
      this.paymentStatus = {
        type: 'Pagado',
        message: 'Esta liga de pago ya ha sido procesada anteriormente',
        errorMessage: this.payment_data.texto,
      };
    } else if (this.conektaResponse && this.conektaResponse.status == 'ERROR') {
      this.paymentStatus = {
        type: 'Error',
        message:
          '¡Su pago ha sido declinado!,el cargo no fué realizado a su tarjeta',
        errorMessage: this.conektaResponse.msg,
      };
    } else if (
      this.conektaResponse &&
      this.conektaResponse.status == 'ERROR_CN'
    ) {
      this.paymentStatus = {
        type: 'Error',
        message: '¡Error al procesar el cargo!',
        errorMessage:
          'Ocurrio un error al momento del procesar el cargo, favor de intentarlo nuevamente.',
      };
    } else if (
      this.conektaResponse &&
      this.conektaResponse.status != 'Aprobado'
    ) {
      this.paymentStatus = {
        type: 'Error',
        message:
          '¡Su pago ha sido declinado! el cargo no fué realizado a su tarjeta',
        errorMessage: this.conektaResponse.respuesta,
      };
    } else {
      this.paymentStatus = {
        type: 'Success',
        message:
          '¡Su pago se ha procesado correctamente!, en breve recibirá una notificación al email ingresado',
        errorMessage: null,
      };
    }
  }
  setPaymentStatusHappi() {
    if (this.happiResponse != 'Aprobado') {
      this.paymentStatus = {
        type: 'Error',
        message:
          '¡Su pago ha sido declinado! el cargo no fué realizado a su tarjeta',
        errorMessage:
          'Ocurrio un error al momento del procesar el cargo, favor de intentarlo nuevamente.',
      };
    }
  }

  setPaymentStatusFiserv() {
    if (this.conektaResponse.status != 'Aprobado') {
      this.paymentStatus = {
        type: 'Error',
        message:
          '¡Su pago ha sido declinado! el cargo no fué realizado a su tarjeta',
        errorMessage: this.conektaResponse.msg,
      };
    } else {
      this.paymentStatus = {
        type: 'Success',
        message:
          '¡Su pago se ha procesado correctamente!, en breve recibirá una notificación al email ingresado',
        errorMessage: null,
      };
    }
  }
  get paymentSelected() {
    return this.paymentsType.find((payment) => payment.selected == true);
  }

  openAviso() {
    PrivacyModalComponent;

    let options = {
      size: 'lg',
    };
    const modalRef = this.modalService.open(PrivacyModalComponent, options);
    modalRef.result.then((result) => {}).catch((error) => {});
  }

  setPagoStrype() {
    this.paymentStatus = {
      type: 'Success',
      message:
        '¡Su pago se ha procesado correctamente!, en breve recibirá una notificación al email ingresado',
      errorMessage: null,
    };
  }

  cargarPasarelas(item: number) {
    this.generalSelect = item;
    this.pagoTarjeta = [];
    this.paymentsFiltered.forEach((paymenType) => {
      switch (item) {
        case 1:
          this.urlLogo1 = 'assets/img/general/credit-card-color.svg';
          this.urlLogo2 = 'assets/img/general/store-solid.svg';
          this.urlLogo3 = 'assets/img/general/money-bill-solid.svg';
          if (
            paymenType.id === 'FISV' ||
            paymenType.id === 'HAPP' ||
            paymenType.id === 'COKT' ||
            paymenType.id === 'STRE' ||
            paymenType.id === 'YOLO' ||
            paymenType.id === 'BNTE' ||
            paymenType.id === 'MFEL'
          ) {
            this.pagoTarjeta.push(paymenType);
          }
          break;
        case 2:
          this.urlLogo1 = 'assets/img/general/credit-card-solid.svg';
          this.urlLogo2 = 'assets/img/general/store-color.svg';
          this.urlLogo3 = 'assets/img/general/money-bill-solid.svg';
          if (
            paymenType.id === 'PAGO' ||
            paymenType.id === 'OXXO' ||
            paymenType.id === 'PUSD'
          ) {
            this.pagoTarjeta.push(paymenType);
          }
          break;
        case 3:
          this.urlLogo1 = 'assets/img/general/credit-card-solid.svg';
          this.urlLogo2 = 'assets/img/general/store-solid.svg';
          this.urlLogo3 = 'assets/img/general/money-bill-color.svg';
          if (paymenType.id === 'CODI' || paymenType.id === 'PAYP') {
            this.pagoTarjeta.push(paymenType);
          }
          break;
        default:
          break;
      }

      if (this.pagoTarjeta.length > 0) {
        this.changePaymentOption(this.pagoTarjeta[0]);
      }
    });
  }

  activaOpcion(tarjetaCount, efectivoCount, otrosCount) {
    if (tarjetaCount > 0) {
      this.generalSelect = 1;
      this.urlLogo1 = 'assets/img/general/credit-card-color.svg';
      this.urlLogo2 = 'assets/img/general/store-solid.svg';
      this.urlLogo3 = 'assets/img/general/money-bill-solid.svg';
    } else if (efectivoCount > 0) {
      this.generalSelect = 2;
      this.urlLogo1 = 'assets/img/general/credit-card-solid.svg';
      this.urlLogo2 = 'assets/img/general/store-color.svg';
      this.urlLogo3 = 'assets/img/general/money-bill-solid.svg';
    } else if (otrosCount > 0) {
      this.generalSelect = 3;
      this.urlLogo1 = 'assets/img/general/credit-card-solid.svg';
      this.urlLogo2 = 'assets/img/general/store-solid.svg';
      this.urlLogo3 = 'assets/img/general/money-bill-color.svg';
    }
  }
}
