import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { documentosService } from '../../services/documentos.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LottieService } from '../../services/lottie.service';
import { environment } from '../../../environments/environment';
import { InputUtilsService } from 'src/app/services/input-utils.service';

declare let Conekta: any;

@Component({
  selector: 'app-codi',
  templateUrl: './codi.component.html',
  styleUrls: ['../payment-components.css'],
})
export class CodiComponent implements OnInit {
  validateMonto: boolean;
  @Input() paymentData = null;
  @Output() codiSend = new EventEmitter();

  paymentForm: FormGroup;
  // private serviceURL = '//mpuapi.payoff.mx/cargo';
  private serviceURL = environment.url + 'cargo';
  private imgURL = 'https://mpuapi.payoff.mx/oxxo/';
  amount = null;
  payment_id = null;
  paymentTypeAmounts = 0;
  clave = '';
  apiKey = '';
  oxxoResponse = null;
  referenciaV;
  referenciaPRE;
  codigo;
  fecha;
  mes;
  anio;
  imagenCreada;
  botondescarga = false;
  cardData = {
    card_holder_name: null,
    email_holder: null,
    phone: null,
  };
  track: string;
  status: boolean;
  msg: string;
  privateKey: string;

  showMsgError: boolean = false;
  currency: any;
  constructor(
    public documentosService: documentosService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    private spinnerService: NgxSpinnerService,
    private lottieService: LottieService,
    private modalService: NgbModal,
    private inputUtilsService: InputUtilsService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.payment_id = params['id'];
    });
  }

  ngOnInit(): void {
    this.privateKey = this.paymentData.datos['CODI']['tprivada'];

    let track = this.paymentData.clave;
    let splice = track.split('-');
    this.track = splice[1];
    // this.validateMonto = true;
    //this.spinnerService.show();
    this.paymentForm = this.fb.group({
      card_holder_name: new FormControl(this.cardData.card_holder_name, [
        Validators.required,
      ]),
      email_holder: new FormControl(this.cardData.email_holder, [
        Validators.required,
        Validators.email,
      ]),
      phone: new FormControl(this.cardData.phone, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(11),
        Validators.pattern('[- +()0-9]+'),
      ]),
    });
    this.mes = new Date().getMonth();
    this.anio = new Date().getFullYear();
    this.fecha = this.mes + '/' + this.anio;
    this.showPaymentsTimes();
  }
  onInputChange(event: any) {
    const numericValue = this.inputUtilsService.onInputChange(event);

    this.phone.setValue(numericValue);
  }
  showPaymentsTimes() {
    // TODO: cambiar la pasrela
    this.paymentTypeAmounts = this.paymentData.monto['CODI']['Cargo'];
    this.referenciaPRE = this.paymentData.monto['CODI']['status'];
    this.currency = this.paymentData.datos['CODI']['currency'];


    if (this.referenciaPRE) {
      this.msg =
        'Esta liga ya cuenta con una referencia generada para este cargo.';
    }

    // this.referenciaPRE = false;
    this.validateMonto = this.paymentTypeAmounts <= 21000 ? true : false;
  }

  addValidatorPhone(group) {
    group.controls.phone.setValidators([Validators.required]);
    group.controls.email_holder.setValidators([Validators.required]);
  }

  sendOxxoData() {
    let params = {
      monto: this.paymentTypeAmounts,
      idPasarela: 'CODI',
      idEmpresa: this.paymentData.idempresa,
      tipoPago: 1,
      referencia: this.track,
      descripcion: this.paymentData.refencia,
      clave: this.paymentData.clave,
      email: this.email_holder.value,
      numeroCelularCliente: this.phone.value,
      track: this.track,
      ccNombre: this.card_holder_name.value,
      nombreBeneficiario2: 'SISTEMA DE TRANSFERENCIAS Y PAGOS STP SA',
      cuentaBeneficiario2: '646180287100000007',
      numeroReferenciaComercio: 1234567,
      llavep: this.privateKey,
    };

    this.lottieService.setLoader(true, '');
    /*  return false; */
    this.http
      .post(this.serviceURL, {
        params: params,
      })
      .subscribe((data) => {
        if (data['status'] == 'APROBADO') {
          this.referenciaPRE = true;
          this.showMsgError = false;
          this.msg =
            'Se generó una referencia para este cargo correctamente. En breve recibirá una notificación al número ingresado para continuar con el proceso de pago';
        } else {
          this.showMsgError = true;
          if (data['msg']) {
            this.msg = data['msg'];
          } else {
            this.msg =
              'Ocurrio un error al momento del procesar el cargo, favor de intentarlo nuevamente.';
          }
        }

        this.lottieService.setLoader(false, '');
        // this.oxxoResponse = data;
        // this.open(this.oxxoResponse);
      });
  }

  // open(referenciao: any,) {
  //   //this.spinnerService.hide();
  //   this.lottieService.setLoader(false, '');
  //   if(referenciao.status == "COMPLETO" ){
  //     let datosO= referenciao.respuesta;
  //     this.referenciaV=datosO.referencia;
  //     this.codigo=datosO.barsCode;
  //     this.botondescarga=true;
  // 	}
  // }

  // elimina caracteres raros y numeros
  sanitize(event: KeyboardEvent) {
    let value = null;
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^A-Za-z\s]+/g, '');
  }

  get card_holder_name() {
    return this.paymentForm.get('card_holder_name');
  }
  get email_holder() {
    return this.paymentForm.get('email_holder');
  }
  get phone() {
    return this.paymentForm.get('phone');
  }
}
