
<div class="modal-header" style="display: flow-root !important;"> 
    <button type="button" class="close" aria-label="Close" (click)="closeModal('close')" style="-webkit-appearance: none; padding: 0; cursor: pointer; background: 0 0; border: 0; float: right; font-size: 27px; font-weight: 700; line-height: 0.5; color: #000; text-shadow: 0 1px 0 #fff; filter: alpha(opacity=20); opacity: 0.5;">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div *ngIf="!versionValidate">
  <iframe class="custom-frame" #frame width="400" height="400" id="frame" name="frame" frameborder="0" [src]="demoEndpoint | safeResourceUrl" (load)="onLoad($event)"></iframe>


  <form target="frame" action="{{toUrl}}" #form method="POST" (ngSubmit)="submitForm($event)">
    <!-- <input type="hidden" name="show_form" value={{show_form}} />  -->

    <input  type="hidden" name="PaReq" value={{PaReq}} >
    <input  type="hidden" name="TermUrl" value={{TermUrl}} >
    <input  type="hidden" name="MD" value={{md}} >


  </form>
</div>

<div *ngIf="versionValidate">

  <iframe class="custom-frame" #frame width="400" height="400" id="frame" name="frame" frameborder="0" [src]="demoEndpoint | safeResourceUrl" (load)="onLoad($event)"></iframe>


  <form target="frame" action="{{toUrl}}" #formDos method="POST" (ngSubmit)="submitForm($event)">
    <!-- <input type="hidden" name="show_form" value={{show_form}} />  -->

    <input  type="hidden" name="creq" value={{creq}} >
    <input  type="hidden" name="threeDSSessionData" value={{threeDSSessionData}} >



  </form>
</div>
<!-- <div class="one" [innerHtml]="htmlToAdd" *ngIf="!versionValidate" ></div>
<div>
  <iframe id="finalIframe" #iframes (load)="onLoads($event)" name="tdsMmethodTgtFrame"></iframe>
  <form id="finalForm" action="" method="post" target="iframes">
    <input  type="hidden" name="3DSMethodData" id="3DSMethodData">
    <input  type="hidden" name="threeDSMethodData" id="threeDSMethodData" >
  </form>
</div> -->
<!-- <div id="prueba" #prueba>
</div> -->