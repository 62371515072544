import { Component, OnInit, ViewChild, ElementRef, Input, Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-status-yolo',
  templateUrl: './status-yolo.component.html',
  styleUrls: ['./status-yolo.component.css']
})
export class StatusYoloComponent implements OnInit {
  @Input() public data;
  test;

  constructor(
    private route: ActivatedRoute,
  ) {
    /* this.route.queryParams.subscribe(params => {
      this.test = params;
    }); */
    this.test=this.route.snapshot.params;
    /* this.route.body.subscribe(body => {
      const parametro1 = body['parametro1'];
      const parametro2 = body['parametro2'];
      // Asigna los valores a tu variable
      this.miVariable = { parametro1, parametro2 };
    }); */
  }

  ngOnInit(): void {
    this.test=this.route.snapshot.params;
  }

}
