import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { PaypalService } from 'src/app/services/paypal.service';

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['../payment-components.css'],
})
export class PaypalComponent implements OnInit {
  @Input() paymentData = null;
  @Output() stripeEmiter = new EventEmitter();
  public payPalConfig?: IPayPalConfig;
  showSuccess: boolean | undefined;
  amount: any;
  clientId: string;
  currency: any;
  constructor(private _srvPaypal: PaypalService) {}

  ngOnInit(): void {
    this.amount = this.paymentData.monto['PAYP']['Tarjeta']['1_cargo'];
    // this.clientId = 'AZINBqulYyjC4q-SGcbCk3HsAxdEy1zSDaOsDSbNzDP3aQFGLKdEVlp_E3wfVfOuZVo45nRIJyWguBz9';
    this.clientId = this.paymentData.datos['PAYP']['tprivada'];
    this.currency = this.paymentData.datos['PAYP']['currency'];

    this.initConfig();
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: 'MXN',
      clientId: this.clientId,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'MXN',
                value: this.amount,
                breakdown: {
                  item_total: {
                    currency_code: 'MXN',
                    value: this.amount,
                  },
                },
              },
              items: [
                {
                  name: this.paymentData.refencia,
                  quantity: '1',
                  unit_amount: {
                    currency_code: 'MXN',
                    value: this.amount,
                  },
                },
              ],
            },
          ],
        },

      style: {
        shape: 'pill',
        color: 'silver',
        layout: 'horizontal',
        label: 'pay',
      },
      onApprove: (data, actions) => {
        actions.order.get().then((details: any) => {
          let datos = {
            idPasarela: 'PAYP',
            idEmpresa: this.paymentData.idempresa,
            monto: this.amount,
            tipoPago: 1,
            referencia: this.paymentData.clave,
            descripcion: this.paymentData.descripcion,
            clave: this.paymentData.clave,
            track: this.paymentData.clave,
            paymentMethodId: details.id,
          };

          this._srvPaypal.sendPayment(datos).subscribe((res) => {});
        });
      },
      onClientAuthorization: (data) => {
        let status = data.status === 'COMPLETED' ? 'Aprobado' : 'Denegado';

        let datos = {
          track: this.paymentData.clave,
          status: status,
          email: data.payer.email_address,
          nombre: data.payer.name.given_name + ' ' + data.payer.name.surname,
          monto: this.amount,
          respuesta: 'Pago aprobado',
          folio: data.id,
          autorizacion: data.payer.payer_id,
          fecha: data.create_time,
        };
        this._srvPaypal.confirmPago(datos).subscribe((res) => {
          const now = new Date();
          let array;
          if (status == 'Denegado') {
            array = {
              status: 'ERROR',
              fecha: now.toLocaleDateString(),
              autorizacion: '',
              ccNumero: '',
              monto: '',
              track: this.paymentData.clave,
              orderId: '',
              msg: 'Error desconocido intente de nuevo',
            };
          } else {
            array = {
              status: 'Aprobado',
              respuesta: {
                status: 'Aprobado',
                fecha: now.toLocaleDateString(),
                autorizacion: data.payer.payer_id,
                ccNumero: '',
                monto: this.amount,
                track: this.paymentData.clave,
                orderId: data.id,
              },
            };
          }

          this.stripeEmiter.emit(array);
        });
        this.showSuccess = true;
      },
      onCancel: (data, actions) => {},
      onError: (err) => {
        const now = new Date();
        let array;
        array = {
          status: 'ERROR',
          fecha: now.toLocaleDateString(),
          autorizacion: '',
          ccNumero: '',
          monto: this.amount,
          track: this.paymentData.clave,
          orderId: '',
          msg: 'Error desconocido intente de nuevo',
        };
        this.stripeEmiter.emit(array);
      },
      onClick: (data, actions) => {},
    };
  }
}
