
<div class="row h-100" style="background-color: #ffffff00;">
    <div class="col-12 d-flex justify-content-center align-items-center" style=" height: 40%;">
        <div class="col-6 d-flex justify-content-center align-items-center" style="height: 65%;;position:relative">
            <img style="width: 100%;height: 100%;" *ngIf="type == 'Success';else errorIcon" src="assets/img/card-success.svg">
            <ng-template #errorIcon>
                <img style="width: 100%;height: 100%;" src="assets/img/card-decline.svg">
            </ng-template>                
        </div>
    </div>
    
    <div class="col-12" style="height: 60%;" *ngIf="type == 'Success';else errorConekta">
        <div class="col-12 text-center" style=" margin-bottom: 10px;background-color: white;padding: 10px;">
            <h3 style="color:#7ac142;font-family: 'opensanssemibold'">¡Pago exitoso!</h3>
            <p>{{message}}</p>
        </div>
        <div class="col-12 d-flex justify-content-center" style="margin-bottom: 7px;background-color: white;">
            <div class="col-10 d-flex" style="padding-top: 10px;padding-bottom: 10px">
                <span>Fecha</span>
                <span style="margin-left:auto">{{conektaResponse.fecha}}</span>
            </div>             
        </div>
        <div class="col-12 d-flex justify-content-center" style="margin-bottom: 7px;background-color: white;">
            <div class="col-10 d-flex" style="padding-top: 10px;padding-bottom: 10px">
                <span>No. Autorización</span>
                <span style="margin-left:auto">{{conektaResponse.autorizacion}}</span>
            </div>           
        </div>
        <div class="col-12 d-flex justify-content-center" style="margin-bottom: 7px;background-color: white;">
            <div class="col-10 d-flex" style="padding-top: 10px;padding-bottom: 10px">
                <span>Folio</span>
                <span style="margin-left:auto">{{conektaResponse.orderId}}</span>
            </div>           
        </div>
        <div class="col-12 d-flex justify-content-center" style="margin-bottom: 7px;background-color: white;">
            <div class="col-10 d-flex" style="padding-top: 10px;padding-bottom: 10px">
                <span>Ultimos 4 digitos de la tarjeta</span>
                <span style="margin-left:auto">{{conektaResponse.ccNumero}}</span>
            </div>              
        </div>
        <div class="col-12 d-flex justify-content-center" style="margin-bottom: 7px;background-color: white;">
            <div class="col-10 d-flex" style="padding-top: 10px;padding-bottom: 10px">
                <span>Monto total a cobrar</span>
                <span style="margin-left:auto">{{conektaResponse.monto  | currency:'USD':'symbol' }} MXN</span>
            </div>              
        </div>
    </div>
    <ng-template #errorConekta>
        <div class="col-12 d-flex align-items-center justify-content-center" style="height: 60%">
            <div class="col-10">
                <div class="col-12 text-center" style=" margin-bottom: 10px;background-color: white;padding: 20px;border-radius:5px">
                    <h3 *ngIf="type !='Pagado'" style="color:red;font-family: 'opensanssemibold'">¡Pago declinado!</h3>
                    <h3 *ngIf="type =='Pagado'" style="color:red;font-family: 'opensanssemibold'">¡Pago Aprobado!</h3>
                    <p *ngIf="message">{{message}}</p>
                </div>
                <div class="col-12 text-center" style="padding-top: 10px;padding-bottom: 10px;background-color:#f7d7da;border-radius:5px">
                    <p style="font-size:12px;font-family: 'opensanssemibold';color:red;padding: 10px">{{errorMessage}}</p>
                </div>
                <div *ngIf="type !='Pagado'" class="col-12 text-center" style="padding-top: 10px;padding-bottom: 10px;background-color:#f7f7f7;border-radius:5px">
                    Si desea reintentar el cargo<span (click)="refresh()" class="btn" href="#" style="text-decoration: underline; color: blue; padding-top: 2px; padding-left: 5px;">haga clic aquí</span>
                </div>       
            </div>
                
        </div>
    </ng-template>         
</div>