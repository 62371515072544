import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/map';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ThrowStmt } from '@angular/compiler';
import { LottieService } from 'src/app/services/lottie.service';

@Component({
  selector: 'app-modal-fiserv',
  templateUrl: './modal-fiserv.component.html',
  styleUrls: ['./modal-fiserv.component.css']
})
export class ModalFiservComponent implements OnInit {

  @ViewChild('form') postForm: ElementRef;
  @ViewChild('formDos') postFormDos: ElementRef;
  @ViewChild('iframe') iframe: ElementRef;

  @Input() public data;


  PaReq: any;
  TermUrl: any;
  md: any;
  toUrl: any;

  show_form: any;
  demoEndpoint: any;
  reqBody: any;
  formBody: any;
  count = 0;
  count3ds = 0;
  template;
  htmlToAdd;
  verison3ds;
  versionValidate: boolean;
  DSMethodData;
  threeDSMethodData;
  idControl: number;
  creq: string;
  threeDSSessionData: string;

  // /fiser/manual
  private fiserManual = environment.url + 'fiserv/manual';
  constructor(private http: HttpClient, public activeModal: NgbActiveModal, private sanitizer: DomSanitizer, private lottieService: LottieService) {
    
   

  }
  
  ngOnInit() {
    
    this.verison3ds = this.data['version'];
    this.versionValidate = (this.verison3ds ==2.1) ? true : false;
    this.idControl = this.data['idControl'];
    
    if(this.versionValidate){

      this.TermUrl = this.data['TermUrl'];
      this.toUrl = this.data['acsURL'];
      this.creq = this.data['cReq'];
      this.threeDSMethodData = (this.data['threeDSMethodData']) ? this.data['threeDSMethodData'] : '';
      // let data = this.sanitizer.bypassSecurityTrustHtml(this.data['data']);
      // this.htmlToAdd = data;
      // setTimeout(function(){
      //   let form: HTMLFormElement = <HTMLFormElement>document.getElementById('tdsMmethodForm');   
      //   let iframe: HTMLFormElement = <HTMLFormElement>document.getElementById('tdsMmethodTgtFrame');   

      //   let finalIframe: HTMLFormElement = <HTMLFormElement>document.getElementById('finalIframe');   
      //   let finalForm: HTMLFormElement = <HTMLFormElement>document.getElementById('finalForm'); 
      //   // form.submit();
      //   // console.log(form.children['3DSMethodData'].value);
      //   finalIframe.setAttribute("id", iframe.attributes['id'].value);
      //   finalIframe.setAttribute("name", iframe.attributes['name'].value);
      //   // finalIframe.setAttribute("style", iframe.attributes['style'].value);
      //   finalIframe.setAttribute("src", iframe.attributes['src'].value);
      //   finalIframe.setAttribute("xmlns", iframe.attributes['xmlns'].value);

      //   iframe.remove();

      //   finalForm.setAttribute("action", form.attributes['action'].value);
      //   finalForm.setAttribute("id", form.attributes['id'].value);
      //   finalForm.setAttribute("method", form.attributes['method'].value);
      //   finalForm.setAttribute("name", form.attributes['name'].value);
      //   finalForm.setAttribute("target", form.attributes['target'].value);
      //   finalForm.setAttribute("xmlns", form.attributes['xmlns'].value);

      //   this.DSMethodData = form.children['3DSMethodData'].value;
      //   this.threeDSMethodData = form.children['threeDSMethodData'].value;

       

      
        
      //   let DSMethodData: HTMLFormElement = <HTMLFormElement>document.getElementById('3DSMethodData');   
      //   let threeDSMethodData: HTMLFormElement = <HTMLFormElement>document.getElementById('threeDSMethodData'); 

      //   DSMethodData.setAttribute("value", this.DSMethodData);
      //   threeDSMethodData.setAttribute("value", this.threeDSMethodData);
      //   form.remove();


      //   finalForm.submit();

        
      // }, 3000);
      

    }else{
          
        this.demoEndpoint = this.data['toUrl']; // this would be the url that you load with the iframe, that is also the value in the action field on the form to be issued in the post request 
      
        this.show_form = 'PAYMENT_FORM';


        this.toUrl = this.data['toUrl'];
        this.PaReq = this.data['PaReq'];
        this.TermUrl = this.data['TermUrl'];
        this.md = this.data['md'];
        
        this.reqBody = new FormData();
        this.reqBody.append('PaReq', this.PaReq);
        this.reqBody.append('TermUrl', this.TermUrl);
        this.reqBody.append('MD', this.md);


        this.reqBody.append('show_form', this.show_form);
    }
    // this.template = 'hOLA MUNDO';


    // this.demoEndpoint = 'https://example.com/demoEndpoint'; // this would be the url that you load with the iframe, that is also the value in the action field on the form to be issued in the post request 
  
    // this.show_form = 'PAYMENT_FORM';


    // this.toUrl = this.data['toUrl'];
    // this.PaReq = this.data['PaReq'];
    // this.TermUrl = this.data['TermUrl'];
    // this.md = this.data['md'];
    
    // this.reqBody = new FormData();
    // this.reqBody.append('PaReq', this.PaReq);
    // this.reqBody.append('TermUrl', this.TermUrl);
    // this.reqBody.append('MD', this.md);


    // this.reqBody.append('show_form', this.show_form);
  }
  
  ngAfterViewInit() {
    if( this.versionValidate){
      this.postFormDos.nativeElement.submit();
    }else{
      this.postForm.nativeElement.submit();
    }
  }
  
  submitForm($event): boolean {
    $event.stopPropagation();
    this.http.post(this.demoEndpoint, this.reqBody);
    return true;
  }
  
  onLoad(event) {
    this.count++;

    if(this.count == 3){
      this.count = 0;
      let data = {
        'status' : 'Aprobado'
      }
      this.closeModal(data);
    }
  }

  onLoads(event){
    
    this.lottieService.setLoader(false, '');
    this.count3ds++;
    if(this.count3ds == 2){
      this.count3ds = 0;
      var data = {
        idControl: this.idControl
      };

      this.http.post(this.fiserManual,{
        'params' : data
      }).subscribe( data => {  

        const now = new Date();
        
        
        if(data['transactionStatus'] == 'APPROVED')   {
          
          // arreglo de respuesta
          let array = {
            'status' : 'Aprobado',
            'fecha' : now.toLocaleDateString() ,
            'autorizacion' : data['processor']['authorizationCode'],
            'ccNumero': data['paymentMethodDetails']['paymentCard']['last4'],
            'monto' : data['approvedAmount']['total'],
            // 'track': this.paymentData.clave
          };
          this.closeModal(array);
          this.lottieService.setLoader(false, '');
        }
      });
      // tdsMmethodTgtFrame
    }
  }

  final(){
    
    
  }

  closeModal(value) {
    
    this.activeModal.close(value);
  }
}
