<div class="payment-form-container">
    <div class="form-container w-100 green-border">
        <p class="title selected"><span class="number">1</span>Ingresar los datos de la tarjeta de crédito o débito</p>
        <br>
        <p class="payment-amount">
            MONTO A PAGAR
        </p>
        <p class="oxxo-amount">{{amount | currency:'USD':'symbol'}} {{ currency }}</p>
        <ngx-paypal [config]="payPalConfig"></ngx-paypal>


    </div>
    <div class="form-container green-border" style="margin-top: 20px;">
        <p class="title selected">
            <span class="number">2</span>
            Tarjetas participantes
        </p>

        <div class="table-payment">

            <div class="table-body">

                <div class="d-flex col-12"
                    style="border-bottom: 2px solid #DDDDDD;margin-bottom: 5px;align-items: center;">
                    <div class="d-flex justify-content-center banks-imgs f-wrap">
                        <div class="w-100 d-flex justify-content-center">
                            <img style="width: 150;height:40px" src="assets/img/visa.png">
                            <img style="width: 150;height: 35px;margin: 4px;" src="assets/img/mastercard.png">
                            <img style="width: 150;height: 55px;margin: 4px;" src="assets/img/americanexpress.png">
                            <img style="width: 150;height: 25px; margin-top: 10px;"
                                src="assets/img/americanexpress2.svg">
                        </div>
                        <div class="logosBancospequenios text-center">
                            <img style="width: 70px;height:25px" src="assets/img/HSBC.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Santander.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Scotiabank.svg">
                            <img style="width: 70px;height:25px" src="assets/img/banregio.svg">
                            <img style="width: 70px;height:25px" src="assets/img/liverpool.svg">
                            <img style="width: 30px;height:25px" src="assets/img/Itaucard.svg">
                            <img style="width: 70px;height:25px" src="assets/img/banco-del-bajio.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Banorte.svg">
                            <img style="width: 70px;height:25px" src="assets/img/afirme.svg">
                            <img style="width: 70px;height:25px" src="assets/img/banca-mifel.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Inbursa.svg">
                            <img style="width: 70px;height:25px" src="assets/img/famsa.svg">
                            <img style="width: 50px;height:25px" src="assets/img/banjercito.svg">
                            <img style="width: 50px;height:25px" src="assets/img/invex.svg">
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

</div>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row modal_header">
                    <img style="height: 50px;" src="assets/img/happi-color.svg" alt="">
                </div>
                <div class="row mt-3 d-flex justify-content-centeral align-items-center">
                    <div class="col-12 text-center"> <strong>Tarjetas participantes</strong> <img class="img-fluid mx-3"
                            src="assets/img/billete.svg"> </div>

                </div>
                <div class="row my-3">
                    <div>
                        <p>Un cargo</p>
                        <div class="d-flex justify-content-around">
                            <img class="" style="width: 150;height:25px" src="assets/img/BBVA.svg">
                            <img style="width: 150;height:25px" src="assets/img/citibanamex.svg">
                        </div>

                    </div>
                </div>
                <div class="row my-3">
                    <div>
                        <p>Un cargo, 3, 6, 9 y 12 meses sin intereses</p>
                        <div class="logosBancos text-center">
                            <img style="width: 70px;height:25px" src="assets/img/HSBC.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Santander.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Scotiabank.svg">
                            <img style="width: 70px;height:25px" src="assets/img/banregio.svg">
                            <img style="width: 70px;height:25px" src="assets/img/liverpool.svg">
                            <img style="width: 30px;height:25px" src="assets/img/Itaucard.svg">
                            <img style="width: 70px;height:25px" src="assets/img/banco-del-bajio.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Banorte.svg">
                            <img style="width: 70px;height:25px" src="assets/img/afirme.svg">
                            <img style="width: 70px;height:25px" src="assets/img/banca-mifel.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Inbursa.svg">
                            <img style="width: 50px;height:25px" src="assets/img/famsa.svg">
                            <img style="width: 50px;height:25px" src="assets/img/banjercito.svg">
                            <img style="width: 50px;height:25px" src="assets/img/invex.svg">
                        </div>

                    </div>
                </div>
                <div class="row my-3">
                    <div>
                        <p>Un cargo, 3, 6, 9, 12 y 18 meses sin intereses</p>
                        <div class="logosBancos text-center">
                            <img style="width: 70px;height:35px" src="assets/img/americanexpress2.svg">

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>