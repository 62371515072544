import { Component, OnInit, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { CobroComponent } from '../happi/cobro/cobro.component';
import { HttpClient } from '@angular/common/http';
import html2canvas from 'html2canvas';
import { documentosService } from '../../services/documentos.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LottieService } from '../../services/lottie.service';
import { environment } from '../../../environments/environment';
import { InputUtilsService } from 'src/app/services/input-utils.service';

declare let Conekta: any;

@Component({
  selector: 'app-oxxo',
  templateUrl: './oxxo.component.html',
  styleUrls: ['../payment-components.css'],
})
export class OxxoComponent implements OnInit {
  validateMonto: boolean;
  @Input() paymentData = null;
  paymentForm: FormGroup;
  // private serviceURL = '//mpuapi.payoff.mx/cargo';
  private serviceURL = environment.url + 'cargo';
  private imgURL = 'https://mpuapi.payoff.mx/oxxo/';
  amount = null;
  payment_id = null;
  paymentTypeAmounts = 0;
  clave = '';
  apiKey = '';
  oxxoResponse = null;
  referenciaV;
  referenciaPRE;
  codigo;
  fecha;
  mes;
  anio;
  imagenCreada;
  botondescarga = false;
  cardData = {
    card_holder_name: null,
    email_holder: null,
    phone: null,
  };
  currency: any;

  constructor(
    public documentosService: documentosService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    private spinnerService: NgxSpinnerService,
    private lottieService: LottieService,
    private modalService: NgbModal,
    private inputUtilsService: InputUtilsService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.payment_id = params['id'];
    });
  }

  ngOnInit(): void {
    // this.validateMonto = true;
    //this.spinnerService.show();
    this.paymentForm = this.fb.group({
      card_holder_name: new FormControl(this.cardData.card_holder_name, [
        Validators.required,
      ]),
      email_holder: new FormControl(this.cardData.email_holder, [
        Validators.required,
        Validators.email,
      ]),
      phone: new FormControl(this.cardData.phone, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(11),
        Validators.pattern('[- +()0-9]+'),
      ]),
    });
    this.mes = new Date().getMonth();
    this.anio = new Date().getFullYear();
    this.fecha = this.mes + '/' + this.anio;
    this.showPaymentsTimes();
    //this.spinnerService.hide();
  }

  onInputChange(event: any) {
    const numericValue = this.inputUtilsService.onInputChange(event);

    this.phone.setValue(numericValue);
  }
  showPaymentsTimes() {
    this.paymentTypeAmounts = this.paymentData.monto['OXXO']['Cargo'];
    this.currency = this.paymentData.datos['OXXO']['currency'];

    //  this.paymentTypeAmounts = 9000;
    this.validateMonto = this.paymentTypeAmounts <= 10000 ? true : false;
    this.clave = this.paymentData.clave;
    this.apiKey = this.paymentData.datos['OXXO'].tprivada;
    if (this.paymentData.monto['OXXO']['status'] == 1) {
      this.referenciaV = this.paymentData.monto['OXXO']['folio'];
      this.codigo = this.paymentData.monto['OXXO']['img'];
      this.referenciaPRE = 1;
      this.botondescarga = true;
    }
  }

  addValidatorPhone(group) {
    group.controls.phone.setValidators([Validators.required]);
    group.controls.email_holder.setValidators([Validators.required]);
  }

  sendOxxoData() {
    //this.spinnerService.show();
    this.lottieService.setLoader(true, '');
    /*  return false; */
    this.http
      .post(this.serviceURL, {
        params: {
          monto: this.paymentTypeAmounts,
          idPasarela: 'OXXO',
          idEmpresa: this.paymentData.idempresa,
          apiKey: this.apiKey,
          fechaExp: this.fecha.toString(),
          tipoPago: 1,
          referencia: 'tester',
          descripcion: this.paymentData.descripcion,
          clave: this.clave,
          email: this.email_holder.value,
          nombreTa: this.card_holder_name.value,
          telefono: this.phone.value,
        },
      })
      .subscribe((data) => {
        this.oxxoResponse = data;
        this.open(this.oxxoResponse);
      });
  }

  open(referenciao: any) {
    //this.spinnerService.hide();
    this.lottieService.setLoader(false, '');
    if (referenciao.status == 'COMPLETO') {
      let datosO = referenciao.respuesta;
      this.referenciaV = datosO.referencia;
      this.codigo = datosO.barsCode;
      this.botondescarga = true;
    }
  }
  sanitize(event: KeyboardEvent) {
    let value = null;
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^A-Za-z\s]+/g, '');
  }

  downloadimg() {
    let url = this.imgURL + this.paymentData.clave;
    window.open(url, '_blank');
    /* this.documentosService.getpdf2(this.imgURL).subscribe(data =>{
    }); */
  }

  downloadFile(data: any) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  get card_holder_name() {
    return this.paymentForm.get('card_holder_name');
  }
  get email_holder() {
    return this.paymentForm.get('email_holder');
  }
  get phone() {
    return this.paymentForm.get('phone');
  }
}
