import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expiration-error',
  templateUrl: './expiration-error.component.html',
  styleUrls: ['./expiration-error.component.css']
})
export class ExpirationErrorComponent implements OnInit {

  type = 'error';
  message = 'Liga caducada';
  errorMessage = 'La liga ha caducado';
  conektaResponse = null;
  title = 'Error liga caducada';
  paymentStatus = true;

  constructor() { }

  ngOnInit(): void {
  }

}
