<div class="container-fluid modal-cuerpo">
  <div class="row justify-content-center align-items-center modal-margin">
    <div *ngIf="payment_data" class="modal-container col-11 col-sm-9 col-lg-6 col-xl-4 no-padding">
      <div class="col-12 h-100" *ngIf="
          !paymentStatus && !conektaResponse && !validacionError;
          else templateName
        ">
        <div class="header col-12">
          <p>SELECCIONA TU FORMA DE PAGO</p>
        </div>

        <div class="body">
          <ul class="nav nav-tabs nav-fill mb-3 nav-justified" id="pills-tab" role="tablist">
            <li class="nav-item " role="presentation" [ngClass]="generalSelect == 1 ? 'nav-item-select' :'nav-item'"
              *ngIf="tarjetaCount > 0">
              <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                type="button" role="tab" aria-controls="pills-home" aria-selected="true" (click)="cargarPasarelas(1)"
                [ngClass]="generalSelect == 1 ? 'active' :''">
                <div class="icon-g">
                  <img class="img-fluid img-general" [src]="urlLogo1" />
                </div>
                Pago con Tarjeta
              </button>
            </li>
            <li class="nav-item" role="presentation" [ngClass]="generalSelect == 2 ? 'nav-item-select' :'nav-item'"
              *ngIf="efectivoCount > 0">
              <button class="nav-link button-general" id="pills-profile-tab" data-bs-toggle="pill"
                data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                aria-selected="false" (click)="cargarPasarelas(2)" [ngClass]="generalSelect == 2 ? 'active' :''">
                <img class="img-fluid img-general" [src]="urlLogo2" />
                Pago en Efectivo
              </button>
            </li>
            <li class="nav-item" role="presentation" [ngClass]="generalSelect == 3 ? 'nav-item-select' :'nav-item'"
              *ngIf="otrosCount > 0">
              <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
                type="button" role="tab" aria-controls="pills-contact" aria-selected="false"
                (click)="cargarPasarelas(3)" [ngClass]="generalSelect == 3 ? 'active' :''">
                <img class="img-fluid img-general" [src]="urlLogo3" />
                Otros
              </button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <!-- CONTENIDO -->
              <ul class="nav nav-tabs nav-fill mb-3" id="pills-tab" role="tablist" *ngIf="pagoTarjeta.length > 0">
                <li class="nav-item li-pasarelas" role="presentation" *ngFor="let item of pagoTarjeta"
                  (click)="changePaymentOption(item)" [ngClass]="idSelect == item.id ? 'nav-item-select' :'nav-item'">
                  <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                    type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                    <img [src]="item.img" alt="" style="max-height: 25px" class="img-fluid">
                  </button>
                  <p class="w-100 parrafo-pasarelas">
                    {{ item.name }}
                  </p>
                </li>

              </ul>
            </div>


          </div>

          <!-- TODO: FISER -->
          <app-happi (happisendf)="getResponseHappi($event)" *ngIf="payment_data && paymentSelected.id == 'HAPP'"
            [paymentData]="payment_data"></app-happi>

          <app-yolopago *ngIf="payment_data && paymentSelected.id == 'YOLO'" [paymentData]="payment_data">
          </app-yolopago>

          <app-conekta (paymentSend)="getResponse($event)" *ngIf="payment_data && paymentSelected.id == 'COKT'"
            [paymentData]="payment_data"></app-conekta>

          <app-fiserv (fiservSend)="getResponseFiserv($event)" *ngIf="paymentSelected.id == 'FISV'"
            [paymentData]="payment_data"></app-fiserv>

          <app-oxxo *ngIf="payment_data && paymentSelected.id == 'OXXO'" [paymentData]="payment_data">
          </app-oxxo>
          <app-pago *ngIf="payment_data && paymentSelected.id == 'PAGO'" [paymentData]="payment_data">
          </app-pago>
          <!-- TODO: VALIDAR -->
          <!-- TODO: CODI -->
          <app-codi (codiSend)="getResponseCodi($event)" *ngIf="paymentSelected.id == 'CODI'"
            [paymentData]="payment_data"></app-codi>
          <!-- TODO: STRIPE -->
          <app-stripe (stripeEmiter)="getResponse($event)" *ngIf="paymentSelected.id == 'STRE'"
            [paymentData]="payment_data"></app-stripe>
          <!-- TODO: PAYPAL -->
          <app-paypal (stripeEmiter)="getResponse($event)" *ngIf="paymentSelected.id == 'PAYP'"
            [paymentData]="payment_data"></app-paypal>
          <app-banorte *ngIf="payment_data && paymentSelected.id == 'BNTE'" [paymentData]="payment_data"></app-banorte>
          <div class="aviso-privacidad">
            <a (click)="openAviso()" style="
                text-decoration: underline;
                color: #0d6efd;
                cursor: pointer;
              ">AVISO DE PRIVACIDAD</a>
          </div>
        </div>
      </div>

      <ng-template class="col-12 h-100" #templateName>
        <app-payment-status [title]="paymentStatus.title" [type]="paymentStatus.type" [message]="paymentStatus.message"
          [errorMessage]="paymentStatus.errorMessage" [conektaResponse]="conektaResponse"></app-payment-status>
      </ng-template>
    </div>
  </div>
</div>