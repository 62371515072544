import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { LottieService } from '../../services/lottie.service';
import { EncrDecrService } from '../../services/encrypt.service';
import { environment } from '../../../environments/environment';
import { InputUtilsService } from 'src/app/services/input-utils.service';
declare let Conekta: any;

@Component({
  selector: 'app-conekta',
  templateUrl: './conekta.component.html',
  styleUrls: ['../payment-components.css'],
})
export class ConektaComponent implements OnInit {
  @Input() paymentData = null;
  @Output() paymentSend = new EventEmitter();
  paymentForm: FormGroup;
  // private serviceURL = '//mpuapi.payoff.mx/cargo';
  private serviceURL = environment.url + 'cargo';
  private serviceBIN = environment.url + 'bin';
  amount = null;
  payment_id = null;
  conektaResponse = null;
  tmarca = '';
  ttipo = '';
  tbanco = '';

  cardData = {
    number_card: null,
    valid_month: null,
    valid_year: null,
    card_holder_name: null,
    card_cvv: null,
    email_holder: null,
    paymentTime: 1,
    paymentTimeA: 1,
    phone: null,
  };
  keysIgnore = [
    'Backspace',
    'Tab',
    'ArrowLeft',
    'ArrowUp',
    'ArrowDown',
    'ArrowRight',
  ];
  paymentsTimes = [
    {
      id: '1_cargo',
      description: '1 cargo',
      value: 1,
      amount: 0,
      show: false,
    },
    {
      id: '3_cargo',
      description: '3 Meses',
      value: 3,
      amount: 0,
      show: false,
    },
    {
      id: '6_cargo',
      description: '6 Meses',
      value: 6,
      amount: 0,
      show: false,
    },
    {
      id: '9_cargo',
      description: '9 Meses',
      value: 9,
      amount: 0,
      show: false,
    },
    {
      id: '12_cargo',
      description: '12 Meses',
      value: 12,
      amount: 0,
      show: false,
    },
    {
      id: '18_cargo',
      description: '18 Meses',
      value: 18,
      amount: 0,
      show: false,
    },
  ];
  currency: any;


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private lottieService: LottieService,
    private EncrDecr: EncrDecrService,
    private http: HttpClient,
    private inputUtilsService: InputUtilsService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.payment_id = params['id'];
    });
  }

  ngOnInit(): void {
    this.currency = this.paymentData.datos['COKT']['currency'];

    //this.spinnerService.show();
    this.paymentForm = this.fb.group(
      {
        number_card: new FormControl(this.cardData.number_card, [
          Validators.required,
          this.validateCardNumber.bind(this),
        ]),
        valid_month: new FormControl(this.cardData.valid_month, [
          Validators.required,
        ]),
        valid_year: new FormControl(this.cardData.valid_year, [
          Validators.required,
        ]),
        card_holder_name: new FormControl(this.cardData.card_holder_name, [
          Validators.required,
        ]),
        card_cvv: new FormControl(this.cardData.card_cvv, [
          Validators.required,
        ]),
        email_holder: new FormControl(this.cardData.email_holder, [
          Validators.required,
          Validators.email,
        ]),
        phone: new FormControl(this.cardData.phone, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(11),
          Validators.pattern('[- +()0-9]+'),
        ]),
      },
      {
        validator: [this.validateDateCard, this.validateCvv.bind(this)],
      }
    );
    this.showPaymentsTimes();
    //this.spinnerService.hide();
  }

  onInputChange(event: any) {
    const numericValue = this.inputUtilsService.onInputChange(event);

    this.phone.setValue(numericValue);
  }
  valid_credit_card(value) {
    // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) return false;

    // The Luhn Algorithm. It's so pretty.
    var nCheck = 0,
      nDigit = 0,
      bEven = false;
    value = value.replace(/\D/g, '');

    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n),
        nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 == 0;
  }

  showPaymentsTimes() {
    let paymentTypeAmounts =
      this.numberCardWithOutSpaces.length == 16 ||
      this.numberCardWithOutSpaces == ''
        ? this.paymentData.monto['COKT']['Visa']
        : this.paymentData.monto['COKT']['AMEX'];
    this.paymentsTimes.forEach((paymentTime) => {
      if (paymentTypeAmounts[paymentTime.id]) {
        paymentTime.show = true;
        paymentTime.amount = paymentTypeAmounts[paymentTime.id];
      }
    });
  }
  maxLength(str, max_length = 16) {
    if (str.length == max_length) {
      return str.slice(0, -1);
    }
    return str;
  }
  formatCardNumber(str, after) {
    after = after || 4;
    var v = str.replace(/[^\dA-Z]/g, ''),
      reg = new RegExp('.{' + after + '}', 'g');
    v = this.maxLength(v);
    return v.replace(reg, function (a) {
      return a + ' ';
    });
  }

  sanitize(event: KeyboardEvent) {
    let value = null;
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^A-Za-z\s]+/g, '');
  }

  cleanCvv(event: KeyboardEvent) {
    if (!this.keysIgnore.includes(event.key)) {
      let value = null;
      const input = event.target as HTMLInputElement;
      value = this.maxLength(input.value, 3);
      if (this.numberCardWithOutSpaces.length == 15) {
        value = this.maxLength(input.value, 4);
      }
      input.value = value;
    }
  }
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    if (!this.keysIgnore.includes(event.key)) {
      input.value = this.formatCardNumber(input.value, 4);
    }
  }

  validateCvv(group) {
    if (group.controls.number_card.value) {
      let number_card = this.numberCardWithOutSpaces;
      if (
        number_card &&
        group.controls.card_cvv.value &&
        ((number_card.length == 15 &&
          group.controls.card_cvv.value.length < 3) ||
          (number_card.length == 16 &&
            group.controls.card_cvv.value.length < 3))
      ) {
        return { cvvInvalid: true };
      }
    }
  }
  validateDateCard(group) {
    if (group.controls.valid_month.value && group.controls.valid_year.value) {
      const date = moment()
        .set('month', group.controls.valid_month.value)
        .set('year', group.controls.valid_year.value)
        .startOf('day');
      const now = moment().startOf('day');
      if (date.isBefore(now)) {
        return { validCardDate: true };
      }
    } else {
      return { validCardDate: true };
    }
  }

  validateCardNumber(c: FormControl) {
    if (c.value !== undefined && this.paymentForm !== undefined && c.dirty) {
      let valid = Conekta.card.validateNumber(this.numberCardWithOutSpaces);
      if (!valid) {
        return { validateCardNumber: true };
      } else {
        this.showPaymentsTimes();
        let bin = this.numberCardWithOutSpaces.substring(0, 6);
        if (bin) {
          //consulta bin api
          this.http
            .post(this.serviceBIN, {
              params: {
                BIN: bin,
                pasarela: 'FISV',
              },
            })
            .subscribe((data) => {
              //setear valores para guardado info tarjeta
              this.tmarca = data['marca'];
              this.ttipo = data['tipo'];
              this.tbanco = data['banco'];
              //
            });
        }
      }
    }
  }
  sendCardData() {
    //this.spinnerService.show();
    this.lottieService.setLoader(true, '');
    if (this.paymentForm.valid) {
      let JSON_STRING = JSON.stringify(this.numberCardWithOutSpaces);
      let base64 = this.EncrDecr.getBase64(JSON_STRING);
      let api_key = this.paymentData.datos['COKT'].tpublica;
      Conekta.setPublicKey(api_key);
      var successResponseHandler = (token) => {
        //let paymentTime = this.paymentsTimes.find(paymentTime => paymentTime.value == this.cardData.paymentTime);
        let number_card = this.numberCardWithOutSpaces;
        let paymentTime;
        if (number_card.length == 15) {
          paymentTime = this.paymentsTimes.find(
            (paymentTime) => paymentTime.value == this.cardData.paymentTimeA
          );
        } else {
          paymentTime = this.paymentsTimes.find(
            (paymentTime) => paymentTime.value == this.cardData.paymentTime
          );
        }
        this.http
          .post(this.serviceURL, {
            params: {
              monto: paymentTime.amount.toString(),
              idEmpresa: this.paymentData.idempresa,
              clave: this.paymentData.clave,
              idPasarela: 'COKT',
              metodoPago: 'card',
              tokenCard: token.id,
              apiKey: this.paymentData.datos['COKT'].tprivada,
              fechaExp: this.valid_month.value + '/' + this.valid_year.value,
              email: this.email_holder.value,
              nombreTa: this.card_holder_name.value,
              telefono: this.phone.value,
              ccType: Conekta.card.getBrand(this.numberCardWithOutSpaces),
              tipoPago: paymentTime.value.toString(),
              referencia: this.paymentData.refencia,
              descripcion: this.paymentData.descripcion,
              tarjeta: base64,
              tmarca: this.tmarca,
              ttipo: this.ttipo,
              tbanco: this.tbanco,
            },
          })
          .subscribe((data) => {
            this.paymentSend.emit(data);
            this.lottieService.setLoader(false, '');
            //this.spinnerService.hide();
          });
      };
      var errorResponseHandler = function (error) {
        this.lottieService.setLoader(false, '');
        //this.spinnerService.hide();
      };
      var tokenParams = {
        card: {
          number: this.numberCardWithOutSpaces,
          name: this.card_holder_name.value,
          exp_year: this.valid_year.value,
          exp_month: this.valid_month.value,
          cvc: this.card_cvv.value,
          address: {},
        },
      };
      Conekta.Token.create(
        tokenParams,
        successResponseHandler,
        errorResponseHandler
      );
    }
  }
  get numberCardWithOutSpaces() {
    if (this.number_card.value) {
      return this.number_card.value.replace(/\s/g, '');
    }
    return '';
  }
  get number_card() {
    return this.paymentForm.get('number_card');
  }
  get valid_month() {
    return this.paymentForm.get('valid_month');
  }
  get valid_year() {
    return this.paymentForm.get('valid_year');
  }
  get card_holder_name() {
    return this.paymentForm.get('card_holder_name');
  }
  get card_cvv() {
    return this.paymentForm.get('card_cvv');
  }
  get email_holder() {
    return this.paymentForm.get('email_holder');
  }
  get phone() {
    return this.paymentForm.get('phone');
  }
}
