import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';
import { EncrDecrService } from '../../../services/encrypt.service';
import { HttpClient} from '@angular/common/http';
import { environment} from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-cobro',
  templateUrl: './cobro.component.html',
  styles: [
  ]
})
export class CobroComponent {
  @Input() link;
  @Input() params;
  @Input() id;
  @Output() valueChange = new EventEmitter();
  @Output() happisend = new EventEmitter();
  // private serviceURL = '//mpuapi.payoff.mx/checkid/';
  private serviceURL = environment.url + 'checkid/';
  idliga;
  datos;
  urliframe;
  urlfinal;
  dataf;
  idhappi;

  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    private EncrDecr: EncrDecrService,
    private http: HttpClient,
    private route:ActivatedRoute,
    private router:Router,
  ) { }

  ngOnInit(): void {
    this.idliga=this.link;
    this.datos=this.params;
    this.idhappi=this.id;

    let JSON_STRING = JSON.stringify(this.datos);
    let base64 = this.EncrDecr.getBase64(JSON_STRING);
    this.urliframe = this.sanitizer.bypassSecurityTrustUrl('https://link.happipay.mx/' + this.idliga + '?params=' + base64);
    this.urlfinal = this.urliframe.changingThisBreaksApplicationSecurity;
    //this.checkstatus();
    //this.valueChange.emit(this.urlfinal);
  }

  closeModal() {
    this.checkstatus();
    this.activeModal.close('Modal Closed');
  }

  checkstatus(){
    const urlcheck = this.serviceURL+this.idhappi;
    this.http.get(urlcheck).subscribe( data => {
      this.dataf=data['ca_status'];
      if(this.dataf == "Aprovado"){
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate(['/',this.id.substring(6)]));
        //window.location.reload();
      }else{ 
      //mostrar mensaje de error y volver a cargar
      this.happisend.emit(this.dataf);
      }
    });
  }

}
