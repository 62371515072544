<div class="modal-header" style="display: flow-root !important;"> 
    <button type="button" class="close" aria-label="Close" (click)="closeModal()" style="-webkit-appearance: none; padding: 0; cursor: pointer; background: 0 0; border: 0; float: right; font-size: 27px; font-weight: 700; line-height: 0.5; color: #000; text-shadow: 0 1px 0 #fff; filter: alpha(opacity=20); opacity: 0.5;">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<p class="titulo-modal">AVISO DE PRIVACIDAD SIMPLIFICADO</p>
<div class="contenido-modal">
    TRAVELMAPSOFT S.A. DE C.V., conocido como SOFTREK para efectos de los servicios que se ofrecen, con domicilio en Calle 45 #461 Col. Centro, Mérida, Yucatán, México y con portal de internet “https://softrek.mx”, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:
</div>
<div class="contenido-modal">
    Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:
    <ul>
        <li>A)	Proveer los servicios y productos que ha solicitado;</li>
        <li>B)	Prevención de fraudes por las transacciones realizadas;</li>
        <li>C)	Evaluar la calidad del servicio que brindamos;</li>
        <li>D)	Comunicarle sobre cambios en los mismos;</li>
        <li>E)	Realizar evaluaciones periódicas de nuestros servicios a efecto de mejorar la calidad de los mismos;</li>
        <li>F)	Recabar datos de seguridad y de asistencia inmediata en caso de alguna contingencia que pueda vulnerar su seguridad;</li>
        <li>G)	Dar cumplimiento a las obligaciones que hemos contraído con usted</li>
    </ul>
</div>

<div class="contenido-modal">
    De manera adicional, utilizaremos su información personal para las siguientes finalidades que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:
    <ul>
        <li>A) Notificarle sobre nuevos servicios que tengan relación con los ya contratados o adquiridos;</li>
        <li>B) Elaborar estudios y programas que son necesarios para determinar hábitos de consumo.</li>
    </ul>
</div>

<div class="contenido-modal">
    <p>La negativa para el uso de sus datos personales para estos fines adicionales, desde este momento usted nos puede comunicar lo anterior presentando una solicitud por escrito y de manera personal en nuestro domicilio, dirigida al Director de la empresa: Felipe Arellano Aponte. Del mismo modo, podrá escribir un correo electrónico a datospersonales@softrek.mx con el asunto USO DE DATOS PERSONALES.</p>
    <p>La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.</p>
    <p>Para conocer mayor información sobre los términos y condiciones en que serán tratados sus datos personales, como los terceros con quienes compartimos su información personal y la forma en que podrá ejercer sus derechos ARCO, puede consultar el aviso de privacidad integral en “https://softrek.mx/aviso-de-privacidad-payoff”. </p>
</div>