import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { CobroComponent } from '../happi/cobro/cobro.component';
import { ModalComponent } from '../happi/modal/modal.component';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { LottieService } from '../../services/lottie.service';
import { EncrDecrService } from '../../services/encrypt.service';
import { environment } from '../../../environments/environment';
import { InputUtilsService } from 'src/app/services/input-utils.service';

declare let Conekta: any;

@Component({
  selector: 'app-happi',
  templateUrl: './happi.component.html',
  styleUrls: ['../payment-components.css'],
})
export class HappiComponent implements OnInit {
  @Output() happisendf = new EventEmitter();
  @Input() paymentData = null;
  paymentForm: FormGroup;
  // private serviceURL = '//mpuapi.payoff.mx/cargo';
  private serviceURL = environment.url + 'cargo';
  private serviceBIN = environment.url + 'bin';
  //private serviceURL = 'http://localhost/mpu/api/cargo';
  amount = null;
  payment_id = null;
  conektaResponse = null;
  paramscobro;
  mes: any;
  anio: any;
  datef;
  mescambio: any;
  tmarca = '';
  ttipo = '';
  tbanco = '';

  cardData = {
    number_card: null,
    valid_month: null,
    valid_year: null,
    card_holder_name: null,
    card_cvv: null,
    email_holder: null,
    paymentTime: 1,
    paymentTimeA: 1,
    phone: null,
  };
  keysIgnore = [
    'Backspace',
    'Tab',
    'ArrowLeft',
    'ArrowUp',
    'ArrowDown',
    'ArrowRight',
  ];
  paymentsTimes = [
    {
      id: '1_cargo',
      description: '1 cargo',
      value: 1,
      amount: 0,
      show: false,
    },
    {
      id: '3_cargo',
      description: '3 Meses',
      value: 3,
      amount: 0,
      show: false,
    },
    {
      id: '6_cargo',
      description: '6 Meses',
      value: 6,
      amount: 0,
      show: false,
    },
    {
      id: '9_cargo',
      description: '9 Meses',
      value: 9,
      amount: 0,
      show: false,
    },
    {
      id: '12_cargo',
      description: '12 Meses',
      value: 12,
      amount: 0,
      show: false,
    },
  ];

  currency: string;


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    private spinnerService: NgxSpinnerService,
    private lottieService: LottieService,
    private EncrDecr: EncrDecrService,
    private modalService: NgbModal,
    private inputUtilsService: InputUtilsService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.payment_id = params['id'];
    });
  }

  ngOnInit(): void {
    this.currency = this.paymentData.datos['HAPP']['currency'];

    //this.lottieService.setLoader(true, '');
    this.paymentForm = this.fb.group(
      {
        number_card: new FormControl(this.cardData.number_card, [
          Validators.required,
          this.validateCardNumber.bind(this),
        ]),
        valid_month: new FormControl(this.cardData.valid_month, [
          Validators.required,
        ]),
        valid_year: new FormControl(this.cardData.valid_year, [
          Validators.required,
        ]),
        card_holder_name: new FormControl(this.cardData.card_holder_name, [
          Validators.required,
        ]),
        card_cvv: new FormControl(this.cardData.card_cvv, [
          Validators.required,
        ]),
        email_holder: new FormControl(this.cardData.email_holder, [
          Validators.required,
          Validators.email,
        ]),
        phone: new FormControl(this.cardData.phone, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(11),
          Validators.pattern('[- +()0-9]+'),
        ]),
      },
      {
        validator: [this.validateDateCard, this.validateCvv.bind(this)],
      }
    );
    this.showPaymentsTimes();
  }

  onInputChange(event: any) {
    const numericValue = this.inputUtilsService.onInputChange(event);

    this.phone.setValue(numericValue);
  }

  valid_credit_card(value) {
    // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) return false;

    // The Luhn Algorithm. It's so pretty.
    var nCheck = 0,
      nDigit = 0,
      bEven = false;
    value = value.replace(/\D/g, '');

    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n),
        nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 == 0;
  }

  showPaymentsTimes() {
    let paymentTypeAmounts =
      this.numberCardWithOutSpaces.length == 16 ||
      this.numberCardWithOutSpaces == ''
        ? this.paymentData.monto['HAPP']['Visa']
        : this.paymentData.monto['HAPP']['AMEX'];
    this.paymentsTimes.forEach((paymentTime) => {
      if (paymentTypeAmounts[paymentTime.id]) {
        paymentTime.show = true;
        paymentTime.amount = paymentTypeAmounts[paymentTime.id];
      }
    });
  }
  maxLength(str, max_length = 16) {
    if (str.length == max_length) {
      return str.slice(0, -1);
    }
    return str;
  }
  formatCardNumber(str, after) {
    after = after || 4;
    var v = str.replace(/[^\dA-Z]/g, ''),
      reg = new RegExp('.{' + after + '}', 'g');
    v = this.maxLength(v);
    return v.replace(reg, function (a) {
      return a + ' ';
    });
  }

  sanitize(event: KeyboardEvent) {
    let value = null;
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^A-Za-z\s]+/g, '');
  }

  cleanCvv(event: KeyboardEvent) {
    if (!this.keysIgnore.includes(event.key)) {
      let value = null;
      const input = event.target as HTMLInputElement;
      value = this.maxLength(input.value, 3);
      if (this.numberCardWithOutSpaces.length == 15) {
        value = this.maxLength(input.value, 4);
      }
      input.value = value;
    }
  }

  /* addValidatorPhone(group){
      if(group.controls.number_card.value && this.numberCardWithOutSpaces.length == 15){
        group.controls.phone.setValidators([Validators.required]);
        group.controls.email_holder.setValidators([Validators.required]);
      }else if(group.controls.number_card.value && group.controls.phone.validator){
        group.controls.phone.clearValidators();
        group.controls.phone.updateValueAndValidity();
        group.controls.email_holder.clearValidators();
        group.controls.email_holder.updateValueAndValidity();
      }
    } */

  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    if (!this.keysIgnore.includes(event.key)) {
      input.value = this.formatCardNumber(input.value, 4);
    }
  }

  validateCvv(group) {
    if (group.controls.number_card.value) {
      let number_card = this.numberCardWithOutSpaces;
      if (
        number_card &&
        group.controls.card_cvv.value &&
        ((number_card.length == 15 &&
          group.controls.card_cvv.value.length < 3) ||
          (number_card.length == 16 &&
            group.controls.card_cvv.value.length < 3))
      ) {
        return { cvvInvalid: true };
      }
    }
  }
  validateDateCard(group) {
    if (group.controls.valid_month.value && group.controls.valid_year.value) {
      const date = moment()
        .set('month', group.controls.valid_month.value - 1)
        .set('year', group.controls.valid_year.value)
        .startOf('day');
      const now = moment().startOf('day');
      if (date.isBefore(now)) {
        return { validCardDate: true };
      }
    } else {
      return { validCardDate: true };
    }
  }

  validateCardNumber(c: FormControl) {
    if (c.value !== undefined && this.paymentForm !== undefined && c.dirty) {
      let valid = Conekta.card.validateNumber(this.numberCardWithOutSpaces);
      if (!valid) {
        return { validateCardNumber: true };
      } else {
        this.showPaymentsTimes();
        let bin = this.numberCardWithOutSpaces.substring(0, 6);
        if (bin) {
          //consulta bin api
          this.http
            .post(this.serviceBIN, {
              params: {
                BIN: bin,
                pasarela: 'FISV',
              },
            })
            .subscribe((data) => {
              //setear valores para guardado info tarjeta
              this.tmarca = data['marca'];
              this.ttipo = data['tipo'];
              this.tbanco = data['banco'];
              //
            });
        }
      }
    }
  }
  sendCardData() {
    //this.spinnerService.show();
    this.lottieService.setLoader(true, '');
    if (this.paymentForm.valid) {
      let JSON_STRING = JSON.stringify(this.numberCardWithOutSpaces);
      let base64 = this.EncrDecr.getBase64(JSON_STRING);
      let number_card = this.numberCardWithOutSpaces;
      let paymentTime;
      if (number_card.length == 15) {
        paymentTime = this.paymentsTimes.find(
          (paymentTime) => paymentTime.value == this.cardData.paymentTimeA
        );
      } else {
        paymentTime = this.paymentsTimes.find(
          (paymentTime) => paymentTime.value == this.cardData.paymentTime
        );
      }
      //let paymentTime = this.paymentsTimes.find(paymentTime => paymentTime.value == this.cardData.paymentTime);
      this.http
        .post(this.serviceURL, {
          params: {
            monto: paymentTime.amount.toString(),
            idPasarela: 'HAPP',
            idEmpresa: this.paymentData.idempresa,
            nombreTa: this.card_holder_name.value,
            ccType: Conekta.card.getBrand(this.numberCardWithOutSpaces),
            tipoPago: paymentTime.value.toString(),
            referencia: this.paymentData.clave,
            descripcion: this.paymentData.descripcion,
            clave: this.paymentData.clave,
            fechaExp: this.valid_month.value + '/' + this.valid_year.value,
            tarjeta: base64,
            tmarca: this.tmarca,
            ttipo: this.ttipo,
            tbanco: this.tbanco,
          },
        })
        .subscribe((data) => {
          this.conektaResponse = data;
          switch (this.valid_month.value) {
            case '1':
              this.mes = '01';
              break;
            case '2':
              this.mes = '02';
              break;
            case '3':
              this.mes = '03';
              break;
            case '4':
              this.mes = '04';
              break;
            case '5':
              this.mes = '05';
              break;
            case '6':
              this.mes = '06';
              break;
            case '7':
              this.mes = '07';
              break;
            case '8':
              this.mes = '08';
              break;
            case '9':
              this.mes = '09';
              break;
            case '10':
              this.mes = '10';
              break;
            case '11':
              this.mes = '11';
              break;
            case '12':
              this.mes = '12';
              break;
          }
          switch (this.valid_year.value) {
            case '2021':
              this.anio = '21';
              break;
            case '2022':
              this.anio = '22';
              break;
            case '2023':
              this.anio = '23';
              break;
            case '2024':
              this.anio = '24';
              break;
            case '2025':
              this.anio = '25';
              break;
            case '2026':
              this.anio = '26';
              break;
            case '2027':
              this.anio = '27';
              break;
            case '2028':
              this.anio = '28';
              break;
            case '2029':
              this.anio = '29';
              break;
            case '2030':
              this.anio = '30';
              break;
            case '2031':
              this.anio = '31';
              break;
            case '2032':
              this.anio = '32';
              break;
            case '2033':
              this.anio = '33';
              break;
            case '2034':
              this.anio = '34';
              break;
            case '2035':
              this.anio = '35';
              break;
          }
          this.datef = this.mes + this.anio;
          this.paramscobro = {
            numerotarjeta: this.number_card.value.replace(/ /g, ''),
            idliga: this.conektaResponse.idLink,
            linktp: paymentTime.value.toString(),
            fechaexp: this.datef,
            nombretit: this.card_holder_name.value,
            codigo: this.card_cvv.value,
            bl_billingPhone: this.phone.value,
            email: this.email_holder.value,
            montolink: paymentTime.amount.toString(),
            tipolink: 1,
            referencia: this.paymentData.clave,
            descrip: this.paymentData.descripcion,
          };
          this.open(
            this.conektaResponse.idLink,
            this.paramscobro,
            this.paymentData.clave
          );
        });
    }
  }

  sendcorreo() {
    const cobrourl = 'https://mpuapi.payoff.mx/envio/correo';

    this.http.get(cobrourl).subscribe((data) => {});
  }

  open(link: any, params: any, id: any) {
    //this.spinnerService.hide();
    this.lottieService.setLoader(false, '');
    const modalRef = this.modalService.open(CobroComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.link = link;
    modalRef.componentInstance.params = params;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.happisend.subscribe(($e) => {
      this.happisendf.emit($e);
    });
    modalRef.result.then((result) => {}).catch((error) => {});
  }

  open3ds() {
    ModalComponent;
    const modalRef = this.modalService.open(ModalComponent, {
      backdrop: 'static',
    });
    modalRef.result.then((result) => {}).catch((error) => {});
  }

  get numberCardWithOutSpaces() {
    if (this.number_card.value) {
      return this.number_card.value.replace(/\s/g, '');
    }
    return '';
  }
  get number_card() {
    return this.paymentForm.get('number_card');
  }
  get valid_month() {
    return this.paymentForm.get('valid_month');
  }
  get valid_year() {
    return this.paymentForm.get('valid_year');
  }
  get card_holder_name() {
    return this.paymentForm.get('card_holder_name');
  }
  get card_cvv() {
    return this.paymentForm.get('card_cvv');
  }
  get email_holder() {
    return this.paymentForm.get('email_holder');
  }
  get phone() {
    return this.paymentForm.get('phone');
  }
}
