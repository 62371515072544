<div class="payment-form-container">
    <div class="form-container w-100" [class.green-border]="paymentForm.invalid">
        <p *ngIf="!referenciaV && validateMonto" class="title" [class.selected]="paymentForm.invalid"><span
                class="number">1</span> Ingresar
            los datos del cliente</p>
        <br>
        <!-- vista con referencia nueva -->
        <p *ngIf="!referenciaPRE" class="payment-amount">
            MONTO A PAGAR
        </p>
        <p *ngIf="!referenciaPRE && paymentTypeAmounts <= 10000 " class="oxxo-amount">{{paymentTypeAmounts |
            currency:'USD':'symbol'}} {{ currency }}</p>

        <div *ngIf="validateMonto">


            <p *ngIf="!referenciaPRE" class="description-oxxo">
                <strong>*OXXO cobrará una comisión adicional al momento de realizar el pago.</strong>
            </p>
            <!-- /////////////////////////////// -->
            <!-- Vista con referencia existeste -->
            <p *ngIf="referenciaPRE" style="text-align: center">
                <strong>Ya se ha generado una referencia para este cargo.</strong>
            </p>
            <!-- /////////////////////////////// -->
            <p *ngIf="referenciaV" style="text-align: right">
                <button *ngIf="botondescarga" (click)="downloadimg()" class="btn btn-info mt-3"
                    style="color: white;margin-right: 2px;">Descargar</button>
            </p>
            <div *ngIf="referenciaV" id="contenido">
                <p class="subtitle color707070">
                    REFERENCIA
                </p>
                <div class="container-reference">
                    <p class="number-reference">
                        {{referenciaV}}
                    </p>
                </div>
                <p class="subtitle color707070">
                    Código de barras
                </p>
                <div style="text-align: center;">
                    <img src="{{codigo}}" alt="" class="codigo_barras">
                </div>
                <img alt="" class="codigo_barras" src="{{codigonu}}">
                <br>
                <ol>
                    <li>Acude a la tienda OXXO más cercana</li>
                    <li>Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</li>
                    <li>Dicta al cajero el número de referencia mostrado en pantalla o enseña el código de barras para
                        su lectura.</li>
                    <li>Realiza el pago correspondiente.</li>
                    <li>Al confirmar tu pago, el cajero te entregará un comprobante impreso.</li>
                    <li>Conserva este comprobante de pago para futuras referencias.</li>
                </ol>
            </div>
            <form class="form row" [formGroup]="paymentForm" *ngIf="!referenciaV">
                <div>
                    <div class="row d-flex align-items-center m-t-20">
                        <div class="group col-12">
                            <div class="col-12">
                                <p>Nombre del titular:</p>
                                <input class="w-100" (keyup)="sanitize($event)"
                                    [ngClass]="card_holder_name.dirty || card_holder_name.touched ? (card_holder_name.invalid ? 'error' : 'valid') : ''"
                                    formControlName="card_holder_name" placeholder="Campo obligatorio" type="text"
                                    name="name">
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center m-t-20">
                        <div class="group col-6">
                            <div class="col-12">
                                <p>Email:</p>
                                <input class="w-100" placeholder="nombre@email.com"
                                    [ngClass]="email_holder.dirty || email_holder.touched ? (email_holder.invalid ? 'error' : 'valid') : ''"
                                    formControlName="email_holder" type="email" name="number-card">
                            </div>
                        </div>
                        <div class="group col-6">
                            <div class="col-12">
                                <p>Teléfono:</p>
                                <input class="w-100" maxlength="11"
                                    [ngClass]="phone.dirty || phone.touched ? (phone.invalid ? 'error' : 'valid') : ''"
                                    placeholder="Campo obligatorio" formControlName="phone" name="number-card"
                                    (input)="onInputChange($event)">
                            </div>
                        </div>
                    </div>
                    <div [className]="paymentForm.valid ? '' : 'disabled'">
                        <button *ngIf="!referenciaV" [disabled]="paymentForm.invalid" (click)="sendOxxoData()"
                            class="w-100 pay-button">Generar referencia</button>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="!validateMonto">
            <p style="text-align: center; margin-top: 15px; color: red">
                <strong>La cantidad no puede ser superior de </strong>
            </p>
            <p class="oxxo-amount" style=" color: red">$10,000.00 MXN.</p>
        </div>
    </div>
</div>
<ngx-spinner type="">
    <img src='assets/img/loader.gif' width="120" />"
</ngx-spinner>