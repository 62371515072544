<div class="payment-form-container">
    <div class="form-container w-100 green-border"
        [ngClass]="{'orange-border': paymentData.monto.PAGO.status == 'Pendiente revision', 'red-border': paymentData.monto.PAGO.status == 'Denegado'}">
        <div *ngIf="paymentData.monto.PAGO.status == 'Pendiente'">
            <p class="title" [class.selected]="paymentForm.invalid"><span class="number">1</span> Seleccione el tipo de
                pago e ingrese los datos solicitados</p>
        </div>
        <div *ngIf="paymentData.monto.PAGO.status == 'Pendiente revision'">
            <h5 style="color: #d89219;">Orden en espera</h5>
            <p class="description-oxxo">Esta liga de pago ya cuenta con un pago Manual en proceso de revisión, espere
                respuesta pronta del pago para poder conocer su estatus</p>
        </div>
        <br>
        <!-- vista con referencia nueva -->
        <p *ngIf="verformulario" class="payment-amount">
            MONTO A PAGAR
        </p>
        <p *ngIf="verformulario" class="oxxo-amount">{{paymentTypeAmounts | currency:'USD':'symbol'}} {{ currency }}</p>
        <div *ngIf="verformulario">
            <!-- /////////////////////////////// -->
            <!-- Vista con referencia existeste -->
            <p *ngIf="!verformulario" style="text-align: center">
                <strong>Ya se ha generado una referencia para este cargo.</strong>
            </p>
            <div *ngIf="!verformulario" id="contenido">
                <p class="subtitle color707070">
                    REFERENCIA
                </p>
                <div class="container-reference">
                    <p class="number-reference">
                        {{verformulario}}
                    </p>
                </div>
                <p class="subtitle color707070">
                    Código de barras
                </p>
                <div style="text-align: center;">
                    <img src="{{codigo}}" alt="" class="codigo_barras">
                </div>
            </div>
            <form class="form row" [formGroup]="paymentForm" *ngIf="verformulario">
                <div>
                    <div class="row d-flex align-items-center m-t-20">
                        <div class="group col-12">
                            <div class="col-12">
                                <p>Tipo de pago:</p>
                                <select
                                    [ngClass]="paymentData.monto.PAGO.status == 'Pendiente revision' ? 'disabled' : ''"
                                    formControlName="TipoPago" class="form-select" (change)="onChangeOption($event)">
                                    <!-- <option value="1" selected>Depósito</option>
                                    <option value="2">Transferencia</option>
                                    <option value="3">Efectivo</option>
                                    <option value="4">Tarjeta presencial</option> -->
                                    <option [value]="item.value" *ngFor="let item of arreglopago">{{ item.nombre }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center m-t-20">
                        <div class="group col-12">
                            <div class="col-12"
                                [ngClass]="paymentData.monto.PAGO.status == 'Pendiente revision' ? 'disabled' : ''">
                                <p>Nombre del titular:</p>
                                <input class="w-100" (keyup)="sanitize($event)"
                                    [ngClass]="card_holder_name.dirty || card_holder_name.touched ? (card_holder_name.invalid ? 'error' : 'valid') : ''"
                                    formControlName="card_holder_name" placeholder="Campo obligatorio" type="text"
                                    name="">
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center m-t-20">
                        <div class="group col-6">
                            <div class="col-12"
                                [ngClass]="paymentData.monto.PAGO.status == 'Pendiente revision' ? 'disabled' : ''">
                                <p>Email:</p>
                                <input class="w-100" placeholder="nombre@email.com"
                                    [ngClass]="email_holder.dirty || email_holder.touched ? (email_holder.invalid ? 'error' : 'valid') : ''"
                                    formControlName="email_holder" type="email" name="">
                            </div>
                        </div>
                        <div class="group col-6">
                            <div class="col-12"
                                [ngClass]="paymentData.monto.PAGO.status == 'Pendiente revision' ? 'disabled' : ''">
                                <p>Teléfono:</p>
                                <input class="w-100" placeholder="Teléfono" formControlName="phone" maxlength="11"
                                    [ngClass]="phone.dirty || phone.touched ? (phone.invalid ? 'error' : 'valid') : ''"
                                    name="" (input)="onInputChange($event)">
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center m-t-20">
                        <div class="group col-6">
                            <div class="col-12"
                                [ngClass]="paymentData.monto.PAGO.status == 'Pendiente revision' ? 'disabled' : ''">
                                <p>Fecha de la transacción:</p>
                                <input class="w-100" [max]="prueba2"
                                    [ngClass]="fecha.dirty || fecha.touched ? (fecha.invalid ? 'error' : 'valid') : ''"
                                    formControlName="fecha" type="date">
                            </div>
                        </div>
                        <div class="group col-6" *ngIf="valueSelect == 1 || valueSelect == 2">
                            <div class="col-12"
                                [ngClass]="paymentData.monto.PAGO.status == 'Pendiente revision' ? 'disabled' : ''">
                                <p>Banco receptor del pago:</p>
                                <input class="w-100"
                                    [ngClass]="bancoDep.dirty || bancoDep.touched ? (bancoDep.invalid ? 'error' : 'valid') : ''"
                                    placeholder="Campo obligatorio" formControlName="bancoDep" name="bancoDep">
                            </div>
                        </div>
                        <div class="group col-12" *ngIf="valueSelect == 2" style="margin-top: 12px;">
                            <div class="col-12"
                                [ngClass]="paymentData.monto.PAGO.status == 'Pendiente revision' ? 'disabled' : ''">
                                <p>Clave de rastreo:</p>
                                <input class="w-100"
                                    [ngClass]="crastreo.dirty || crastreo.touched ? (crastreo.invalid ? 'error' : 'valid') : ''"
                                    placeholder="Campo obligatorio" formControlName="crastreo" name="crastreo">
                            </div>
                        </div>
                        <!-- campo de folio para pago efectivo
                        <div class="group col-6" *ngIf="valueSelect == 3">
                            <div class="col-12"
                                [ngClass]="paymentData.monto.PAGO.status == 'Pendiente revision' ? 'disabled' : ''">
                                <p>Folio:</p>
                                <input class="w-100"
                                    [ngClass]="folio.dirty || folio.touched ? (folio.invalid ? 'error' : 'valid') : ''"
                                    placeholder="Campo obligatorio" formControlName="folio" name="folio">
                            </div>
                        </div> -->
                        <div class="group col-6" *ngIf="valueSelect == 4">
                            <div class="col-12"
                                [ngClass]="paymentData.monto.PAGO.status == 'Pendiente revision' ? 'disabled' : ''">
                                <p>Folio/núm. autorización:</p>
                                <input class="w-100"
                                    [ngClass]="noAutorizacion.dirty || noAutorizacion.touched ? (noAutorizacion.invalid ? 'error' : 'valid') : ''"
                                    placeholder="Campo obligatorio" formControlName="noAutorizacion"
                                    name="noAutorizacion">
                            </div>
                        </div>
                    </div>
                    <br>
                    <div [ngClass]="paymentData.monto.PAGO.status == 'Pendiente revision' ? 'disabled' : ''">
                        <label for="files" class="btn btn-secondary ">Comprobante de pago</label>
                        <input type="file" accept="image/png, image/jpeg, application/pdf" style="display: none"
                            name="files" id="files" (change)="onChange($event)" />

                        <div *ngIf="myimglog"
                            [ngClass]="paymentData.monto.PAGO.status == 'Pendiente revision' ? 'd-none' : ''">
                            <img [src]="myimglog" width="200px" height="200px" alt="" />
                        </div>
                        <div [ngClass]="paymentData.monto.PAGO.status == 'Pendiente revision' ? 'd-block' : 'd-none'">
                            <img [src]="paymentData.monto.PAGO.img" width="200px" height="200px" alt="" />
                        </div>
                    </div>
                    <div class="row d-flex align-items-center m-t-20" *ngIf="valueSelect == 3">
                        <div class="group col-12"
                            [ngClass]="paymentData.monto.PAGO.status == 'Pendiente revision' ? 'disabled' : ''">
                            <div class="col-12">
                                <p>Descripción:</p>
                                <textarea name="Desc" formControlName="Desc" cols="30" rows="3"
                                    class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="paymentData.monto.PAGO.status == 'Pendiente revision' ? 'disabled' : ''">
                        <button [ngClass]="paymentForm.valid ? '' : 'disabled'" (click)="sendPagoData()"
                            class="w-100 pay-button">Generar pago</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<ngx-spinner type="">
    <img src='assets/img/loader.gif' width="120" />"
</ngx-spinner>