import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-stripe',
  templateUrl: './modal-stripe.component.html',
  styleUrls: ['./modal-stripe.component.css']
})
export class ModalStripeComponent implements OnInit {

  urlfmodal;
  urlmodal;
  url = 'https://checkout.stripe.com/pay/cs_test_a1Slin8q044V24AaAiwem2YGODjZTo1tNraccQbmSRARYrl0YFmezFBWu3#fidkdWxOYHwnPyd1blpxYHZxWjA0STB%2Fal9GZ2Fic31fcWxyXFEwX19jQVFvNHJoYGd1a0ZrckR1d3x%2FU21ESmRNMFdpSHQwalQxM31qZkdodXFHZjVndjV2YzdncmJNYlxGMWt2PXBNaW9dNTVsVH19VnI8dicpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl';
  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    window.location.href = this.url;
    // this.urlmodal = this.sanitizer.bypassSecurityTrustUrl('https://checkout.stripe.com/pay/cs_test_a1Slin8q044V24AaAiwem2YGODjZTo1tNraccQbmSRARYrl0YFmezFBWu3#fidkdWxOYHwnPyd1blpxYHZxWjA0STB%2Fal9GZ2Fic31fcWxyXFEwX19jQVFvNHJoYGd1a0ZrckR1d3x%2FU21ESmRNMFdpSHQwalQxM31qZkdodXFHZjVndjV2YzdncmJNYlxGMWt2PXBNaW9dNTVsVH19VnI8dicpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl');
    // this.urlfmodal = this.urlmodal.changingThisBreaksApplicationSecurity;
  }
  closeModal() {
    
    this.activeModal.close('Modal Closed');
  }

}
