import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { LottieService } from '../../services/lottie.service';
import { EncrDecrService } from '../../services/encrypt.service';
import { environment } from '../../../environments/environment';
declare let Conekta: any;
import { ModalFiservComponent } from '../fiserv/modal-fiserv/modal-fiserv.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DomSanitizer } from '@angular/platform-browser';
import { Logs } from 'selenium-webdriver';
import { InputUtilsService } from 'src/app/services/input-utils.service';

@Component({
  selector: 'app-fiserv',
  templateUrl: './fiserv.component.html',
  styleUrls: ['../payment-components.css'],
})
export class FiservComponent implements OnInit {
  @Input() paymentData = null;
  @Output() fiservSend = new EventEmitter();
  paymentForm: FormGroup;
  // private serviceURL = '//mpuapi.payoff.mx/cargo';
  private serviceURL = environment.url + 'cargo';
  private serviceBIN = environment.url + 'bin';
  private checkidUrl = environment.url + 'checkid/';

  private privateKey = environment.privateKey;
  private publickey = environment.publicKey;
  amount = null;
  payment_id = null;
  conektaResponse = null;
  imgtarjeta;
  vermontos = false;
  dataModal;
  montoinicial;
  track: string;
  cardData = {
    number_card: null,
    valid_month: null,
    valid_year: null,
    card_holder_name: null,
    card_cvv: null,
    email_holder: null,
    paymentTime: 1,
    phone: null,
  };
  keysIgnore = [
    'Backspace',
    'Tab',
    'ArrowLeft',
    'ArrowUp',
    'ArrowDown',
    'ArrowRight',
  ];
  paymentsTimes = [
    {
      id: '1_cargo',
      description: '1 cargo',
      value: 1,
      amount: 0,
      show: false,
    },
    {
      id: '3_cargo',
      description: '3 Meses',
      value: 3,
      amount: 0,
      show: false,
    },
    {
      id: '6_cargo',
      description: '6 Meses',
      value: 6,
      amount: 0,
      show: false,
    },
    {
      id: '9_cargo',
      description: '9 Meses',
      value: 9,
      amount: 0,
      show: false,
    },
    {
      id: '12_cargo',
      description: '12 Meses',
      value: 12,
      amount: 0,
      show: false,
    },
    {
      id: '18_cargo',
      description: '18 Meses',
      value: 18,
      amount: 0,
      show: false,
    },
  ];

  version3DS;
  iframe3DS;
  idControl: number;
  // pirvateKey: string;
  // publicKey: string;
  storeId: number;

  // TODO: variables para iframe
  count3ds = 0;
  htmlToAdd;
  verison3ds;
  versionValidate: boolean;
  DSMethodData;
  threeDSMethodData;
  tmarca = '';
  ttipo = '';
  tbanco = '';
  private fiserManual = environment.url + 'fiserv/manual';
  currency: any;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private lottieService: LottieService,
    private EncrDecr: EncrDecrService,
    private http: HttpClient,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private inputUtilsService: InputUtilsService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.payment_id = params['id'];
    });
  }

  ngOnInit(): void {
    // this.pirvateKey = this.paymentData.datos['FISV']['tprivada'];
    // this.publicKey = this.paymentData.datos['FISV']['tpublica'];
    this.storeId = this.paymentData.datos['FISV']['tprivada'];
    this.montoinicial = this.paymentData.monto['FISV']['Credito']['1_cargo'];
    this.currency = this.paymentData.datos['FISV']['currency'];


    let track = this.paymentData.clave;
    let splice = track.split('-');
    this.track = splice[1];

    this.paymentForm = this.fb.group(
      {
        number_card: new FormControl(this.cardData.number_card, [
          Validators.required,
          this.validateCardNumber.bind(this),
        ]),
        valid_month: new FormControl(this.cardData.valid_month, [
          Validators.required,
        ]),
        valid_year: new FormControl(this.cardData.valid_year, [
          Validators.required,
        ]),
        card_holder_name: new FormControl(this.cardData.card_holder_name, [
          Validators.required,
        ]),
        card_cvv: new FormControl(this.cardData.card_cvv, [
          Validators.required,
        ]),
        email_holder: new FormControl(this.cardData.email_holder, [
          Validators.required,
          Validators.email,
        ]),
        phone: new FormControl(this.cardData.phone, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(11),
          Validators.pattern('[- +()0-9]+'),
        ]),
      },
      {
        validator: [this.validateDateCard, this.validateCvv.bind(this)],
      }
    );
    //this.showPaymentsTimes('Credito');
    //this.spinnerService.hide();
  }

  onInputChange(event: any) {
    const numericValue = this.inputUtilsService.onInputChange(event);

    this.phone.setValue(numericValue);
  }

  valid_credit_card(value) {
    // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) return false;

    // The Luhn Algorithm. It's so pretty.
    var nCheck = 0,
      nDigit = 0,
      bEven = false;
    value = value.replace(/\D/g, '');

    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n),
        nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 == 0;
  }

  showPaymentsTimes(clave) {
    this.vermontos = true;
    let paymentTypeAmounts = this.paymentData.monto['FISV'][clave];
    this.paymentsTimes.forEach((paymentTime) => {
      if (paymentTypeAmounts[paymentTime.id]) {
        paymentTime.show = true;
        paymentTime.amount = paymentTypeAmounts[paymentTime.id];
      }
    });
  }
  maxLength(str, max_length = 16) {
    if (str.length == max_length) {
      return str.slice(0, -1);
    }
    return str;
  }
  formatCardNumber(str, after) {
    after = after || 4;
    var v = str.replace(/[^\dA-Z]/g, ''),
      reg = new RegExp('.{' + after + '}', 'g');
    v = this.maxLength(v);
    return v.replace(reg, function (a) {
      return a + ' ';
    });
  }

  sanitize(event: KeyboardEvent) {
    let value = null;
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^A-Za-z\s]+/g, '');
  }

  cleanCvv(event: KeyboardEvent) {
    if (!this.keysIgnore.includes(event.key)) {
      let value = null;
      const input = event.target as HTMLInputElement;
      value = this.maxLength(input.value, 3);
      if (this.numberCardWithOutSpaces.length == 15) {
        value = this.maxLength(input.value, 4);
      }
      input.value = value;
    }
  }
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    if (!this.keysIgnore.includes(event.key)) {
      input.value = this.formatCardNumber(input.value, 4);
    }
  }

  validateCvv(group) {
    if (group.controls.number_card.value) {
      let number_card = this.numberCardWithOutSpaces;
      if (
        number_card &&
        group.controls.card_cvv.value &&
        ((number_card.length == 15 &&
          group.controls.card_cvv.value.length < 3) ||
          (number_card.length == 16 &&
            group.controls.card_cvv.value.length < 3))
      ) {
        return { cvvInvalid: true };
      }
    }
  }
  validateDateCard(group) {
    if (group.controls.valid_month.value && group.controls.valid_year.value) {
      const date = moment()
        .set('month', group.controls.valid_month.value)
        .set('year', group.controls.valid_year.value)
        .startOf('day');
      const now = moment().startOf('day');
      if (date.isBefore(now)) {
        return { validCardDate: true };
      }
    } else {
      return { validCardDate: true };
    }
  }

  validateCardNumber(c: FormControl) {
    if (c.value !== undefined && this.paymentForm !== undefined && c.dirty) {
      let valid = Conekta.card.validateNumber(this.numberCardWithOutSpaces);
      if (!valid) {
        this.vermontos = false;

        return { validateCardNumber: true };
      } else {
        //validacion de revision bin
        let bin = this.numberCardWithOutSpaces.substring(0, 6);
        if (bin) {
          //consulta bin api
          this.http
            .post(this.serviceBIN, {
              params: {
                BIN: bin,
                pasarela: 'FISV',
              },
            })
            .subscribe((data) => {
              //setear valores para guardado info tarjeta
              this.tmarca = data['marca'];
              this.ttipo = data['tipo'];
              this.tbanco = data['banco'];
              //
              let clave;
              let american;
              switch (data['idbanco']) {
                case 'AFME':
                  //afirme.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/afirme.svg';
                  american = 2;
                  break;
                case 'AMEX':
                  //americanexpress2.svg
                  american = 1;
                  break;
                case 'MIFL':
                  //banca-mifel.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/banca-mifel.svg';
                  american = 2;
                  break;
                case 'BAJO':
                case 'BDSU':
                  //banco-del-bajio.svg
                  this.imgtarjeta = 'assets/img/banco-del-bajio.svg';
                  clave = 'Credito';
                  american = 2;
                  break;
                case 'BNDE':
                  //banjercito.svg
                  this.imgtarjeta = 'assets/img/banjercito.svg';
                  clave = 'Credito';
                  american = 2;
                  break;
                case 'BNOT':
                case 'MECT':
                  //Banorte.svg
                  this.imgtarjeta = 'assets/img/Banorte.svg';
                  clave = 'Credito';
                  american = 2;
                  break;
                case 'BREG':
                  //banregio.svg
                  this.imgtarjeta = 'assets/img/banregio.svg';
                  clave = 'Credito';
                  american = 2;
                  break;
                case 'BBVA':
                  //BBVA.svg
                  this.imgtarjeta = 'assets/img/BBVA.svg';
                  clave = 'Credito';
                  american = 2;
                  break;
                case 'BANX':
                case 'NACL':
                  //citibanamex.svg
                  clave = 'Banamex';
                  this.imgtarjeta = 'assets/img/citibanamex.svg';
                  american = 2;
                  break;
                case 'FMSA':
                  //famsa.svg
                  this.imgtarjeta = 'assets/img/famsa.svg';
                  clave = 'Credito';
                  american = 2;
                  break;
                case 'HSBC':
                  //HSBC.svg
                  this.imgtarjeta = 'assets/img/HSBC.svg';
                  clave = 'Credito';
                  american = 2;
                  break;
                case 'IMBS':
                  //Inbursa.svg
                  this.imgtarjeta = 'assets/img/Inbursa.svg';
                  clave = 'Credito';
                  american = 2;
                  break;
                case 'INVX':
                  //invex.svg
                  this.imgtarjeta = 'assets/img/invex.svg';
                  clave = 'Credito';
                  american = 2;
                  break;
                case 'LIVP':
                  //liverpool.svg
                  this.imgtarjeta = 'assets/img/liverpool.svg';
                  clave = 'Credito';
                  american = 2;
                  break;
                case 'SANT':
                case 'SERF':
                  //Santander.svg
                  this.imgtarjeta = 'assets/img/Santander.svg';
                  clave = 'Credito';
                  american = 2;
                  break;
                case 'SCTB':
                  //Scotiabank.svg
                  this.imgtarjeta = 'assets/img/Scotiabank.svg';
                  clave = 'Credito';
                  american = 2;
                  break;
                case 'INDT':
                  //banco_industrial.png
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/banco_industrial.svg';
                  american = 2;
                  break;
                case 'IXEB':
                  //Ixe.svg
                  this.imgtarjeta = 'assets/img/ixe.svg';
                  clave = 'Credito';
                  american = 2;
                  break;
                case 'AZTC':
                  //azteca.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/azteca.svg';
                  american = 2;
                  break;
                case 'WSPC':
                  //westpac.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/westpac.svg';
                  american = 2;
                  break;
                case 'GECF':
                  //ge_capital_financial.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/ge_capital_financial.svg';
                  american = 2;
                  break;
                case 'LISE':
                  //libertad_servicios.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/libertad_servicios.svg';
                  american = 2;
                  break;
                case 'CHMA':
                  //chase.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/chase.svg';
                  american = 2;
                  break;
                case 'BPDN':
                  //promotor_norte.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/mastercard.svg';
                  american = 2;
                  break;
                case 'PYOP':
                  //prosa.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/prosa.svg';
                  american = 2;
                  break;
                case 'CSCU':
                  //card_services.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/card_services.svg';
                  american = 2;
                  break;
                case 'FCEN':
                  //ficen.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/ficen.svg';
                  american = 2;
                  break;
                case 'FUNI':
                  //unisap.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/unisap.svg';
                  american = 2;
                  break;
                case 'CREM':
                case 'CRMA':
                  //credomatic.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/credomatic.svg';
                  american = 2;
                  break;
                case 'BNSI':
                  //bansi.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/bansi.svg';
                  american = 2;
                  break;
                case 'IBIB':
                  //ibi_services.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/ibi_services.svg';
                  american = 2;
                  break;
                //logos pendientes
                case 'IPSV':
                  //interpayment.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/interpayment.svg';
                  american = 2;
                  break;
                case 'SPIR':
                  //spira.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/spira.svg';
                  american = 2;
                  break;
                case 'BACR':
                  //bancrecer.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/bancrecer.svg';
                  american = 2;
                  break;
                case 'BSFI':
                  //bansefi.png
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/bansefi.png';
                  american = 2;
                  break;
                case 'TSSI':
                  //total_system.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/total_system.svg';
                  american = 2;
                  break;
                case 'AUTF':
                  //autofin.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/autofin.svg';
                  american = 2;
                  break;
                case 'COMS':
                  //commstar.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/commstar.svg';
                  american = 2;
                  break;
                case 'ODRR':
                  //operadora_recursos.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/operadora_recursos.svg';
                  american = 2;
                  break;
                case 'QATR':
                  //bank_qatar.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/bank_qatar.svg';
                  american = 2;
                  break;
                case 'MONX':
                  //banco_monex.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/banco_monex.svg';
                  american = 2;
                  break;
                case 'MUTV':
                  //multiva.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/multiva.svg';
                  american = 2;
                  break;
                case 'COPL':
                  //bancoppel.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/bancoppel.svg';
                  american = 2;
                  break;
                case 'BIRN':
                  //banco_internacional.svg
                  clave = 'Credito';
                  this.imgtarjeta = 'assets/img/banco_internacional.svg';
                  american = 2;
                  break;
                case 'EXTR':
                  //banco_internacional.svg
                  clave = 'Extranjera';
                  this.imgtarjeta = 'assets/img/extranjera.svg';
                  american = 2;
                  break;
                default:
                  this.imgtarjeta = 'assets/img/mastercard.png';
                  clave = 'Extranjera';
                  american = 2;
                  break;
              }
              if (american == 1) {
                this.vermontos = false;
                const nameControl = this.paymentForm.get('number_card');
                nameControl.setErrors({
                  validateCardNumber: true,
                });
              } else {
                this.showPaymentsTimes(clave);
              }
            });
        }
      }
    }
  }
  sendCardData() {
    this.lottieService.setLoader(true, '');
    if (this.paymentForm.valid) {
      let JSON_STRING = JSON.stringify(this.numberCardWithOutSpaces);
      // let JSON_STRING = JSON.stringify('4147463011110083');
      let base64 = this.EncrDecr.getBase64(JSON_STRING);

      let JSON_STRING_CVV = JSON.stringify(this.card_cvv.value);
      let base64Cvv = this.EncrDecr.getBase64(JSON_STRING_CVV);

      // this.fiservSend.emit(Params);

      let paymentTime = this.paymentsTimes.find(
        (paymentTime) => paymentTime.value == this.cardData.paymentTime
      );

      let month = this.valid_month.value;
      if (this.valid_month.value < 10) {
        month = '0' + this.valid_month.value;
      }
      //  monto: paymentTime.amount.toString(),
      //  tipoPago: paymentTime.value.toString(),
      var data = {
        monto: paymentTime.amount.toString(),
        tarjeta: base64,
        securityCode: base64Cvv,
        month: month,
        year: this.valid_year.value,
        ccType: Conekta.card.getBrand(this.numberCardWithOutSpaces),
        tipoPago: paymentTime.value.toString(),
        idPasarela: 'FISV',
        idEmpresa: this.paymentData.idempresa,
        clave: this.paymentData.clave,
        metodoPago: 'card',
        apiKey: this.privateKey,
        email: this.email_holder.value,
        ccNombre: this.card_holder_name.value,
        telefono: this.phone.value,
        referencia: this.paymentData.refencia,
        descripcion: this.paymentData.descripcion,
        track: this.track,
        pirvateKey: this.privateKey,
        publicKey: this.publickey,
        storeId: this.storeId,
        tmarca: this.tmarca,
        ttipo: this.ttipo,
        tbanco: this.tbanco,
      };

      // this.lottieService.setLoader(false, '');

      this.http
        .post(this.serviceURL, {
          params: data,
        })
        .subscribe((data) => {
          // debugger
          if (data['banderaValidacion'] == 'APPROVED') {
            const now = new Date();
            let array = {
              status: 'PENDIENTE',
              respuesta: {
                status: 'PENDIENTE',
                fecha: now.toLocaleDateString(),
                autorizacion: '',
                ccNumero: '',
                monto: '',
                track: this.paymentData.clave,
              },
            };
            this.lottieService.setLoader(false, '');
            this.fiservSend.emit(array);
          } else {
            this.idControl = data['idControl'];
            if (data['status'] == 'APROBADO') {
              if (data['version'] == 2.1) {
                if (data['bandera'] == true) {
                  let datos = data['data'][0];

                  this.dataModal = {
                    toUrl: datos['acsURL'],
                    cReq: datos['cReq'],
                    termURL: datos['termURL'],
                    acsURL: datos['acsURL'],
                    version: data['version'],
                    bandera: data['bandera'],
                  };

                  this.lottieService.setLoader(false, '');
                  this.open3ds();
                } else {
                  this.version3DS = data['version'];
                  this.iframe3DS = data['data'];

                  let datos = {
                    version: this.version3DS,
                    data: this.iframe3DS,
                    idControl: this.idControl,
                  };

                  let dataIframe = this.sanitizer.bypassSecurityTrustHtml(
                    this.iframe3DS
                  );
                  this.htmlToAdd = dataIframe;

                  this.procesaTransaccion(datos, this.paymentData.clave);
                }

                // this.open3ds();
              } else {
                if (data['data'][0] != null) {
                  let datos = data['data'][0];
                  this.dataModal = {
                    toUrl: datos['acsURL'],
                    PaReq: datos['payerAuthenticationRequest'],
                    TermUrl: datos['termURL'],
                    md: datos['merchantData'],
                    version: this.version3DS,
                  };
                  this.lottieService.setLoader(false, '');
                  this.open3ds();
                } else {
                  this.lottieService.setLoader(false, '');
                  let dataResponse = {
                    status: 'ERROR',
                    msg: 'Ocurrió un error al conectar con el banco, por favor intente de nuevo',
                  };
                  this.fiservSend.emit(dataResponse);
                }
              }
            } else {
              this.lottieService.setLoader(false, '');
              let dataResponse = {
                status: 'ERROR',
                msg: data['msg'],
              };
              this.fiservSend.emit(dataResponse);
            }
          }

          // this.paymentSend.emit(data);
          // this.lottieService.setLoader(false, '');
          //this.spinnerService.hide();
        });

      var errorResponseHandler = function (error) {
        this.lottieService.setLoader(false, '');
        //this.spinnerService.hide();
      };
    }
  }

  open3ds() {
    ModalFiservComponent;
    const modalRef = this.modalService.open(ModalFiservComponent, {
      backdrop: 'static',
    });

    modalRef.componentInstance.data = this.dataModal;

    modalRef.result
      .then((result) => {
        if (result['status'] != 'close') {
          let data = {
            status: 'PENDIENTE',
            respuesta: {
              status: 'PENDIENTE',
              fecha: result['fecha'],
              autorizacion: result['autorizacion'],
              ccNumero: result['ccNumero'],
              monto: result['monto'],
              track: this.paymentData.clave,
            },
          };

          this.fiservSend.emit(data);
        } else {
        }
      })
      .catch((error) => {});
  }

  // TODO: Funciones para transaccion sin friccion
  procesaTransaccion(arrayData, track) {
    // this.lottieService.setLoader(true, '');

    setTimeout(function () {
      debugger;
      let form: HTMLFormElement = <HTMLFormElement>(
        document.getElementById('tdsMmethodForm')
      );
      let iframe: HTMLFormElement = <HTMLFormElement>(
        document.getElementById('tdsMmethodTgtFrame')
      );

      let finalIframe: HTMLFormElement = <HTMLFormElement>(
        document.getElementById('finalIframe')
      );
      let finalForm: HTMLFormElement = <HTMLFormElement>(
        document.getElementById('finalForm')
      );
      // form.submit();
      // finalIframe.setAttribute("id", iframe.attributes['id'].value);
      // finalIframe.setAttribute("name", iframe.attributes['name'].value);
      // finalIframe.setAttribute("style", iframe.attributes['style'].value);
      // finalIframe.setAttribute("src", iframe.attributes['src'].value);
      // finalIframe.setAttribute("xmlns", iframe.attributes['xmlns'].value);

      // iframe.remove();

      finalForm.setAttribute('action', form.attributes['action'].value);
      finalForm.setAttribute('id', form.attributes['id'].value);
      finalForm.setAttribute('method', form.attributes['method'].value);
      finalForm.setAttribute('name', form.attributes['name'].value);
      finalForm.setAttribute('target', form.attributes['target'].value);
      finalForm.setAttribute('xmlns', form.attributes['xmlns'].value);

      this.DSMethodData = form.children['3DSMethodData'].value;
      this.threeDSMethodData = form.children['threeDSMethodData'].value;
      // form.remove();

      let DSMethodData: HTMLFormElement = <HTMLFormElement>(
        document.getElementById('3DSMethodData')
      );
      let threeDSMethodData: HTMLFormElement = <HTMLFormElement>(
        document.getElementById('threeDSMethodData')
      );

      DSMethodData.setAttribute('value', this.DSMethodData);
      threeDSMethodData.setAttribute('value', this.threeDSMethodData);
      finalForm.submit();
    }, 4000);
    // const now = new Date();
    // let array = {
    //   'status' : 'PENDIENTE',
    //   'respuesta': {
    //     'status' : 'PENDIENTE',
    //     'fecha' : now.toLocaleDateString(),
    //     'autorizacion' : '',
    //     'ccNumero': '',
    //     'monto' : '',
    //     'track': track
    //   }
    // }
    // this.lottieService.setLoader(false, '');
    // this.fiservSend.emit(array);
  }

  onLoads(event) {
    this.count3ds++;
    if (this.count3ds == 2) {
      this.count3ds = 0;
      var data = {
        idControl: this.idControl,
      };

      this.http
        .post(this.fiserManual, {
          params: data,
        })
        .subscribe((data) => {
          const now = new Date();

          if (data['transactionStatus'] == 'APPROVED') {
            let array = {
              status: 'Aprobado',
              respuesta: {
                status: 'Aprobado',
                fecha: now.toLocaleDateString(),
                autorizacion: data['processor']['authorizationCode'],
                ccNumero: data['paymentMethodDetails']['paymentCard']['last4'],
                monto: data['approvedAmount']['total'],
                track: this.paymentData.clave,
                orderId: data['orderId'],
              },
            };
            this.lottieService.setLoader(false, '');
            this.fiservSend.emit(array);
            // this.closeModal(array);
          } else if (data['status'] == 'Fallido') {
            let array = {
              status: 'Fallido',
              respuesta: {
                status: 'Fallido',
                fecha: now.toLocaleDateString(),
                autorizacion: '',
                ccNumero: '',
                monto: '',
                track: this.paymentData.clave,
                mensaje: data['respuesta'],
              },
            };
            this.lottieService.setLoader(false, '');
            this.fiservSend.emit(array);
          } else {
            if (data['authenticationResponse']) {
              let datos = data['authenticationResponse']['params'];

              this.dataModal = {
                toUrl: datos['acsURL'],
                cReq: datos['cReq'],
                termURL: datos['termURL'],
                acsURL: datos['acsURL'],
                version: this.version3DS,
              };

              this.lottieService.setLoader(false, '');
              this.open3ds();
            } else {
              let array = {
                status: 'PENDIENTE',
                respuesta: {
                  status: 'PENDIENTE',
                  fecha: now.toLocaleDateString(),
                  autorizacion: '',
                  ccNumero: '',
                  monto: '',
                  track: this.paymentData.clave,
                },
              };
              this.lottieService.setLoader(false, '');
              this.fiservSend.emit(array);
            }
          }
        });
      // tdsMmethodTgtFrame
    }
  }

  get numberCardWithOutSpaces() {
    if (this.number_card.value) {
      return this.number_card.value.replace(/\s/g, '');
    }
    return '';
  }
  get number_card() {
    return this.paymentForm.get('number_card');
  }
  get valid_month() {
    return this.paymentForm.get('valid_month');
  }
  get valid_year() {
    return this.paymentForm.get('valid_year');
  }
  get card_holder_name() {
    return this.paymentForm.get('card_holder_name');
  }
  get card_cvv() {
    return this.paymentForm.get('card_cvv');
  }
  get email_holder() {
    return this.paymentForm.get('email_holder');
  }
  get phone() {
    return this.paymentForm.get('phone');
  }
}
