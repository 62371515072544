<div class="modal-header" style="display: flow-root !important;">
    <div *ngIf="this.idStatus =='APROBADO' || this.idStatus=='ERROR'" class="col-md-12">
        <div class="row">
          <div class="col-md-11">
            <p class="" style="font-size: 20px; margin: -5px; color: #d89219;">Transacción en revisión.</p>
          </div>
          <div class="col-md-1">
            <button type="button" class="close" aria-label="Close" (click)="closeModal()" style="-webkit-appearance: none; padding: 0; cursor: pointer; background: 0 0; border: 0; float: right; font-size: 27px; font-weight: 700; line-height: 0.5; color: #000; text-shadow: 0 1px 0 #fff; filter: alpha(opacity=20); opacity: 0.5;">
                <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
    </div>
    <div *ngIf="this.idStatus =='IMAGEN' || this.idStatus=='ERROR'" class="col-md-12">
        <div class="row">
          <div class="col-md-11">
            <p class="" style="font-size: 20px; margin: -5px; color: #e91717;">Error.</p>
          </div>
          <div class="col-md-1">
            <button type="button" class="close" aria-label="Close" (click)="closeModal()" style="-webkit-appearance: none; padding: 0; cursor: pointer; background: 0 0; border: 0; float: right; font-size: 27px; font-weight: 700; line-height: 0.5; color: #000; text-shadow: 0 1px 0 #fff; filter: alpha(opacity=20); opacity: 0.5;">
                <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
    </div>
</div>
<div>
    <p *ngIf="this.idStatus=='APROBADO'" class="" style="font-size: 20px; margin: 15px; color: #d89219;">Tu pago ha sido recibido, en breve recibirás respuesta sobre su estatus.</p>
    <p *ngIf="this.idStatus=='ERROR'" class="" style="font-size: 20px; margin: 15px; color: #e91717;">Error al generar el cargo, favor de intentarlo más tarde.</p>
    <p *ngIf="this.idStatus =='IMAGEN'" class="" style="font-size: 20px; margin: 15px; color: #e91717;">Para generar el cargo, se debe cargar el comprobante de pago.</p>
</div>
