<div class="payment-form-container">
    <div class="form-container w-100" [class.green-border]="paymentForm.invalid">
        <p class="title" [class.selected]="paymentForm.invalid"><span class="number">1</span> Ingresar los datos de la
            tarjeta de crédito o débito</p>
        <form class="form row" [formGroup]="paymentForm">
            <div class="row d-flex align-items-center m-t-20">
                <div class="group col-6">
                    <div class="col-12">
                        <p>Número de tarjeta:</p>
                        <input class="col-12" placeholder="Entre 15 y 16 números" (keydown)="onKeyDown($event)"
                            [ngClass]="number_card.dirty || number_card.touched ? (number_card.invalid ? 'error' : 'valid') : ''"
                            formControlName="number_card" type="text" name="number-card">
                        <div *ngIf="number_card.hasError('validateCardNumber')" class="col-12 error-msg">
                            <p style="" class="">Numero de tarjeta invalido</p>
                        </div>
                    </div>
                </div>
                <div class="group f-wrap col-6" style="margin-bottom:auto">
                    <div class="row">
                        <p class="w-100">Válida hasta :</p>
                        <div class="col-6">
                            <select class="w-100"
                                [ngClass]="valid_month.touched ? (paymentForm.hasError('validCardDate') || valid_month.invalid  ? 'error' : 'valid') : ''"
                                formControlName="valid_month" name="valid_month">
                                <option value="null" hidden>Mes</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                        </div>
                        <div class="col-6">
                            <select class="w-100" formControlName="valid_year" name="valid_year">
                                <option value="null" hidden>Año</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                                <option value="2031">2031</option>
                                <option value="2032">2032</option>
                                <option value="2033">2033</option>
                                <option value="2034">2034</option>
                                <option value="2035">2035</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex align-items-center m-t-20">
                <div class="group col-9">
                    <div class="col-12">
                        <p>Nombre del titular:</p>
                        <input (keyup)="sanitize($event)" class="w-100" placeholder="Como aparece en la tarjeta"
                            [ngClass]="card_holder_name.dirty || card_holder_name.touched ? (card_holder_name.invalid ? 'error' : 'valid') : ''"
                            formControlName="card_holder_name" type="text" name="name">
                    </div>
                </div>
                <div class="group col-3">
                    <div class="col-12">
                        <p>CVV:</p>
                        <input class="w-100" (keydown)="cleanCvv($event)"
                            [ngClass]="card_cvv.dirty || card_cvv.touched ? (card_cvv.invalid || paymentForm.hasError('cvvInvalid') ? 'error' : 'valid') : ''"
                            formControlName="card_cvv" type="password" name="cvv">
                    </div>
                </div>
            </div>
            <div class="row d-flex align-items-center m-t-20">
                <div class="group col-6">
                    <div class="col-12">
                        <p>Email:</p>
                        <input class="w-100" placeholder="nombre@email.com"
                            [ngClass]="email_holder.dirty || email_holder.touched ? (email_holder.invalid ? 'error' : 'valid') : ''"
                            formControlName="email_holder" type="email" name="number-card">
                    </div>
                </div>
                <div class="group col-6">
                    <div class="col-12">
                        <p>Teléfono:</p>
                        <input class="w-100" maxlength="11"
                            [ngClass]="phone.dirty || phone.touched ? (phone.invalid ? 'error' : 'valid') : ''"
                            placeholder="Campo obligatorio" formControlName="phone" name="number-card"
                            (input)="onInputChange($event)">
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="row" style="margin-top: 20px;" [className]="paymentForm.valid ? 'green-border' : 'disabled'"
        [class.green-border]="paymentForm.valid">
        <p class="title" [class.selected]="paymentForm.valid">
            <span class="number">2</span>
            Elige tu método de pago
        </p>
        <p class="subtitle color707070">
            Los planes de meses sin intereses sólo aplican para tarjeta de crédito
        </p>
        <div class="table-payment" *ngIf="numberCardWithOutSpaces.length < 15">
            <div class="table-header col-12 d-flex">
                <div class="col-6">
                    <p>Tarjetas participantes</p>
                </div>

            </div>
            <div class="table-body">
                <div style="text-align: center;">
                    <img style="width: 300;height:80px;" src="assets/img/visa.png">
                    <img style="width: 300;height:70px;" src="assets/img/mastercard.png">
                </div>
            </div>
        </div>

        <div class="table-payment" *ngIf="vermontos">
            <div class="table-header col-12 d-flex">
                <div class="col-6">
                    <p>Tarjetas participantes</p>
                </div>
                <div class="col-3 text-center">
                    <p>Plan</p>
                </div>
                <div class="col-3 text-center">
                    <p>Mensualidad</p>
                </div>
            </div>
            <div class="table-body">

                <div class="d-flex col-12"
                    style="border-bottom: 2px solid #DDDDDD;margin-bottom: 5px;align-items: center;">
                    <div class="col-6">
                        <div class=" banks-imgs f-wrap">
                            <div class="w-100 d-flex justify-content-center box">

                                <img class="logo-banco" src="{{imgtarjeta}}">
                            </div>
                        </div>
                    </div>

                    <!-- todo -->

                    <div class="col-6 text-center">
                        <div class="w-100 d-flex" [class.selected]="cardData.paymentTime == paymentTime.value"
                            *ngFor="let paymentTime of paymentsTimes; let i = index">
                            <ng-container *ngIf="paymentTime.show && paymentTime.value != 18">
                                <div style="margin-bottom: 5px; display: flex; align-items: center; justify-content: center;"
                                    *ngFor="let paymentTime of paymentsTimes; let i = index">
                                    <input [(ngModel)]="cardData.paymentTimeA" *ngIf="paymentTime.amount != 0"
                                        [checked]="i == 0" type="radio" class="table-mount" name="charge"
                                        [value]="paymentTime.value"
                                        style="height: 20px !important; margin-right: 10px;">
                                    <label *ngIf="paymentTime.amount != 0" style="width: 70%;" for="payment{{i}}">
                                        {{paymentTime.description}}
                                    </label>
                                </div>

                                <div class="col-6" style="text-align:center">
                                    <p>{{(paymentTime.amount/paymentTime.value).toFixed(2) | currency:'USD':'symbol'}}
                                        {{currency}}
                                    </p>
                                </div>
                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button *ngIf="numberCardWithOutSpaces.length >= 15" class="w-100 pay-button" [disabled]="paymentForm.invalid"
            (click)="sendCardData()">Pagar </button>
        <button *ngIf="numberCardWithOutSpaces.length < 15" class="w-100 pay-button" [disabled]="paymentForm.invalid"
            (click)="sendCardData()">Pagar {{montoinicial |
            currency:'USD':'symbol'}} </button>
    </div>
    <!-- TODO: seccion iframe oculta -->
    <div class="one" [innerHTML]="htmlToAdd"></div>


    <div>
        <iframe id="finalIframe" #iframes (load)="onLoads($event)" name="tdsMmethodTgtFrame"
            style="display: none;"></iframe>
        <form id="finalForm" action="" method="post" target="iframes">
            <input type="hidden" name="3DSMethodData" id="3DSMethodData">
            <input type="hidden" name="threeDSMethodData" id="threeDSMethodData">
        </form>
    </div>
</div>



<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row modal_header">
                    <img style="height: 50px;" src="assets/img/conekta-color.svg" alt="">
                </div>
                <div class="row mt-3 d-flex justify-content-centeral align-items-center">
                    <div class="col-12 text-center"> <strong>Tarjetas participantes</strong> <img class="img-fluid mx-3"
                            src="assets/img/billete.svg"> </div>

                </div>

                <div class="row my-3">
                    <div>
                        <p>Un cargo, 3, 6, 9 y 12 meses sin intereses</p>
                        <div class="logosBancos text-center">
                            <img style="width: 70px;height:25px" src="assets/img/HSBC.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Santander.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Scotiabank.svg">
                            <img style="width: 70px;height:25px" src="assets/img/banregio.svg">
                            <img style="width: 70px;height:25px" src="assets/img/liverpool.svg">
                            <img style="width: 30px;height:25px" src="assets/img/Itaucard.svg">
                            <img style="width: 70px;height:25px" src="assets/img/banco-del-bajio.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Banorte.svg">
                            <img style="width: 70px;height:25px" src="assets/img/afirme.svg">
                            <img style="width: 70px;height:25px" src="assets/img/banca-mifel.svg">
                            <img style="width: 70px;height:25px" src="assets/img/Inbursa.svg">
                            <img style="width: 50px;height:25px" src="assets/img/famsa.svg">
                            <img style="width: 50px;height:25px" src="assets/img/banjercito.svg">
                            <img style="width: 50px;height:25px" src="assets/img/invex.svg">
                            <img class="" style="width: 50;height:23px" src="assets/img/BBVA.svg">
                            <img style="width: 50px;height:27px" src="assets/img/americanexpress2.svg">
                        </div>

                    </div>
                </div>
                <div class="row my-3">
                    <div>
                        <p>Un cargo, 3, 6, 9, 12 y 18 meses sin intereses</p>
                        <div class="logosBancos text-center">
                            <img style="width: 150;height:25px" src="assets/img/citibanamex.svg">

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner type="">
    <img src='assets/img/loader.gif' width="120" />"
</ngx-spinner>