import { Component,Injectable } from '@angular/core';
import { LottieService } from './services/lottie.service';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
@Injectable({
  providedIn: 'root'
})
//path: 'https://payoff.mx/img/loader/Credir_Cart.json',
export class AppComponent {
  title = 'modal-payment';
  options: AnimationOptions = {
		path: 'assets/img/Credit_Card.json',
	};
  styles: Partial<CSSStyleDeclaration> = {
    maxWidth: '1000px',
  };
  showLoader: boolean;
  textLoader: string;

  constructor(
    private lottieService: LottieService
  ) {
  
  }

  ngOnInit() {
    this.lottieService.getLoader().subscribe(options => {
      this.showLoader = options.show;
      this.textLoader = options.text;
    })
  }
}


